import { reportsAssessedService } from "api";
import { initSingleReportsFilterData, Preloader, SingleReportsFilterData, SingleReportsTableItem, Toast } from "components";
import { ReportAssessedUI } from "features";
import { useApiRequest } from "hooks";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Routes } from "router";
import { format, parseISO } from 'date-fns';
import { copyToClipBoard } from "helpers";

const ReportAssessed = () => {
  const { id } = useParams();
  const {
    run: runTableData,
    data: tableDataResponse,
    requestStatus: tableRequestStatus,
    error: tableError,
  } = useApiRequest({});
  const [toast, setToast] = useState({ show: false, head: "", message: "" });
  const [singleReportSearchTerm, setSingleReportSearchTerm] = useState("");
  const [singleCurrentReportsPage, setSingleCurrentReportsPage] = useState(1);
  const [filter, setFilter] = useState<SingleReportsFilterData>(initSingleReportsFilterData);
  const [totalSingleReport, setTotalSingleReport] = useState("");
  const [assessmentTitle, setAssessmentTitle] = useState("");
  const [singleReportsTable, setSingleReportsTable] = useState<
    SingleReportsTableItem[]
  >([]);
  const handleSingleReportFilter = (data: SingleReportsFilterData) => {
    setFilter(data);
    setSingleCurrentReportsPage(1);
  };
  useMemo(() => {
    if (tableDataResponse?.status === 200) {
      const tableData = tableDataResponse?.data?.results;
      setTotalSingleReport(tableDataResponse?.data.count);
      setAssessmentTitle(tableData[0]?.assessment_title)
      const reportList = tableData.map((item: any) => ({
        id: item.id,
        singleReportName: item.full_name,
        date: format((parseISO(item.created_at)), "MM/dd/yyyy - h:mm a"),
        email: item.email,
        phoneNumber: item.phone_number,
        score: Math.floor(item.total_score),
        link: item.portfolio,
        resume: item.resume
      }));
      setSingleReportsTable(reportList);
    } else if (tableError) {
      setToast({
        show: true,
        head: "Error",
        message: tableError.message,
      });
    }
  }, [tableDataResponse, tableError]);
  useEffect(() => {
    runTableData(reportsAssessedService(
      {
        id: id,
        search: singleReportSearchTerm,
        page: singleCurrentReportsPage,
        scores: filter.scores?.value,
        date: filter.startDate
      }));
  }, [id, runTableData, singleCurrentReportsPage, singleReportSearchTerm, filter]);
  const handleSingleReportPageChange = (page: number) => {
    setSingleCurrentReportsPage(page);
  };
  const handleSingleReportSearch = (searchTerm: string) => {
    setSingleReportSearchTerm(searchTerm);
    setSingleCurrentReportsPage(1);
  };
  const copyLink = (link: any) => {
    const onSuccess = () => {
      setToast({
        show: true,
        head: "Success",
        message: "Link copied to clipboard successfully!",
      });
    };

    const onError = () => {
      alert("Failed to copy link to clipboard.");
      setToast({
        show: true,
        head: "Error",
        message: "Failed to copy link to clipboard.",
      });
    };

    copyToClipBoard({
      link: link,
      onSuccess: onSuccess,
      onError: onError,
    });
  };
  const isValidURL = (str: string): boolean => { try { return !!new URL(str); } catch { return false; } };

  const viewCV = (cv: any) => {
    
    if (cv && isValidURL(cv)) {
      window.open(cv, '_blank');
    } else {
      setToast({
        show: true,
        head: "Error",
        message: "CV is invalid",
      });
    }
  };
  const sort = () => {
    setSingleReportsTable([]);
  };
  const navigate = useNavigate();
  const handleSingleReportView = (id: any, tab: any, id2: any, id3: any) => {
    navigate(Routes.candidateReport(id, "assessed", id2, id3));
  };
  const handleClose = () => {
    setToast({ show: false, head: "", message: "" });
  };

  return (
    <div>
      <Toast
        onClose={handleClose}
        loading={toast.show}
        head={toast.head}
        message={toast.message}
      />
      <Preloader loading={tableRequestStatus.isPending} />
      <ReportAssessedUI
        singleReportsTable={singleReportsTable}
        singleReportSearchTerm={singleReportSearchTerm}
        handleSingleReportSearch={handleSingleReportSearch}
        handleSingleReportFilter={handleSingleReportFilter}
        handleSingleReportView={handleSingleReportView}
        singleReportPagination={{
          handleChange: handleSingleReportPageChange,
          total: Math.floor(parseInt(totalSingleReport) / 7),
          current: singleCurrentReportsPage,
          limit: 7
        }}
        copyLink={copyLink}
        viewCV={viewCV}
        sort={sort}
        title={assessmentTitle}
      />
    </div>
  );
};

export { ReportAssessed };

import React, { useState } from "react";
import styles from "./styles.module.scss"
import { DoughnutChartProps } from "components/charts";

interface PopupProps {
  text: string;
  action: (id: any) => void;
  className?: any;
  Icon: React.FC<DoughnutChartProps> | any;
}

const Popup: React.FC<PopupProps> = ({Icon, text, action, className}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <div>
      <span
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={`${styles.icons} ${className}`}
      >
        <div className={`${styles.eye}`}>
          {typeof Icon === "function" ? <Icon className={`${styles.eye}`} /> : <Icon />}
        </div>
        
        {isHovered && (
          <span
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={styles.tooltip}
          >
            <span className={styles.tooltipArrow}></span>
            <span className={styles.tooltipContent} onClick={action}>{text}</span>
          </span>
        )}
      </span>
    </div>
  );
};

export { Popup };

import React from "react";
import styles from "./styles.module.scss"
import { Doughnut } from "react-chartjs-2";
import {
  ChartOptions,
  Plugin,
  ChartData,
} from "chart.js";

export interface DoughnutChartProps {
  score: string;
  total: number;
  color: string[];
  radius: number[];
  cutout: string;
  plugins?: Plugin<"doughnut">[];
  className?: string;
}

const DoughnutChart: React.FC<DoughnutChartProps> = ({ className, score, cutout, total, radius, color, plugins = [] }) => {
  const scoreInt = parseFloat(score);
  const doughnutData: ChartData<"doughnut"> = {
    datasets: [
      {
        data: [scoreInt, total - scoreInt],
        backgroundColor: color,
        borderWidth: [0],
        hoverBorderWidth: 0,
        borderRadius: radius,
        borderAlign: "inner",
      },
    ],
  };

  const doughnutOptions: ChartOptions<"doughnut"> = {
    cutout: cutout,
    animation: {
      animateRotate: true,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        display: false,
      },
    },
  };

  return (
    <div className={`${styles.chart} ${className}`}><Doughnut data={doughnutData} options={doughnutOptions} plugins={plugins} />

    </div>
    );
};

export  {DoughnutChart};

import styles from "./styles.module.scss";
import { CandidatesIcon, CloseIcon, } from "assets";
import * as yup from "yup";
import { SubmitHandler } from "react-hook-form";
import { Button, Input, InputDropDown, Modal } from "components";

export interface DraftsFilterData {
  dateCreated?: string | null;
}

export const initDraftFilterData: DraftsFilterData = {
  dateCreated: "",
};

export const draftSchema = yup.object({
  dateCreated: yup.string().nullable(),
});

interface DraftsFilterModalProps {
  show: boolean;
  close: () => void;
  submit: (data: DraftsFilterData) => void;
  form: any;
}

const DraftsFilterModal: React.FC<DraftsFilterModalProps> = ({
  show,
  close,
  submit,
  form,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = form;
  const onSubmit: SubmitHandler<DraftsFilterData> = (data) => {
    submit(data);
    close();
  };
  const onReset = () => {
    reset(initDraftFilterData);
    close();
  };
  return (
    <>
      <Modal
        contentClassName={styles.modal}
        position={"centered"}
        close={close}
        show={show}
      >
        <div className={styles.close}>
          <CloseIcon
            className={styles.closeIcon}
            role="button"
            onClick={close}
          />
        </div>
        <div className={styles.container}>
          <div className={styles.header}>
            <p>Filters</p>
          </div>
          <form className={styles.form}>
            <InputDropDown
              Icon={CandidatesIcon}
              className={styles.wrap} title="Date">
              <Input
                placeholder="Date Created"
                name="dateCreated"
                type="date"
                validatorMessage={errors.dateCreated?.message}
                parentClassName={styles.input}
                className={styles.mainInput}
                register={register}
                value={watch("startDate")}
                min={new Date().toISOString().split("T")[0]}
              />
            </InputDropDown>
          </form>
          <div className={styles.footer}>
            <Button
              className={styles.cancelbtn}
              type="outline"
              onClick={onReset}
            >
              Cancel
            </Button>
            <Button type="fill" onClick={handleSubmit(onSubmit)}>
              Apply filters
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export { DraftsFilterModal };

import * as React from "react";
import styles from "./styles.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { Button, Input } from "components";
import { LogoWithText } from "assets";
import { Link } from "react-router-dom";
import { Routes } from "router";

export interface ForgotPasswordData {
  email: string;
}

const initialValues: ForgotPasswordData = {
  email: "",
};

export interface ForgotPasswordModalProps {
  login: (data: ForgotPasswordData) => void;
  sent: boolean;
}

const forgotPasswordSchema = yup
  .object({
    email: yup.string().required("Required").email("Enter a valid email"),
  })
  .required();

const ForgotPasswordUI: React.FC<ForgotPasswordModalProps> = ({
  login, sent
}: ForgotPasswordModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordData>({
    resolver: yupResolver(forgotPasswordSchema),
    defaultValues: initialValues,
  });

  const onSubmit: SubmitHandler<ForgotPasswordData> = (data) => {
    return login(data);
  };

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <div className={styles.head}>
          <LogoWithText className={styles.logo} />
        </div>
        <h1 className={styles.ttl}>
          {sent ? "Instructions have been sent" : "Forgot Password"}
        </h1>
        <p className={styles.subttl}>
          {sent
            ? "Please check your inbox and click the received link to reset your password."
            : "Enter the email address you registered with and we'll send you instructions to reset your password."}
        </p>
        <div className={styles.body}>
          <form className={styles.form}>
            {sent ? (
              <p className={styles.subttl}>
                Didn’t receive the email? Check your spam or click the button
              </p>
            ) : (
              <>
                <Input
                  label="Work email"
                  placeholder="Please input"
                  type="email"
                    parentClassName={styles.input}
                    className={styles.mainInput}
                  required
                  validatorMessage={errors.email?.message}
                  name="email"
                  register={register}
                />
                <Link className={styles.footer} to={Routes.resetPassword}>
                  Reset password?
                </Link>
              </>
            )}
            <Button
              className={styles.continue}
              onClick={handleSubmit(onSubmit)}
              type="fill"
            >
              {sent? "Resend" : "Send"} Instructions
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export { ForgotPasswordUI };

import styles from "./styles.module.scss";
import { CandidatesIcon, CloseIcon, FlagIcon } from "assets";
import * as yup from "yup";
import { SubmitHandler } from "react-hook-form";
import { Button, CheckBox, Input, InputDropDown, Modal } from "components";

export interface ReportsFilterData {
  startDate?: string | null;
  active?: boolean | null;
  closed?: boolean | null;
  scheduled?: boolean | null;
  totalMin?: string | null;
  totalMax?: string | null;
  completedMin?: string | null;
  completedMax?: string | null;
  abandonedMin?: string | null;
  abandonedMax?: string | null;
}

export const initReportsFilterData: ReportsFilterData = {
  startDate: null,
  active: false,
  closed: false,
  scheduled: false,
  totalMin: null,
  totalMax: null,
  completedMin: null,
  completedMax: null,
  abandonedMin: null,
  abandonedMax: null,
};

export const reportSchema = yup.object({
  startDate: yup.string().nullable(),
  active: yup.boolean(),
  closed: yup.boolean(),
  scheduled: yup.boolean(),
  totalMin: yup
    .string()
    .nullable()
    .matches(/[0-9]/, "Min value should only contain numbers"),
  totalMax: yup
    .string()
    .nullable()
    .matches(/[0-9]/, "Min value should only contain numbers"),
  completedMin: yup
    .string()
    .nullable()
    .matches(/[0-9]/, "Min value should only contain numbers"),
  completedMax: yup
    .string()
    .nullable()
    .matches(/[0-9]/, "Min value should only contain numbers"),
  abandonedMin: yup
    .string()
    .nullable()
    .matches(/[0-9]/, "Min value should only contain numbers"),
  abandonedMax: yup
    .string()
    .nullable()
    .matches(/[0-9]/, "Min value should only contain numbers"),
});

interface ReportsFilterModalProps {
  show: boolean;
  close: () => void;
  submit: (data: ReportsFilterData) => void;
  form: any;
}

const ReportsFilterModal: React.FC<ReportsFilterModalProps> = ({
  show,
  close,
  submit,
  form,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    reset,
  } = form;
  const onSubmit: SubmitHandler<ReportsFilterData> = (data) => {
    submit(data);
    close();
  };

  const onReset = () => {
    reset(initReportsFilterData);
    close();
  };

  return (
    <>
      <Modal
        contentClassName={styles.modal}
        position={"centered"}
        close={close}
        show={show}
      >
        <div className={styles.close}>
          <CloseIcon
            className={styles.closeIcon}
            role="button"
            onClick={close}
          />
        </div>
        <div className={styles.container}>
          <div className={styles.header}>
            <p>Filters</p>
          </div>
          <form className={styles.form}>
            <InputDropDown
              Icon={CandidatesIcon}
              className={styles.wrap}
              title="Start Date"
            >
              <Input
                placeholder="Start Date"
                name="startDate"
                type="date"
                validatorMessage={errors.startDate?.message}
                parentClassName={styles.input}
                className={styles.mainInput}
                register={register}
                value={watch("startDate")}
                min={new Date().toISOString().split("T")[0]}
              />
            </InputDropDown>
            <InputDropDown
              Icon={CandidatesIcon}
              className={styles.wrap}
              title="Total candidates"
            >
              <Input
                placeholder="Min"
                name="totalMin"
                type="number"
                validatorMessage={errors.totalMin?.message}
                parentClassName={styles.input}
                className={styles.mainInput}
                register={register}
              />
              <span className={styles.dash}>-</span>
              <Input
                placeholder="Max"
                name="totalMax"
                type="number"
                validatorMessage={errors.totalMax?.message}
                parentClassName={styles.input}
                className={styles.mainInput}
                register={register}
              />
            </InputDropDown>
            <InputDropDown
              Icon={CandidatesIcon}
              className={styles.wrap}
              title="Completed"
            >
              <Input
                placeholder="Min"
                name="completedMin"
                type="number"
                validatorMessage={errors.completedMin?.message}
                parentClassName={styles.input}
                className={styles.mainInput}
                register={register}
              />
              <span className={styles.dash}>-</span>
              <Input
                placeholder="Max"
                name="completedMax"
                type="number"
                validatorMessage={errors.completedMax?.message}
                parentClassName={styles.input}
                className={styles.mainInput}
                register={register}
              />
            </InputDropDown>
            <InputDropDown
              Icon={CandidatesIcon}
              className={styles.wrap}
              title="Abandoned"
            >
              <Input
                placeholder="Min"
                name="abandonedMin"
                type="number"
                validatorMessage={errors.abandonedMin?.message}
                parentClassName={styles.input}
                className={styles.mainInput}
                register={register}
              />
              <span className={styles.dash}>-</span>
              <Input
                placeholder="Max"
                name="abandonedMax"
                type="number"
                validatorMessage={errors.abandonedMax?.message}
                parentClassName={styles.input}
                className={styles.mainInput}
                register={register}
              />
            </InputDropDown>
            <InputDropDown
              Icon={FlagIcon}
              className={styles.wrapStatus} title="Status">
                <div className={`${styles.item} ${styles.firstItem}`}>
                  <CheckBox
                    id={""}
                    checked={watch(`active`)}
                    onChange={() => {
                      setValue(`active`, !watch(`active`));
                    }}
                  />
                  <span>Active</span>
                </div>
                <div className={styles.item}>
                  <CheckBox
                    id={""}
                    checked={watch(`closed`)}
                    onChange={() => {
                      setValue(`closed`, !watch(`closed`));
                    }}
                  />
                  <span>Closed</span>
                </div>
                <div className={styles.item}>
                  <CheckBox
                    id={""}
                    checked={watch(`scheduled`)}
                    onChange={() => {
                      setValue(`scheduled`, !watch(`scheduled`));
                    }}
                  />
                  <span>Scheduled</span>
                </div>
            </InputDropDown>
          </form>
          <div className={styles.footer}>
            <Button
              className={styles.cancelbtn}
              type="outline"
              onClick={onReset}
            >
              Cancel
            </Button>
            <Button type="fill" onClick={handleSubmit(onSubmit)}>
              Apply filters
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export { ReportsFilterModal };

import styles from "./styles.module.scss";
import { CandidatesIcon, CloseIcon, FlagIcon } from "assets";
import * as yup from "yup";
import { SubmitHandler } from "react-hook-form";
import { Button, CheckBox, Input, InputDropDown, Modal } from "components";

export interface AssessmentsFilterData {
  startDate?: string | null;
  endDate?: string | null;
  active?: boolean | null;
  closed?: boolean | null;
  scheduled?: boolean | null;
  paused?: boolean | null;
}

export const initAssessmentFilterData: AssessmentsFilterData = {
  startDate: "",
  endDate: "",
  active: false,
  closed: false,
  scheduled: false,
  paused: false,
};

export const assessmentSchema = yup.object({
  startDate: yup.string().nullable(),
  endDate: yup.string().nullable(),
  active: yup.boolean(),
  closed: yup.boolean(),
  scheduled: yup.boolean(),
  paused: yup.boolean(),
});

interface AssessmentsFilterModalProps {
  show: boolean;
  close: () => void;
  submit: (data: AssessmentsFilterData) => void;
  form: any;
}

const AssessmentsFilterModal: React.FC<AssessmentsFilterModalProps> = ({
  show,
  close,
  submit,
  form,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    reset,
  } = form;
  const onSubmit: SubmitHandler<AssessmentsFilterData> = (data) => {
    submit(data);
    close();
  };
  const onReset = () => {
    reset(initAssessmentFilterData);
    close();
  };
  return (
    <>
      <Modal
        contentClassName={styles.modal}
        position={"centered"}
        close={close}
        show={show}
      >
        <div className={styles.close}>
          <CloseIcon
            className={styles.closeIcon}
            role="button"
            onClick={close}
          />
        </div>
        <div className={styles.container}>
          <div className={styles.header}>
            <p>Filters</p>
          </div>
          <form className={styles.form}>
            <InputDropDown
              Icon={CandidatesIcon}
              className={styles.wrap} title="Date">
              <Input
                placeholder="Start Date"
                name="startDate"
                type="date"
                validatorMessage={errors.startDate?.message}
                parentClassName={styles.input}
                className={styles.mainInput}
                register={register}
                value={watch("startDate")}
                min={new Date().toISOString().split("T")[0]}
              />
              <span className={styles.dash}>-</span>
              <Input
                placeholder="End Date"
                name="endDate"
                type="date"
                validatorMessage={errors.endDate?.message}
                parentClassName={styles.input}
                className={styles.mainInput}
                register={register}
                value={watch("endDate")}
                min={new Date().toISOString().split("T")[0]}
              />
            </InputDropDown>
            <InputDropDown
              Icon={FlagIcon}
              className={styles.wrapStatus} title="Status">
                <div className={`${styles.item} ${styles.firstItem}`}>
                  <CheckBox
                    id={""}
                    checked={watch(`active`)}
                    onChange={() => {
                      setValue(`active`, !watch(`active`));
                    }}
                  />
                  <span>Active</span>
                </div>
                <div className={styles.item}>
                  <CheckBox
                    id={""}
                    checked={watch(`closed`)}
                    onChange={() => {
                      setValue(`closed`, !watch(`closed`));
                    }}
                  />
                  <span>Closed</span>
                </div>
                <div className={styles.item}>
                  <CheckBox
                    id={""}
                    checked={watch(`scheduled`)}
                    onChange={() => {
                      setValue(`scheduled`, !watch(`scheduled`));
                    }}
                  />
                  <span>Scheduled</span>
                </div>
                <div className={styles.item}>
                  <CheckBox
                    id={""}
                    checked={watch(`paused`)}
                    onChange={() => {
                      setValue(`paused`, !watch(`paused`));
                    }}
                  />
                  <span>Paused</span>
                </div>
            </InputDropDown>
          </form>
          <div className={styles.footer}>
            <Button
              className={styles.cancelbtn}
              type="outline"
              onClick={onReset}
            >
              Cancel
            </Button>
            <Button type="fill" onClick={handleSubmit(onSubmit)}>
              Apply filters
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export { AssessmentsFilterModal };

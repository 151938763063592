import styles from "./styles.module.scss";

interface ProgressBarProps {
  rating: number;
  className?: string;
  background?: string;
  barClassName?: string;
}

const ProgressBar = ({ rating, className }: ProgressBarProps) => {
  return (
    <>
      <div className={`${styles.progressBar} ${className}`}>
        <span
          style={{ left: `${rating}%` }}
          className={styles.progressBar__rating}
        >
          {rating}%
        </span>
        <div
          style={{ width: `${rating}%` }}
          className={styles.progressBar__bar}
        ></div>
        <div className={styles.progressBar__bar__white}></div>
      </div>
    </>
  );
};

const RatingBar = ({ rating, className, background, barClassName }: ProgressBarProps) => {
  return (
    <>
      <div className={`${className} ${styles.ratingBar}`}>
        <span
          style={{ left: `12px`, fontWeight: "500" }}
          className={styles.ratingBar__rating}
        >
          {/* {rating}% */}
        </span>
        <span
          style={{ right: `12px`, color: "#000", fontWeight: "500" }}
          className={styles.ratingBar__rating}
        >
          {/* {100 - rating}% */}
        </span>
        <div
          style={{ width: `calc(${rating}% - 8px)`, background }}
          className={`${styles.ratingBar__bar} ${barClassName}`}
        ></div>
        <div className={styles.ratingBar__bar__white}></div>
      </div>
    </>
  );
};

interface ScoreBarProps {
  isFullReport: boolean;
  title: string;
  rating: number
}
const ScoreBar = ({ isFullReport, title, rating }: ScoreBarProps) => {
  return (
    <div className={isFullReport ? styles.full : ""}>
      {isFullReport ? <p>{title} </p> : ""}
      <ProgressBar
        className={isFullReport ? styles.full__bar : ""}
        rating={rating}
      />
    </div>
  );
};
export { ProgressBar, RatingBar, ScoreBar };
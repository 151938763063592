import {
  billingGraphService,
  billingPlansCountService,
  billingPlansUsersService,
  FrequentlyCreatedRolesService,
  overviewCarouselService,
  reportInsightsService,
  scoreService,
} from "api";
import { OverviewModal, Preloader, Toast } from "components";
import { formattedDate, formattedLastMonthDate, OverviewUI } from "features";
import { useApiRequest } from "hooks/useApiRequest";
import { useEffect, useMemo, useState } from "react";
import { format, parseISO } from "date-fns";
import { optionType } from "types";
import { ChartData } from "chart.js";

const Overview = () => {
  // HOOK VARIABLES
  const [toast, setToast] = useState({ show: false, head: "", message: "" });
  const {
    run: runOverviewCarousel,
    data: overviewCarouselResponse,
    error: overviewCarouselError,
    requestStatus: overviewCarouselRequestStatus,
  } = useApiRequest({});
  const {
    run: runBillingPlans,
    data: billingPlansResponse,
    error: billingPlansError,
    requestStatus: billingPlansRequestStatus,
  } = useApiRequest({});
  const {
    run: runBillingPlansUsers,
    data: billingPlansUsersResponse,
    error: billingPlansUsersError,
    requestStatus: billingPlansUsersRequestStatus,
  } = useApiRequest({});
  const {
    run: runBillingGraph,
    data: billingGraphResponse,
    error: billingGraphError,
    requestStatus: billingGraphRequestStatus,
  } = useApiRequest({});
  const {
    run: runReportInsights,
    data: reportInsightsResponse,
    error: reportInsightsError,
    requestStatus: reportInsightsRequestStatus,
  } = useApiRequest({});
  const {
    run: runFrequentlyCreatedRolesData,
    data: frequentlyCreatedRolesDataResponse,
    requestStatus: FrequentlyCreatedRolesRequestStatus,
    error: frequentlyCreatedRolesError,
  } = useApiRequest({});
  const {
    run: runScoreData,
    data: ScoreDataResponse,
    requestStatus: ScoreRequestRequestStatus,
    error: ScoreError,
  } = useApiRequest({});
  const [prop, setProp] = useState("pro");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showOverviewModal, setShowOverviewModal] = useState(false);
  const [details, setDetails] = useState({
    title: "",
    number: "",
  });
  const [carouselData, setCarouselData] = useState<any>();
  const [billingPlansData, setBillingPlansData] = useState<any>();
  const [billingPlansUsersData, setBillingPlansUsersData] = useState<any>([]);
  const [checks, setChecks] = useState<boolean[]>(new Array(billingPlansUsersData.length).fill(false));
  const [lineChartLabels, setLineChartLabels] = useState([]);
  const [reportInsightData, setReportInsightData] = useState<any>();
  const [frequency, setFrequency] = useState("oneYear");
  const [frequentlyCreatedRolesValue, setFrequentlyCreatedRolesValue] =
    useState<number[]>([]);
  const [frequentlyCreatedRolesLabels, setFrequentlyCreatedRolesLabels] =
    useState<string[]>([]);
  const [totalUsersData, setTotalUsersData] = useState([]);
  const [totalEarningsData, setTotalEarningsData] = useState([]);
  const [noOfCandidatesData, setNoOfCandidatesData] = useState([]);
  const [noOfEmployersData, setNoOfEmployersData] = useState([]);
  const [candidatesTopUpData, setCandidatesTopUpData] = useState([]);
  const [extendAccessData, setExtendAccessData] = useState([]);
  const [score, setScore] = useState("")
  const [scoreTimeFrame, setScoreTimeFrame] = useState("one_year");
  const [startDate, setStartDate] = useState(formattedLastMonthDate);
  const [endDate, setEndDate] = useState(formattedDate)

  // MOCK-DATA VARIABLES

  const data:  ChartData<'line'> = {
    labels: lineChartLabels,
    datasets: [
      {
        label: "Earnings",
        data: totalEarningsData,
        backgroundColor: "rgba(96, 91, 255, 1)",
        borderColor: "#605BFF",
        borderWidth: 2,
        fill: false,
        pointBorderColor: "#605BFF",
        pointBackgroundColor: "#fff",
        pointRadius: 5,
        tension: 0.3,
      }
    ],
  };

  const filterOptions: optionType[] = [
    {
      label: "12 months",
      value: "12_months",
    },
    {
      label: "30 days",
      value: "30_days",
    },
    {
      label: "7 days",
      value: "7_days",
    },
    {
      label: "24 hours",
      value: "24_hours",
    },
  ];
  const accountsFilterOptions: optionType[] = [
    {
      label: "Daily",
      value: "one_day",
    },
    {
      label: "Monthly",
      value: "one_month",
    },
    {
      label: "Yearly",
      value: "one_year",
    },
  ];
  const reportInsightsFilterOptions: optionType[] = [
    {
      label: "Daily",
      value: "one_day",
    },
    {
      label: "Monthly",
      value: "one_month",
    },
    {
      label: "Yearly",
      value: "one_year",
    },
  ];
  const [period, setPeriod] = useState<optionType>(filterOptions[0]);
  const [timeFrame, setTimeFrame] = useState("12_months");
  const [accountsPeriod, setAccountsPeriod] = useState<optionType>(accountsFilterOptions[1]);
  const [accountsTimeFrame, setAccountsTimeFrame] = useState<"one_day" | "one_month" | "one_year">("one_month");
  const [reportInsightsPeriod, setReportInsightsPeriod] = useState<optionType>(reportInsightsFilterOptions[1]);
  const [reportInsightsTimeFrame, setReportInsightsTimeFrame] = useState<"one_day" | "one_month" | "one_year">("one_month");
  const CardData = [
    {
      id: 1,
      header: "Employers Accounts",
      users: carouselData?.all_employers.count,
      percentage: false,
      ratingType: carouselData?.all_employers.growth,
    },
    {
      id: 2,
      header: "Active Employers",
      users: carouselData?.active_employers.count,
      ratingType: carouselData?.active_employers.growth,
    },
    {
      id: 3,
      header: "Dominant Employers",
      users: carouselData?.dormant_employers.count,
      ratingType: carouselData?.dormant_employers.growth,
    },
    {
      id: 4,
      header: "Roles Assessed",
      users: carouselData?.created_roles.count,
      ratingType: carouselData?.created_roles.growth,
    },
    {
      id: 5,
      header: "Roles created",
      users: carouselData?.created_roles.count,
      ratingType: carouselData?.created_roles.growth,
    },
    {
      id: 6,
      header: "Total Candidates",
      users: carouselData?.total_candidate_population.count,
      ratingType: carouselData?.total_candidate_population.growth,
    },
    {
      id: 7,
      header: "Total candidates: Completed tests",
      users: carouselData?.no_completed_tests.count,
      ratingType: carouselData?.no_completed_tests.growth,
    },
    {
      id: 8,
      header: "Total candidates: Abandoned tests",
      users: carouselData?.no_abandoned_tests.count,
      ratingType: carouselData?.no_abandoned_tests.growth,
    },
    {
      id: 9,
      header: "NPS(employers)",
      users: carouselData?.NPS.count,
      ratingType: carouselData?.NPS.growth,
    },
  ];
  const ReportInsightData = [
    {
      id: 1,
      header: "Assessed Candidate",
      users: reportInsightData?.assessed_candidates.count,
      percentage: false,
      ratingType: reportInsightData?.assessed_candidates.growth,
    },
    {
      id: 2,
      header: "Considered Candidate",
      users: reportInsightData?.considered_candidates.count,
      ratingType: reportInsightData?.considered_candidates.growth,
    },
    {
      id: 3,
      header: "To Interview",
      users: reportInsightData?.to_interview_candidates.count,
      ratingType: reportInsightData?.to_interview_candidates.growth,
    },
    {
      id: 4,
      header: "Accepted Candidates",
      users: reportInsightData?.accepted_candidates.count,
      ratingType: reportInsightData?.accepted_candidates.growth,
    },
    {
      id: 5,
      header: "Rejected Candidates",
      users: reportInsightData?.rejected_candidates.count,
      ratingType: reportInsightData?.rejected_candidates.growth,
    },
  ];
    const CandidatesCardData = [
      {
        id: 1,
        header: "Total PAYG users",
        prop: "pay_as_you_go",
        users: billingPlansData?.total_PAYG_users,
        trendValue: billingPlansData?.PAYG_users_growth,
        trendStatus: billingPlansData?.PAYG_users_growth > 0 ? "positive" : billingPlansData?.PAYG_users_growth === 0 ? "even" :  "negative",
        type: "View users",
      },
      {
        id: 2,
        header: "Total Free trial plan users",
        prop: "regular",
        users: billingPlansData?.total_free_trial_users,
        trendValue: billingPlansData?.free_trial_users_growth,
        trendStatus: billingPlansData?.free_trial_users_growth > 0 ? "positive" : billingPlansData?.free_trial_users_growth === 0 ? "even" :  "negative",
        type: "View users",
      },
      {
        id: 3,
        header: "Total Regular plan users",
        prop: "regular",
        users: billingPlansData?.total_regular_users,
        trendValue: billingPlansData?.regular_users_growth,
        trendStatus: billingPlansData?.regular_users_growth > 0 ? "positive" : billingPlansData?.regular_users_growth === 0 ? "even" :  "negative",
        type: "View users",
      },
      {
        id: 4,
        header: "Total Pro plan users",
        prop: "pro",
        users: billingPlansData?.total_pro_users,
        trendValue: billingPlansData?.pro_users_growth,
        trendStatus: billingPlansData?.pro_users_growth > 0 ? "positive" : billingPlansData?.pro_users_growth === 0 ? "even" :  "negative",
        type: "View users",
      },
      {
        id: 5,
        header: "Total Custom plan users",
        prop: "custom",
        users: billingPlansData?.total_custom_users,
        trendValue: billingPlansData?.custom_users_growth,
        trendStatus: billingPlansData?.custom_users_growth > 0 ? "positive" : billingPlansData?.custom_users_growth === 0 ? "even" :  "negative",
        type: "View users",
      },
      {
        id: 6,
        header: "Total Candidate Top-up users",
        prop: "regular",
        users: billingPlansData?.total_candidate_top_up,
        trendValue: 0,
        trendStatus: billingPlansData?.total_candidate_top_up_growth > 0 ? "positive" : billingPlansData?.total_candidate_top_up_growth < 0 ? "negative": "even",
        type: "View users",
      },
      {
        id: 7,
        header: "Total Extend Access users",
        prop: "regular",
        users: billingPlansData?.total_extend_access,
        trendValue: 0,
        trendStatus: billingPlansData?.total_extend_access_growth > 0 ? "positive" : billingPlansData?.total_extend_access_growth < 0 ? "negative" : "even",
        type: "View users",
      },
    ];

  // REQUESTS

  useMemo(() => {
    if (overviewCarouselResponse?.status === 200) {
      const overviewCarouselData = overviewCarouselResponse?.data.data;
      setCarouselData(overviewCarouselData);
    } else if (overviewCarouselError) {
      setToast({
        show: true,
        head: "Error",
        message: overviewCarouselError.message,
      });
    }
  }, [overviewCarouselResponse, overviewCarouselError]);
  useMemo(() => {
    if (billingPlansResponse?.status === 200) {
      const billingPlansData = billingPlansResponse?.data.data;
      setBillingPlansData(billingPlansData);
    } else if (billingPlansError) {
      setToast({
        show: true,
        head: "Error",
        message: billingPlansError.message,
      });
    }
  }, [billingPlansResponse, billingPlansError]);
  useMemo(() => {
    if (billingPlansUsersResponse?.status === 200) {
      // const billingPlansUsersData = billingPlansUsersResponse?.data.data.users;
      // const BillingPlansUsersList = billingPlansUsersData.map((item: any) => ({
      //   id: item.id,
      //   businessName: "Vision Group",
      //   email: item.owner__email,
      //   startDate: format(parseISO(item.started_at), "MM/dd/yyyy - h:mm a"),
      //   endDate: format(parseISO(item.next_due_date), "MM/dd/yyyy - h:mm a"),
      // }));
      setBillingPlansUsersData([]);
    } else if (billingPlansUsersError) {
      setToast({
        show: true,
        head: "Error",
        message: billingPlansUsersError.message,
      });
    }
  }, [billingPlansUsersResponse, billingPlansUsersError]);
  useMemo(() => {
    if (reportInsightsResponse?.status === 200) {
      const reportInsightsData = reportInsightsResponse?.data.data;
      setReportInsightData(reportInsightsData);
    } else if (reportInsightsError) {
      setToast({
        show: true,
        head: "Error",
        message: reportInsightsError.message,
      });
    }
  }, [reportInsightsResponse, reportInsightsError]);
  useMemo(() => {
    if (frequentlyCreatedRolesDataResponse?.status === 200) {
      const frequentlyCreatedRolesData =
        frequentlyCreatedRolesDataResponse.data.data.frequently_created_roles;
      setFrequentlyCreatedRolesValue(
        frequentlyCreatedRolesData.map((role: any) => role.count)
      );
      setFrequentlyCreatedRolesLabels(
        frequentlyCreatedRolesData.map((role: any) => role.assessment_title)
      );
    } else if (frequentlyCreatedRolesError) {
      setToast({
        show: true,
        head: "Error",
        message: frequentlyCreatedRolesError.message,
      });
    }
  }, [frequentlyCreatedRolesDataResponse, frequentlyCreatedRolesError]);
  useMemo(() => {
    if (billingGraphResponse?.status === 200) {
      const billingGraphData = billingGraphResponse?.data.data.graph_plots;
      setTotalUsersData(
        billingGraphData.map((item: any) => item.plot_values.total_users)
      );
      setTotalEarningsData(
        billingGraphData.map((item: any) => item.plot_values.total_earnings)
      );
      setNoOfCandidatesData(
        billingGraphData.map((item: any) => item.plot_values.no_of_candidates)
      );
      setCandidatesTopUpData(
        billingGraphData.map((item: any) => item.plot_values.candidate_top_up)
      );
      setExtendAccessData(
        billingGraphData.map((item: any) => item.plot_values.extended_access)
      );
      setNoOfEmployersData(
        billingGraphData.map((item: any) => item.plot_values.no_of_employers)
      );
      const array = billingGraphData.map((date: any) => date.plot_name);
      setLineChartLabels(array);
    } else if (billingGraphError) {
      setToast({
        show: true,
        head: "Error",
        message: billingGraphError.message,
      });
    }
  }, [billingGraphResponse, billingGraphError]);
  useMemo(() => {
    if (ScoreDataResponse?.status === 200) {
      const Score = Math.floor(ScoreDataResponse?.data.data.overall_precision_score);
      setScore(`${Score}%`);
    } else if (ScoreError) {
      setToast({
        show: true,
        head: "Error",
        message: ScoreError.message,
      });
    }
  }, [ScoreDataResponse, ScoreError]);

  useEffect(() => {
    runScoreData(scoreService({
      time_frame: scoreTimeFrame
    }));
  }, [runScoreData, scoreTimeFrame]);
  useEffect(() => {
    runOverviewCarousel(overviewCarouselService(
      {
      time_frame: accountsTimeFrame
      }
    ));
  }, [accountsTimeFrame, runOverviewCarousel]);
  useEffect(() => {
    runBillingPlans(billingPlansCountService({
      start_date: startDate,
      end_date: endDate
    }));
  }, [runBillingPlans, startDate, endDate]);
  useEffect(() => {
    runBillingGraph(
      billingGraphService({
        time_frame: timeFrame,
      })
    );
  }, [runBillingGraph, timeFrame]);
  useEffect(() => {
    runReportInsights(reportInsightsService(
      {
        time_frame: reportInsightsTimeFrame
      }
    ));
  }, [runReportInsights, reportInsightsTimeFrame]);
  useEffect(() => {
    runFrequentlyCreatedRolesData(
      FrequentlyCreatedRolesService({
        id: "19",
        frequency: frequency,
      })
    );
  }, [runFrequentlyCreatedRolesData, frequency]);
  useEffect(() => {
    runBillingPlansUsers(
      billingPlansUsersService({
        plan_type: prop,
        search: searchTerm,
      })
    );
  }, [searchTerm, prop, runBillingPlansUsers]);

  // FUNCTIONS

  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    setCurrentPage(1);
  };
  const handleModalView = (prop: string, header: string, number: string) => {
    setProp(prop);
    setShowOverviewModal(true);
    setDetails({ title: header, number: number });
    runBillingPlansUsers(
      billingPlansUsersService({
        plan_type: prop,
        search: searchTerm,
      })
    );
  };
  const handleFrequentlyCreatedRolesPeriodChange = (frequency: any) => {
    setFrequency(frequency);
  };
  const handleScoreChange = (frequency: any) => {
    setScoreTimeFrame(frequency);
  };
  const handleCheck = ({ index, value }: { index: number; value: boolean }) => {
    const newChecks = [...checks];
    newChecks[index] = value;
    setChecks(newChecks);
  };
  const handleClose = () => {
    setToast({ show: false, head: "", message: "" });
  };
  const handleFilterClick = (selectedOption: optionType) => {
    setPeriod(selectedOption);
    setTimeFrame(selectedOption.value);
  };
  const handleAccountsFilterClick = (selectedOption: optionType) => {
    setAccountsPeriod(selectedOption);
    setAccountsTimeFrame(selectedOption.value);
  };
  const handleReportInsightsFilterClick = (selectedOption: optionType) => {
    setReportInsightsPeriod(selectedOption);
    setReportInsightsTimeFrame(selectedOption.value);
  };

    const onStartDateChange = (start: string, end: string) => {
      runOverviewCarousel(
        overviewCarouselService({
          start_date: start,
          end_date: end
        })
      );
    };
    const onEndDateChange = (start: string, end: string) => {
      runOverviewCarousel(
        overviewCarouselService({
          start_date: start,
          end_date: end
        })
      );
    };

  return (
    <>
      <Toast
        onClose={handleClose}
        loading={toast.show}
        head={toast.head}
        message={toast.message}
      />
      <Preloader
        loading={
          overviewCarouselRequestStatus.isPending ||
          billingPlansRequestStatus.isPending ||
          billingPlansUsersRequestStatus.isPending ||
          reportInsightsRequestStatus.isPending ||
          FrequentlyCreatedRolesRequestStatus.isPending ||
          billingGraphRequestStatus.isPending
        }
      />
      <OverviewModal
        show={showOverviewModal}
        close={() => {
          setShowOverviewModal(false);
        }}
        title={details.title}
        number={details.number}
        searchTerm={searchTerm}
        handleSearch={handleSearch}
        checks={checks}
        handleCheck={handleCheck}
        tableItems={billingPlansUsersData}
      />
      <OverviewUI
        handleModalView={handleModalView}
        assessment={handleScoreChange}
        barChartData={frequentlyCreatedRolesValue}
        barChartLabels={frequentlyCreatedRolesLabels}
        data={data}
        cardData={CardData}
        reportInsightData={ReportInsightData}
        candidatesCardData={CandidatesCardData}
        filterOptions={filterOptions}
        period={period}
        handleFilterClick={handleFilterClick}
        totalEarningsData={totalEarningsData}
        totalUsersData={totalUsersData}
        noOfCandidatesData={noOfCandidatesData}
        noOfEmployersData={noOfEmployersData}
        candidatesTopUpData={candidatesTopUpData}
        extendAccessData={extendAccessData}
        frequentlyCreatedRoles={handleFrequentlyCreatedRolesPeriodChange}
        score={score}
        onStartDateChange={setStartDate}
        onEndDateChange={setEndDate}
        accountsFilterOptions={accountsFilterOptions}
        handleAccountsFilterClick={handleAccountsFilterClick}
        accountsPeriod={accountsPeriod}
        reportInsightsFilterOptions={reportInsightsFilterOptions}
        handleReportInsightsFilterClick={handleReportInsightsFilterClick}
        reportInsightsPeriod={reportInsightsPeriod}
      />
    </>
  );
};

export { Overview };

import { deactivateEmployerService, deactivateMultipleEmployerService, employerCarouselService, employerService } from "api";
import {
  ConfirmationModal,
  EmployerTableItem,
  Preloader,
  Toast,
} from "components";
import {
  EmployersFilterData,
  initFilterData,
} from "components/modalVariants/employersFilterModal";
import { EmployersUI } from "features";
import { useApiRequest } from "hooks";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Routes } from "router";
import { optionType } from "types";
import { format, parseISO } from "date-fns";

const Employers = () => {
  // STATE VARIABLES
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [currentId, setCurrentId] = useState<string | undefined | null>(null);
  const [toast, setToast] = useState({ show: false, head: "", message: "" });
  const [searchTerm, setSearchTerm] = useState("");
  const [activeStatus, setActiveStatus] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [totalEmployers, setTotalEmployers] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState<EmployersFilterData>(initFilterData);
  const [tableData, setTableData] = useState<EmployerTableItem[]>([]);
  const [checks, setChecks] = useState<boolean[]>(
    new Array(tableData.length).fill(false)
  );
  const getCheckedItemIds = () => {
    return tableData.filter((_, index) => checks[index]).map((item) => item.id);
  };
  const checkedIds = getCheckedItemIds();
  const [carousel, setCarousel] = useState({
    employerAccount: {
      count: "",
      growth: "",
    },
    activeEmployers: {
      count: "",
      growth: "",
    },
    dormantEmployers: {
      count: "",
      growth: "",
    },
    createdRoles: {
      count: "",
      growth: "",
    },
    assessedRoles: {
      count: "",
      growth: "",
    },
    closedRoles: {
      count: "",
      growth: "",
    },
    completedTests: {
      count: "",
      growth: "",
    },
  });
  const filterOptions: optionType[] = [
    {
      label: "Daily",
      value: "one_day",
    },
    {
      label: "Monthly",
      value: "one_month",
    },
    {
      label: "Yearly",
      value: "one_year",
    },
  ];
  const [period, setPeriod] = useState<optionType>(filterOptions[1]);
  const [timeFrame, setTimeFrame] = useState<
    "one_day" | "one_month" | "one_year"
  >("one_month");

  // API REQUEST VARIABLES

  const {
    run: runEmployersData,
    data: employersDataResponse,
    requestStatus,
    error,
  } = useApiRequest({});
  const {
    run: runCarouselData,
    data: carouselDataResponse,
    requestStatus: carouselRequestStatus,
    error: carouselError,
  } = useApiRequest({});
  const {
    run: runDeactivateEmployer,
    data: deactivateEmployerResponse,
    requestStatus: deactivateRequestStatus,
    error: deactivateError,
  } = useApiRequest({});
  const {
    run: runDeactivateMultipleEmployers,
    data: deactivateMultipleEmployersResponse,
    requestStatus: deactivateMultipleEmployersRequestStatus,
    error: deactivateMultipleEmployersError,
  } = useApiRequest({});

  // USE EFFECT
  useEffect(() => {
    setTableData([]);
  }, []);
  useEffect(() => {
    runEmployersData(
      employerService({
        status:
          (filter.active && "active") ||
          (filter.dormant && "dormant") || activeStatus ||
          "",
        search: searchTerm,
        page: currentPage,
        maxCandidates: filter.candidatesMax,
        minCandidates: filter.candidatesMin,
        annualHiringFrequency: filter?.hiringFrequency?.value || "",
        noOfEmployees: filter?.noOfEmployees?.value || "",
        planType:
          (filter.regular && "regular") ||
          (filter.pro && "pro") ||
          (filter.payg && "pay_as_you_go") ||
          (filter.freeTrial && "free_trial") ||
          "",
        noOfRolesAccessed: filter.assessedMin,
        noOfCreatedRoles: filter.createdMin,
      })
    );
  }, [filter, runEmployersData, deactivateRequestStatus, deactivateMultipleEmployersRequestStatus, searchTerm, currentPage, activeStatus]);
  useEffect(() => {
    runCarouselData(
      employerCarouselService({
        time_frame: timeFrame,
      })
    );
  }, [runCarouselData, timeFrame]);

  // USE MEMO

  useMemo(() => {
    if (deactivateEmployerResponse?.status === 200) {
      setToast({
        show: true,
        head: "Success",
        message: deactivateEmployerResponse?.data?.data,
      });
    } else if (deactivateError) {
      setToast({
        show: true,
        head: "Error",
        message: deactivateError.message,
      });
    }
  }, [deactivateEmployerResponse, deactivateError]);

  useMemo(() => {
    if (deactivateMultipleEmployersResponse?.status === 200) {
      setToast({
        show: true,
        head: "Success",
        message: deactivateMultipleEmployersResponse?.data?.data,
      });
    } else if (deactivateMultipleEmployersError) {
      setToast({
        show: true,
        head: "Error",
        message: deactivateMultipleEmployersError.message,
      });
    }
  }, [deactivateMultipleEmployersResponse, deactivateMultipleEmployersError]);

  useMemo(() => {
    if (employersDataResponse?.status === 200) {
      const userData = employersDataResponse?.data?.results;
      setTotalEmployers(employersDataResponse?.data.count);
      setTotalPages(Math.ceil(employersDataResponse?.data.count / 8));
      const planTypeMapping: any = {
        free_trial: "Free Trial",
        regular: "Regular",
        pro: "Pro",
        pay_as_you_go: "Pay As You Go",
        extend_access: "Extend Access",
        top_up_candidates: "Top Up Candidates",
      };
      const userList = userData.map((item: any) => ({
        id: item.id,
        businessName: item?.name,
        dateCreated: format(parseISO(item?.created_at), "MM/dd/yyyy"),
        ownersID: item?.owner_id,
        email: item?.email,
        name: item?.name,
        phoneNumber: "0" + item?.phone_number,
        planType: planTypeMapping[item?.plan_type],
        candidateNo: item?.candidate_no,
        status:
          item?.status?.charAt(0).toUpperCase() +
          item?.status?.slice(1).toLowerCase(),
      }));
      setTableData(userList);
    } else if (error) {
      setToast({
        show: true,
        head: "Error",
        message: error.message,
      });
    }
  }, [employersDataResponse, error]);

  useMemo(() => {
    if (carouselDataResponse?.status === 200) {
      const carouselData = carouselDataResponse?.data?.data;
      const data = {
        employerAccount: {
          count: carouselData?.all_employers.count,
          growth: carouselData?.all_employers.growth,
        },
        activeEmployers: {
          count: carouselData?.active_employers.count,
          growth: carouselData?.active_employers.growth,
        },
        dormantEmployers: {
          count: carouselData?.dormant_employers.count,
          growth: carouselData?.dormant_employers.growth,
        },
        createdRoles: {
          count: carouselData?.created_roles.count,
          growth: carouselData?.created_roles.growth,
        },
        assessedRoles: {
          count: carouselData?.assessed_roles.count,
          growth: carouselData?.assessed_roles.growth,
        },
        closedRoles: {
          count: carouselData?.closed_roles.count,
          growth: carouselData?.closed_roles.growth,
        },
        completedTests: {
          count: carouselData?.completed_assessments.count,
          growth: carouselData?.completed_assessments.growth,
        },
      };
      setCarousel(data);
    } else if (carouselError) {
      setToast({
        show: true,
        head: "Error",
        message: carouselError.message,
      });
    }
  }, [carouselDataResponse]);

  // FUNCTIONS

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    setCurrentPage(1);
  };
  const handleFilter = (data: any) => {
    setFilter({
      regular: data.regular,
      pro: data.pro,
      payg: data.payg,
      freeTrial: data.freeTrial,
      candidatesMin: data.candidatesMin,
      candidatesMax: data.candidatesMax,
      hiringFrequency: data.hiringFrequency,
      noOfEmployees: data.noOfEmployees,
      assessedMin: data.assessedMin,
      assessedMax: data.assessedMax,
      createdMin: data.createdMin,
      createdMax: data.createdMin,
      active: data.active,
      dormant: data.dormant,
    });
    setCurrentPage(1);
  };

  const handleView = (id: number) => {
    navigate(Routes.profile(id));
  };

  const sort = () => {
    setTableData([]);
  };

  const handleRequestReview = (candidateID: any) => {
    setShowReviewModal(true);
    setChecks(new Array(tableData.length).fill(false));
    setCurrentId(candidateID);
  };
  const confirmDeactivation = () => {
    if (checkedIds.length > 0) {
      setShowModal(false);
      setChecks(new Array(tableData.length).fill(false));
      runDeactivateMultipleEmployers(
        deactivateMultipleEmployerService(
          {
            owner_ids: checkedIds,
          }
        )
      );
    } else {
      setShowModal(false);
      setChecks(new Array(tableData.length).fill(false));
      runDeactivateEmployer(
        deactivateEmployerService(
          currentId
        )
      );
    }
  };

  const confirmReview = (id: any) => {
    if (checkedIds.length > 0) {
      handleRequestReview(checkedIds);
    } else {
      handleRequestReview([id]);
    }
  };

  const deactivateEmployers = (id?: string) => {
    setShowModal(true);
    setCurrentId(id);
  };

  const requestReview = (id?: string) => {
    setShowReviewModal(true);
    setCurrentId(id);
  };

  const handleClose = () => {
    setToast({ show: false, head: "", message: "" });
  };

  const onStartDateChange = (start: string, end: string) => {
    runCarouselData(
      employerCarouselService({
        start_date: start,
        end_date: end
      })
    );
  };

  const onEndDateChange = (start: string, end: string) => {
    runCarouselData(
      employerCarouselService({
        start_date: start,
        end_date: end
      })
    );
  };

  const handleFilterClick = (selectedOption: optionType) => {
    setPeriod(selectedOption);
    setTimeFrame(selectedOption.value);
  };

  const handleCardFilter = (data: string) => {
    const status = data === "Active Employers" ? "active" : data === "Dormant Employers" ? "dormant" : ""
    if (data === "Active Employers" || data === "Dormant Employers" || data === "Employer Accounts") {
      setActiveStatus(status);
    }
  };
  return (
    <>
      <ConfirmationModal
        confirm={() => {
          confirmReview(currentId);
          setShowReviewModal(false);
        }}
        show={showReviewModal}
        close={() => {
          setShowReviewModal(false);
        }}
        purpose="Are you sure you want to review this group"
        header="Review employers"
      />
      <ConfirmationModal
        confirm={() => {
          confirmDeactivation();
          setShowModal(false);
        }}
        show={showModal}
        close={() => {
          setShowModal(false);
        }}
        purpose="Are you sure you want to deactivate this group"
        header="Deactivate employers"
      />
      <Toast
        onClose={handleClose}
        loading={toast.show}
        head={toast.head}
        message={toast.message}
      />
      <Preloader
        loading={requestStatus.isPending || carouselRequestStatus.isPending}
      />
      <EmployersUI
        onStartDateChange={onStartDateChange} onEndDateChange={onEndDateChange}
        employers={tableData}
        handleSearch={handleSearch}
        searchTerm={searchTerm}
        pagination={{
          handleChange: handlePageChange,
          total: totalPages,
          current: currentPage,
          limit: 8
        }}
        handleFilter={handleFilter}
        handleView={handleView}
        sort={sort}
        checks={checks}
        setChecks={setChecks}
        deactivateEmployers={deactivateEmployers}
        requestReview={requestReview}
        carousel={carousel}
        filterOptions={filterOptions}
        handleFilterClick={handleFilterClick}
        period={period}
        filter={handleCardFilter}
      />
    </>
  );
};

export { Employers };

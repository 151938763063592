import React, { useRef, useState } from "react";
import styles from "./styles.module.scss";
import { ChevronIcon, DotIcon } from "assets";
import { useClickOutside } from "helpers";

interface ProgressProps {
  className: string;
  title: string;
  items: any[];
}

const ProgressSelect: React.FC<ProgressProps> = ({
  className,
  title,
  items,
}) => {
  const [show, setList] = useState(false);
  const dropdownRef = useRef(null);
  useClickOutside(dropdownRef, () => setList(false));
  return (
    <div ref={dropdownRef} className={styles.main}>
      <div className={`${styles.progress} ${className}`}>
        <div className={styles.item}>
          <DotIcon className={`${styles.dot}`} />
          {title}
        </div>
        <ChevronIcon
          className={styles.chevron}
          onClick={() => setList(!show)}
        />
      </div>
      {show && (
        <div className={`${styles.sortList}`}>
          {items.map((item, index) => (
            <div key={index} className={styles.sortList__items}>
              <div className={styles.item} onClick={item.action}>
                {item.icon ? (
                  <item.icon
                    role={"button"}
                    className={`${styles.icon} ${styles[item.name.toLowerCase()]}`}
                    id="icon"
                  />
                ) : (
                  ""
                )}
                {item.name}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export { ProgressSelect };

import {
  assessmentChartService,
  assessmentModalService,
  assessmentsCarouselService,
  employerAssessmentService,
  employerProfileService,
} from "api";
import {
  AssessmentEmployersModal,
  AssessmentsFilterData,
  AssessmentsTableItem,
  DraftsFilterData,
  DraftsTableItem,
  initDraftFilterData,
  Preloader,
  Toast,
} from "components";
import { AssessmentsUI } from "features";
import { useApiRequest } from "hooks";
import { format, parseISO } from "date-fns";

import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Routes } from "router";
import { copyToClipBoard } from "helpers";
import { optionType } from "types";

export const initAssessmentFilterData: AssessmentsFilterData = {
  startDate: "",
  endDate: "",
  active: false,
  closed: false,
  scheduled: false,
};

const Assessments = () => {
  //HOOK VARIABLES

  const { id, tab } = useParams();
  const assessmentTab = tab as "active" | "drafts";
  const navigate = useNavigate();
  const [toast, setToast] = useState({ show: false, head: "", message: "" });
  const [showAssessmentModal, setShowAssessmentModal] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [titleHead, setTitleHead] = useState("");
  const [assessmentModalData, setAssessmentModalData] = useState({
    title: "",
    start: "",
    end: "",
    duration: "",
    questions: "",
    assessmentScope: [],
    descriptionText: "",
  });
  const [assessmentsSearchTerm, setAssessmentsSearchTerm] = useState("");
  const [currentAssessmentsPage, setCurrentAssessmentsPage] = useState(1);
  const [totalAssessments, setTotalAssessments] = useState("");
  const [filter, setFilter] = useState<AssessmentsFilterData>(
    initAssessmentFilterData
  );
  const [assessmentTable, setAssessmentTable] = useState<
    AssessmentsTableItem[]
  >([]);
  const [draftsSearchTerm, setDraftsSearchTerm] = useState("");
  const [draftsFilter, setDraftsFilter] =
    useState<DraftsFilterData>(initDraftFilterData);
  const [totalDrafts, setTotalDrafts] = useState("");
  const [currentDraftsPage, setCurrentDraftsPage] = useState(1);
  const [draftTable, setDraftTable] = useState<DraftsTableItem[]>([]);
  const [carousel, setCarousel] = useState({
    activeRoles: {
      count: "",
      growth: "",
    },
    scheduledRoles: {
      count: "",
      growth: "",
    },
    draftRoles: {
      count: "",
      growth: "",
    },
    closedRoles: {
      count: "",
      growth: "",
    },
  });
  const [frequency, setFrequency] = useState("oneYear");
  const [assessmentChartValue, setAssessmentChartValue] = useState<number[]>(
    []
  );
  const [assessmentChartLabels, setAssessmentChartLabels] = useState<string[]>(
    []
  );
  const filterOptions: optionType[] = [
    {
      label: "Daily",
      value: "one_day",
    },
    {
      label: "Monthly",
      value: "one_month",
    },
    {
      label: "Yearly",
      value: "one_year",
    },
  ];
  const [period, setPeriod] = useState<optionType>(filterOptions[1]);
  const [timeFrame, setTimeFrame] = useState<
    "one_day" | "one_month" | "one_year"
  >("one_month");

  //API REQUEST VARIABLES

  const {
    run: runAssessmentsData,
    data: assessmentsDataResponse,
    requestStatus: assessmentRequestStatus,
    error: assessmentsError,
  } = useApiRequest({});

  const {
    run: runAssessmentsChartData,
    data: assessmentsChartDataResponse,
    requestStatus: assessmentChartRequestStatus,
    error: assessmentsChartError,
  } = useApiRequest({});

  const {
    run: runCarouselData,
    data: carouselDataResponse,
    requestStatus: carouselRequestStatus,
    error: carouselError,
  } = useApiRequest({});

  const {
    run: runModalData,
    data: modalResponse,
    requestStatus: modalRequestStatus,
    error: modalError,
  } = useApiRequest({});

  const {
    run: runProfileData,
    data: profileDataResponse,
    requestStatus: profileRequestStatus,
    error: profileError,
  } = useApiRequest({});

  // USE MEMO

    useMemo(() => {
      if (profileDataResponse?.status === 200) {
        const profileData = profileDataResponse?.data;
        setTitleHead(profileData?.name);
        localStorage.setItem(
          "titleHead",
          profileData?.name
        );
      } else if (profileError) {
        setToast({
          show: true,
          head: "Error",
          message: profileError.message,
        });
      }
    }, [profileDataResponse, profileError]);

  useMemo(() => {
    if (assessmentsDataResponse?.status === 200) {
      const assessmentsData = assessmentsDataResponse.data.results;

      if (assessmentTab === "drafts") {
        setTotalDrafts(assessmentsDataResponse?.data.count);
        const draftsList = assessmentsData.map((item: any) => ({
          id: item.assessment_id,
          assessmentTitle: item.title,
          dateCreated: format(parseISO(item.created_at), "MM/dd/yyyy - h:mm a"),
          metricsSet: item.metrics_set.join(', '),
          button: "Continue",
        }));
        setDraftTable(draftsList);
      } else {
        setTotalAssessments(assessmentsDataResponse?.data.count);
        const assessmentsList = assessmentsData.map((item: any) => ({
          modalID: item.id,
          id: item.assessment_id,
          assessmentTitle: item.title,
          startDate: format(parseISO(item.created_at), "MM/dd/yyyy - h:mm a"),
          endDate: format(parseISO(item.updated_at), "MM/dd/yyyy - h:mm a"),
          status:
            item.status.charAt(0).toUpperCase() +
            item.status.slice(1).toLowerCase(),
        }));
        setAssessmentTable(assessmentsList);
      }
    } else if (assessmentsError) {
      setToast({
        show: true,
        head: "Error",
        message: assessmentsError.message,
      });
    }
  }, [assessmentsDataResponse, assessmentsError]);
  useMemo(() => {
    if (modalResponse?.status === 200) {
      const modalData = modalResponse?.data;
      const filteredModalData = {
        title: modalData?.title,
        start: modalData?.start_date
          ? format(parseISO(modalData.start_date), "MM/dd/yyyy - h:mm a")
          : "",
        end: modalData?.end_date
          ? format(parseISO(modalData.end_date), "MM/dd/yyyy - h:mm a")
          : "",
        duration: modalData?.duration_in_minutes,
        questions: modalData?.no_of_questions,
        assessmentScope: modalData?.metrics_set,
        descriptionText: modalData?.intro_html,
      };
      setAssessmentModalData(filteredModalData);
    } else if (modalError) {
      setToast({
        show: true,
        head: "Error",
        message: modalError.message,
      });
    }
  }, [modalResponse, modalError]);
  useMemo(() => {
    if (assessmentsChartDataResponse?.status === 200) {
      const assessmentsChartData =
        assessmentsChartDataResponse.data.data.frequently_created_roles;
      setAssessmentChartValue(
        assessmentsChartData.map((role: any) => Math.ceil(role.count))
      );
      setAssessmentChartLabels(
        assessmentsChartData.map((role: any) => role.assessment_title)
      );
    } else if (assessmentsChartError) {
      setToast({
        show: true,
        head: "Error",
        message: assessmentsChartError.message,
      });
    }
  }, [assessmentsChartDataResponse, assessmentsChartError]);
  useMemo(() => {
    if (carouselDataResponse?.status === 200) {
      const carouselData = carouselDataResponse.data.data;
      const data = {
        activeRoles: {
          count: carouselData.active_roles.count,
          growth: carouselData.active_roles.growth
        },
        scheduledRoles: {
          count: carouselData.scheduled_roles.count,
          growth: carouselData.scheduled_roles.growth,
        },
        draftRoles: {
          count: carouselData.draft_roles.count,
          growth: carouselData.draft_roles.growth,
        },
        closedRoles: {
          count: carouselData.closed_roles.count,
          growth: carouselData.closed_roles.growth,
        },
      };
      setCarousel(data);
    } else if (carouselError) {
      setToast({
        show: true,
        head: "Error",
        message: carouselError.message,
      });
    }
  }, [carouselDataResponse, carouselError]);

  // USE EFFECT

    useEffect(() => {
      runProfileData(employerProfileService(id));
    }, [id, runProfileData]);

  useEffect(() => {
    runAssessmentsData(
      employerAssessmentService({
        id: id,
        status: assessmentTab,
        search: assessmentsSearchTerm,
        page: currentAssessmentsPage,
        start_date: filter.startDate,
        end_date: filter.endDate,
        assessment_status:
          assessmentTab === "active"
            ? (filter.active && "active") ||
              (filter.closed && "closed") ||
              (filter.scheduled && "scheduled") ||
              (filter.paused && "paused") || activeTab ||
              ""
            : undefined,
      })
    );
  }, [
    filter,
    assessmentsSearchTerm,
    currentAssessmentsPage,
    runAssessmentsData,
    id,
    assessmentTab,
    activeTab,
  ]);
  useEffect(() => {
    runAssessmentsChartData(
      assessmentChartService({
        id: id,
        frequency: frequency,
      })
    );
  }, [runAssessmentsChartData, frequency]);
  useEffect(() => {
    runCarouselData(
      assessmentsCarouselService({ id: id, time_frame: timeFrame })
    );
  }, [id, runCarouselData, timeFrame]);

  //FUNCTIONS

  const handleFilter = (data: any) => {
    setFilter({
      startDate: data.startDate,
      endDate: data.endDate,
      active: data.active,
      closed: data.closed,
      scheduled: data.scheduled,
      paused: data.paused,
    });
    setCurrentAssessmentsPage(1);
  };

  const handleAssessmentsPageChange = (page: number) => {
    setCurrentAssessmentsPage(page);
  };
  const handleAssessmentsSearch = (searchTerm: string) => {
    setAssessmentsSearchTerm(searchTerm);
    setCurrentAssessmentsPage(1);
  };

  const handleDraftsFilter = (data: any) => {
    setDraftsFilter({
      dateCreated: data.dateCreated,
    });
    setCurrentDraftsPage(1);
  };
  const handleDraftsPageChange = (page: number) => {
    setCurrentDraftsPage(page);
  };
  const handleDraftsSearch = (searchTerm: string) => {
    setDraftsSearchTerm(searchTerm);
    setCurrentDraftsPage(1);
  };
  const handleDraftsView = (id: number) => {};

  const handleClose = () => {
    setToast({ show: false, head: "", message: "" });
  };
  const copyLink = (id: string) => {
    const link = `${process.env.REACT_APP_PRECISION_FE_URL}/assessment/${id}`;
    const onSuccess = () => {
      setToast({
        show: true,
        head: "Success",
        message: "Link copied to clipboard successfully!",
      });
    };

    const onError = () => {
      alert("Failed to copy link to clipboard.");
      setToast({
        show: true,
        head: "Error",
        message: "Failed to copy link to clipboard.",
      });
    };

    copyToClipBoard({
      link: link,
      onSuccess: onSuccess,
      onError: onError,
    });
  };
  const viewReview = (id2: number) => {
    navigate(Routes.reviews(id, id2));
  };
  const assessmentModal = (id2: string) => {
    runModalData(assessmentModalService(id, id2));
    setShowAssessmentModal(true);
  };
  const handleAssessmentChartPeriodChange = (frequency: any) => {
    setFrequency(frequency);
  };
  const handleFilterClick = (selectedOption: optionType) => {
    setPeriod(selectedOption);
    setTimeFrame(selectedOption.value);
  };

  return (
    <>
      <Toast
        onClose={handleClose}
        loading={toast.show}
        head={toast.head}
        message={toast.message}
      />
      <Preloader
        loading={
          assessmentRequestStatus.isPending ||
          carouselRequestStatus.isPending ||
          modalRequestStatus.isPending ||
          assessmentChartRequestStatus.isPending
        }
      />
      <AssessmentEmployersModal
        show={showAssessmentModal}
        close={() => {
          setShowAssessmentModal(false);
        }}
        title={assessmentModalData.title}
        start={assessmentModalData.start}
        end={assessmentModalData.end}
        duration={assessmentModalData.duration}
        questions={assessmentModalData.questions}
        assessmentScope={assessmentModalData.assessmentScope}
        descriptionText={assessmentModalData.descriptionText}
      />
      <AssessmentsUI
        assessments={assessmentTable}
        handleSearch={handleAssessmentsSearch}
        searchTerm={assessmentsSearchTerm}
        assessmentsPagination={{
          handleChange: handleAssessmentsPageChange,
          total: Math.ceil(parseInt(totalAssessments) / 9),
          current: currentAssessmentsPage,
          limit: 9,
        }}
        handleFilter={handleFilter}
        filter={{
          startDate: filter.startDate,
          endDate: filter.endDate,
          active: filter.active,
          close: filter.closed,
          scheduled: filter.scheduled,
        }}
        handleAssessmentsView={viewReview}
        assessmentChartData={assessmentChartValue}
        assessmentChartLabels={assessmentChartLabels}
        assessment={handleAssessmentChartPeriodChange}
        assessmentModal={assessmentModal}
        drafts={draftTable}
        draftsSearchTerm={draftsSearchTerm}
        handleDraftsSearch={handleDraftsSearch}
        handleDraftsFilter={handleDraftsFilter}
        handleDraftsView={handleDraftsView}
        draftsPagination={{
          handleChange: handleDraftsPageChange,
          total: Math.ceil(parseInt(totalDrafts) / 9),
          current: currentDraftsPage,
          limit: 9,
        }}
        draftsFilter={{
          dateCreated: draftsFilter.dateCreated,
        }}
        carousel={carousel}
        copyLink={copyLink}
        title={titleHead}
        filterOptions={filterOptions}
        handleFilterClick={handleFilterClick}
        period={period}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
    </>
  );
};

export { Assessments };

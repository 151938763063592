import { loginData, loginService } from "api";
import { Preloader, Toast } from "components";
import { useApiRequest } from "hooks/useApiRequest";
import { LoginUI } from "modules";
import { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Routes } from "router";

const Login = () => {
  const navigate = useNavigate();
  const [toast, setToast] = useState({ show: false, head: "", message: "" });
  const { run: runLogin, data: loginResponse, error, requestStatus } = useApiRequest({});
  const login = (data: loginData) => {
    localStorage.removeItem("precisionAdminAccess");

    runLogin(loginService(data));
  };

  useMemo(() => {
    if (loginResponse?.status === 200) {
      localStorage.setItem(
        "precisionAdminAccess",
        loginResponse.data.access_token
      );
      localStorage.setItem(
        "precisionAdminRefresh",
        loginResponse.data.refresh_token
      );
      localStorage.setItem(
        "userEmail",
        loginResponse.data.user.email
      );
      localStorage.setItem(
        "userName",
        loginResponse.data.user.first_name
      );
      localStorage.setItem(
        "displayPhoto",
        loginResponse.data.user.display_photo
      );
      setToast({
        show: true,
        head: "Success",
        message: "Login successful!",
      });
      setTimeout(() => {
        navigate(Routes.overview);
      }, 2000);
    } else if (error) {
      setToast({
        show: true,
        head: "Error",
        message: error.message,
      });
    }
  }, [loginResponse, error, navigate]);

  const handleClose = () => {
    setToast({ show: false, head: "", message: "" });
  };

  return (
    <>
      <Toast
        onClose={handleClose}
        loading={toast.show}
        head={toast.head}
        message={toast.message}
      />
      <Preloader loading={requestStatus.isPending} />
      <LoginUI login={login} />
    </>
  );
};

export { Login };

import styles from "./styles.module.scss";
import { CandidatesIcon, CloseIcon } from "assets";
import * as yup from "yup";
import { SubmitHandler } from "react-hook-form";
import { Button, CustomSelect, InputDropDown, Modal } from "components";
import { optionType } from "types";

export interface ReviewsFilterData {
  rating: {
    label: string;
    value: string;
  };
}

export const initReviewsFilterData: ReviewsFilterData = {
  rating: { label: "", value: "" },
};

export const ReviewsSchema = yup.object({
  rating: yup.object({
    label: yup.string().required(),
    value: yup.string().required(),
  }),
});

interface ReviewsFilterModalProps {
  show: boolean;
  close: () => void;
  submit: (data: ReviewsFilterData) => void;
  form: any;
}

const ReviewsFilterModal: React.FC<ReviewsFilterModalProps> = ({
  show,
  close,
  submit,
  form,
}) => {
  const {
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    reset,
  } = form;

  const onSubmit: SubmitHandler<ReviewsFilterData> = (data) => {
    submit(data);
    close();
  };

  const onReset = () => {
    reset(initReviewsFilterData);
    close();
  };

  const scores: optionType[] = [
    {
      label: "0 - 6",
      value: "0 - 6",
    },
    {
      label: "6 - 8",
      value: "6 - 8",
    },
    {
      label: "8 - 10",
      value: "8 - 10",
    },
  ];

  return (
    <>
      <Modal
        contentClassName={styles.modal}
        position={"centered"}
        close={close}
        show={show}
      >
        <div className={styles.close}>
          <CloseIcon
            className={styles.closeIcon}
            role="button"
            onClick={close}
          />
        </div>
        <div className={styles.container}>
          <div className={styles.header}>
            <p>Filters</p>
          </div>
          <form className={styles.form}>
            <InputDropDown
              Icon={CandidatesIcon}
              className={styles.wrap}
              title="Rating"
            >
              <CustomSelect
                onChange={(val) => setValue(`rating`, val)}
                validatorMessage={
                  errors?.rating
                    ? errors?.rating?.value?.message?.toString() ?? ""
                    : ""
                }
                name={`rating`}
                placeholder={"Please select"}
                options={scores}
                value={watch("rating")}
                parentClassName={styles.inputContainer}
                inputClass={styles.select}
                label=""
              />
            </InputDropDown>
          </form>
          <div className={styles.footer}>
            <Button
              className={styles.cancelbtn}
              type="outline"
              onClick={onReset}
            >
              Cancel
            </Button>
            <Button type="fill" onClick={handleSubmit(onSubmit)}>
              Apply filters
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export { ReviewsFilterModal };

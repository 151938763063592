import * as React from "react";
import styles from "./styles.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { Button, Input } from "components";
import { Link } from "react-router-dom";
import { LogoWithText } from "assets";
import { Routes } from "router";

export interface LoginData {
  email: string;
  password: string;
}

const initialValues: LoginData = {
  email: "",
  password: "",
};

export interface LoginModalProps {
  login: (data: LoginData) => void;
}

const loginSchema = yup
  .object({
    email: yup.string().required("Required").email("Enter a valid email"),
    password: yup.string().required("Required"),
  })
  .required();

const LoginUI: React.FC<LoginModalProps> = ({ login }: LoginModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginData>({
    resolver: yupResolver(loginSchema),
    defaultValues: initialValues,
  });

  const onSubmit: SubmitHandler<LoginData> = (data) => {
    return login(data);
  };

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <div className={styles.head}>
          <LogoWithText className={styles.logo} />
        </div>
        <h1 className={styles.ttl}>Sign in</h1>
        <p className={styles.subttl}>Welcome back Admin!</p>
        <div className={styles.body}>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}> {/* Updated here */}
            <Input
              label="Work email"
              placeholder="Please input"
              type="email"
              parentClassName={styles.input}
              className={styles.mainInput}
              required
              validatorMessage={errors.email?.message}
              name="email"
              register={register}
            />
            <Input
              label="Password"
              placeholder=""
              type="password"
              parentClassName={styles.passwordInput}
              className={styles.mainInput}
              required
              validatorMessage={errors.password?.message}
              name="password"
              register={register}
            />
            <Link className={styles.footer} to={Routes.forgotPassword}>
              Forgot password?
            </Link>
            <Button
              className={styles.continue}
              onClick={handleSubmit(onSubmit)}
              type="fill"
            >
              Sign in
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export { LoginUI };

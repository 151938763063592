import * as React from "react";
import { TableBody, TableBodyRow } from "..";
import styles from "./styles.module.scss";
import { DotIcon } from "assets";

export interface ReportCheckType {
  index: number;
  value: boolean;
}

// Test Table Body Item
export interface ReportsTableItem {
  id: string;
  reportTitle: string;
  startDate: string;
  status: string;
  total: string;
  completed: string;
  abandoned: string;
  deadline: string;
}

// Test Table Body Props
interface TableBodyProps {
  tableBodyItems: ReportsTableItem[];
  tableBodyItemClassName?: string;
  tableBodyRowClassName?: string;
  tableBodyStatus?: string;
  tableBody?: string;
  statusItem?: string;
  statusColor?: string;
  shownHeaders: { title: string; Icon: React.FC }[];
  view: (id: any) => void;
}

const ReportsTable: React.FC<TableBodyProps> = ({
  tableBody,
  tableBodyItems,
  tableBodyItemClassName,
  tableBodyRowClassName,
  statusItem,
  statusColor,
  shownHeaders,
  view,
}) => {
  return (
    <>
      <TableBody customClassName={`${styles.tableBody} ${tableBody}`}>
        {tableBodyItems.map((item, index) => (
          <TableBodyRow
            key={`body ${index}`}
            customClassName={`${styles.tableBodyRow} ${tableBodyRowClassName}`}
          >
            {shownHeaders.map((header, headerIndex) => (
              <div
                key={headerIndex}
                className={`${tableBodyItemClassName} ${styles.box}`}
              >
                {header.title === "Assessments title" && (
                  <span
                    onClick={() => {
                      view(item.id);
                    }}
                    className={`${styles.title}`}
                  >
                    {item.reportTitle}
                  </span>
                )}

                {header.title === "Start date" && (
                  <span className={`${styles.date}`}>{item.startDate}
                  </span>
                )}
                {header.title === "Status" && (
                  <span
                    className={`${styles.statusSection}`}
                  >
                    <p
                      className={`
                      ${statusItem} 
                      ${statusColor}
                      ${styles.status}
                      ${styles['text'+item.status]}
                      `}
                    >
                      <DotIcon
                        className={`${styles.dot} ${styles['dot'+item.status]}`}
                      />
                      <span>{item.status}</span>
                    </p>
                  </span>
                )}
                {header.title === "Total candidates" && (
                  <span className={tableBodyItemClassName}>{item.total}</span>
                )}
                {header.title === "Completed" && (
                  <span className={tableBodyItemClassName}>
                    {item.completed}
                  </span>
                )}
                {header.title === "Abandoned" && (
                  <span className={tableBodyItemClassName}>
                    {item.abandoned}
                  </span>
                )}
                {header.title === "Deadline" && (
                  <span
                    className={`${tableBodyItemClassName} ${styles.deadline}
                  ${
                    item.deadline === "Not live"
                      ? styles.textNotLive
                      : item.deadline === "Ended"
                      ? styles.textEnded
                      : styles.textDays
                  }`}
                  >
                    {item.deadline}
                  </span>
                )}
              </div>
            ))}
          </TableBodyRow>
        ))}
      </TableBody>
    </>
  );
};

export { ReportsTable };

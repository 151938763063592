import React, { useState } from "react";
import styles from "./styles.module.scss";
import {
  GroupTab,
  Pagination,
  ReviewsFilter,
  ReviewsTable,
  ReviewsTableItem,
  Search,
  Table,
} from "components";
import { ArrowIcon, EmptyTable, UsersIcon } from "assets";
import { EmptyComponent } from "../employers";
import { useNavigate, useParams } from "react-router-dom";
import { Routes } from "router";

interface ReviewsProps {
  reviewsTable: ReviewsTableItem[];
  reviewSearchTerm: string;
  handleReviewSearch: (data: any) => void;
  handleReviewFilter: (data: any) => void;
  handleView: (id: any, id2: any, id3: any) => void;
  reviewPagination: {
    handleChange: (page: any) => void;
    total: number;
    current: number;
    limit: number;
  };
  reviewSort: () => void;
  assessmentTitle: string;
}

const ReviewsUI: React.FC<ReviewsProps> = ({
  reviewsTable,
  reviewSearchTerm,
  handleReviewSearch,
  handleReviewFilter,
  reviewPagination,
  reviewSort,
  handleView,
  assessmentTitle,
}) => {
  const reviewsTableHeaderTitles = [
    {
      index: 1,
      title: "Candidate name",
      Icon: EmptyComponent,
    },
    {
      index: 2,
      title: "Rating",
      Icon: ArrowIcon,
    },
    {
      index: 3,
      title: "Comment",
      Icon: EmptyComponent,
    },
  ];
  const [headerVisibility, setHeaderVisibility] = useState(
    reviewsTableHeaderTitles.map(() => true)
  );
  const shouldHideTable = headerVisibility
    .slice(0, -1)
    .every((visible) => !visible);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const title = localStorage.getItem("titleHead");
  return (
    <div className={styles.main}>
      <GroupTab
        title={title ?? ""}
        description="View and manage candidate feedback for this assessment, including individual reviews and overall ratings."
        back={true}
        navigateBack={() => {
          navigate(Routes.assessments("active", id));
        }}
        active={"assessments"}
        id={id}
        navigateProfile={() => {
          navigate(Routes.profile(id));
        }}
        navigateAssessments={() => {
          navigate(Routes.assessments("active", id));
        }}
        navigateReports={() => {
          navigate(Routes.reports(id));
        }}
        items={[
          {
            Icon: UsersIcon,
            label: "Users",
            link: "",
          },
          {
            Icon: EmptyComponent,
            label: "Employers",
            link: Routes.employers,
          },
          {
            Icon: EmptyComponent,
            label: title,
            link: Routes.profile(id),
          },
          {
            Icon: EmptyComponent,
            label: assessmentTitle,
            link: Routes.profile(id),
          },
        ]}
      />
      <div className={styles.reviews}>
        <div className={styles.head}>{assessmentTitle}</div>

        <div className={styles.table}>
          <div className={styles.tableBar}>
            <div className={styles.tableActions}>
              <ReviewsFilter
                submit={handleReviewFilter}
                className={styles.filter}
                show="reports"
              />
              <Search
                className={styles.search}
                value={reviewSearchTerm}
                placeholder="Search in list..."
                handleChange={(e) => {
                  handleReviewSearch(e);
                }}
              />
            </div>
          </div>
          <div className={styles.singleReport}>
            {shouldHideTable ? (
              <div className={styles.emptyTable}>
                <EmptyTable />
              </div>
            ) : (
              <Table
                tableHeaderTitles={reviewsTableHeaderTitles}
                tableBody={
                  <ReviewsTable
                    tableBody={styles.tableBody}
                    tableBodyItems={reviewsTable}
                    tableBodyItemClassName={styles.tableItem}
                    tableBodyRowClassName={styles.reportTableBodyItem}
                    tableBodyStatus={styles.tableBodyStatus}
                    shownHeaders={reviewsTableHeaderTitles}
                    statusItem={styles.statusItem}
                    view={handleView}
                  />
                }
                customTableClasses={{
                  tableContainerClassName: styles.reportTableWrap,
                  tableHeaderClassName: styles.reportTableHeader,
                  tableHeaderItemClassName: styles.tableHeaderItem,
                  statusClass: styles.statusClass,
                  headerStyle: styles.headerStyle,
                }}
                headerVisibility={headerVisibility}
                sort={reviewSort}
              />
            )}
            <Pagination
              current={reviewPagination.current}
              total={reviewPagination.total}
              handleChange={reviewPagination.handleChange}
              limit={reviewPagination.limit}
              parentClassName={styles.pagin}
              classP={styles.paginationBtn}
              paginText={styles.paginText}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { ReviewsUI };

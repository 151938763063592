import { SelectIcon } from "assets";
import styles from "./styles.module.scss";
import { useClickOutside } from "helpers";
import { useState, useRef } from "react";

export interface StatusProps {
  className?: string;
  subClassName?: string;
  items: any[];
}

const SelectDropDown: React.FC<StatusProps> = ({
  className,
  subClassName,
  items,
}) => {
  const [show, setList] = useState(false);
  const dropdownRef = useRef(null);
  useClickOutside(dropdownRef, () => setList(false));

  return (
    <div ref={dropdownRef} className={`${styles.sort} ${className}`}>
      <div
        onClick={() => setList(!show)}
        role="button"
        className={`${styles.sortHd} ${show && styles.select}`}
      >
        <SelectIcon />
      </div>

      {show && (
        <div className={`${styles.sortList} ${subClassName}`}>
          {items.map((item, index) => (
            <div key={index} className={styles.sortList__items} onClick={()=>{setList(false)}}>
              <div className={styles.item} onClick={item.action}>
                {item.icon ? (
                  <item.icon
                    role={"button"}
                    className={`${styles.icon}`}
                    id="icon"
                  />
                ) : (
                  ""
                )}
                {item.name}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export { SelectDropDown };

/*
=================================
AUTH SERVICES
=================================
*/

import { loginURL, postRequest, forgotPasswordURL, resetPasswordURL, getRequest, logoutURL } from "api";

/**
 * login service
 * @returns axios promise
 */

export interface loginData {
  email: string;
  password: string;
}

export const loginService = (data: loginData) => {
  const request = {
    url: loginURL(),
    data
  };
  return postRequest(request);
};

/**
 * forgotPassword Service
 * @returns axios promise
 */

export interface forgotPasswordData {
  email: string;
}

export const forgotPasswordService = (data: forgotPasswordData) => {
  const request = {
    url: forgotPasswordURL(data),
    data
  };
  return getRequest(request);
};

/**
 * reset service
 * @returns axios promise
 */

export interface resetPasswordData {
  email: string;
  token: string;
  password: string;   
}

export const resetPasswordService = (data: resetPasswordData) => {
  const request = {
    url: resetPasswordURL(),
    data
  };
  return postRequest(request);
};

/**
 * logout service
 * @returns axios promise
 */

export const logoutService = () => {
  const request = {
    url: logoutURL()
  };
  return postRequest(request);
};
import React, { useState } from "react";
import styles from "./styles.module.scss";
import { DoughnutChart } from "components/charts";
import { CircleCheck } from "components/circleCheck";
import { TickIcon, XIcon } from "assets";
import { Plugin } from "chart.js";

interface StackDropDownprops {
  plugin: Plugin<"doughnut">[];
  stack: string;
  score: string;
  questions: {
    question: string;
    options: {
      isCorrect: boolean;
      isChosen: boolean;
    }[]
  }[];
  doughnutScore: string;
}

const StackDropDown: React.FC<StackDropDownprops> = ({
  plugin,
  stack,
  score,
  doughnutScore,
  questions,
}) => {
  const [show, setShow] = useState(true);
  return (
    <section className={styles.stackSection}>
      <div className={styles.stack}>
        <div className={styles.left}>
          <div className={styles.chart}>
            <DoughnutChart
              score={doughnutScore}
              radius={[0]}
              cutout="60%"
              total={100}
              color={["#605BFF", "#DFDEFF"]}
              plugins={plugin}
            />
          </div>
          <div className={styles.deets}>
            <p className={styles.stackHead}>{stack}</p>
            <p className={styles.stackText}>{score}</p>
          </div>
        </div>
        <div
          className={styles.right}
          onClick={() => {
            setShow((prev) => !prev);
          }}
        >
          {show ? `Hide answers` : `Show answers`}
        </div>
      </div>

      {show && (
        <>
          <div className={styles.line}></div>
          {questions.map((item, index) => (
            <div className={styles.question} key={index}>
              <div className={styles.questionText}>
                {index + 1}{'. '}{item.question} <span className={styles.star}>*</span>{" "}
              </div>
              <div className={styles.questionProp}>
                Select the most likely answer
              </div>
              <div className={styles.options}>
                {item.options.map((value, id) => (
                  <div
                    key={id}
                    className={`${styles.option} ${
                      value.isChosen && styles.border
                    }`}
                  >
                    <div className={styles.opt}>
                      <CircleCheck
                        id={""}
                        checked={value.isChosen}
                        onChange={() => {}}
                        inputClassName={styles.checkBox}
                      />
                      <p className={styles.optionText}>Option {id+1}</p>
                    </div>
                    {value.isCorrect ? <TickIcon /> : <XIcon />}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </>
      )}
    </section>
  );
};

export { StackDropDown };

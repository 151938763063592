import { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ChevronIcon } from "assets";
import { SideBarDropDown, dropDownType } from "layout/dropDown";

export type menu =
  | "MENU"
  | "Overview"
  | "Templates"
  | "Users"
  | "Employers"
  | "Candidates"
  | "Payment Information"
  | "Reviews";

export type admin =
  | "ADMINISTRATION"
  | "Integrations"
  | "Support"
  | "Account"
  | "Sign Out";

export interface SidebarType {
  active: menu | admin;
  state: menu | admin;
  url: string;
  type: "link" | "header" | "dropdown";
  action: () => void;
  categories: dropDownType[];
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const SidebarItem: React.FC<SidebarType> = ({
  active,
  state,
  type,
  url,
  action,
  Icon,
  categories,
}) => {
  const contentEl = useRef<HTMLDivElement>(null);
  const [showCategories, setShowCategories] = useState(false);
  const [removeOverflow, setOverflow] = useState(false);

  const location = useLocation();
  const path = location.pathname;

  const isActive = () => {
    if (
      path.includes("/users/") ||
      path.includes("/profile/") ||
      path.includes("/reports/") ||
      path.includes("/assessments/") ||
      path.includes("/reviews/")
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const active = isActive();
    setShowCategories(active);
    if (contentEl.current) {
      contentEl.current.style.height = active
        ? `${contentEl.current.scrollHeight}px`
        : "0px";
    }
  }, [path, state]);

  const navigate = useNavigate();
  const showDropDown = () => {
    setShowCategories(!showCategories);
    if (showCategories && contentEl.current)
      contentEl.current.style.height = `${contentEl.current?.scrollHeight}px;`;
  };
  return (
    <ul>
      <li
        onClick={() => {
          navigate(url);
        }}
        className={`
          ${styles.last} 
          ${type === "header" && styles.header} 
          ${styles.sidebarItem} 
          ${active === state ? styles.activeItem : ""}
        `}
      >
        {type === "link" ? (
          <Link
            onClick={() => {
              showDropDown()
              action()
            }}
            className={styles.sidebarType}
            to={url}>
            <div
              className={styles.nameIcon}>
              <Icon
                className={`${styles.sidebarIcon} ${
                  state === "Sign Out" && styles.logout
                }`}
              />
              <span className={styles.sidebarText}>{state}</span>
            </div>
            {state === "Users" && (
              <ChevronIcon
                className={`${styles.downIcon} ${
                  showCategories ? styles.up : ""
                }`}
              />
            )}
          </Link>
        ) : (
          <p className={`${styles.header}`}>{state}</p>
        )}
      </li>
      <div
        ref={contentEl}
        className={styles.sidebarItemWrap}
        style={
          showCategories && removeOverflow && contentEl.current
            ? { height: "auto" }
            : showCategories && contentEl.current
            ? { height: contentEl.current.scrollHeight }
            : { height: "0px" }
        }
      >
        {categories &&
          categories.map((item, index) => (
            <SideBarDropDown
              key={index}
              dropDownItem={item}
              parentHeight={(x) => setOverflow(x)}
            />
          ))}
      </div>
    </ul>
  );
};

export { SidebarItem };

import React from "react";
import styles from "./styles.module.scss";
import { ChartData, ChartOptions } from "chart.js";
import { Line } from "react-chartjs-2";

interface LineChartProps {
  className?: string;
  data: ChartData<'line'>;
  totalEarningsData: number[];
  totalUsersData: number[];
  noOfCandidatesData: number[];
  noOfEmployersData: number[];
  candidatesTopUpData: number[];
  extendAccessData: number[];
}

const LineChart: React.FC<LineChartProps> = ({
  className,
  data,
  totalEarningsData,
  totalUsersData,
  noOfCandidatesData,
  noOfEmployersData,
  candidatesTopUpData,
  extendAccessData
}) => {

  const options: ChartOptions<"line"> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        display: true,
        grid: {
          display: false,
        },
        ticks: {
          display: true,
          stepSize: 100000,
          padding: 20,
        },
        border: {
          display: false,
        },
        beginAtZero: true,
      },
      x: {
        display: true,
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          autoSkip: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "rgba(255, 255, 255, 0.8)", // White background for the tooltip
        titleColor: "#667085",
        bodyColor: "#667085",
        borderColor: "rgba(102, 112, 133, 0.08)",
        borderWidth: 1,
        titleFont: {
          size: 12,
        },
        bodyFont: {
          size: 12,
        },
        padding: {
          top: 15,   
          right: 20, 
          bottom: 15,
          left: 10   
        },
        displayColors: false,
        cornerRadius: 8,
        callbacks: {
          label: (context) => {
            const earnings = `Earnings:                   $${totalEarningsData[context.dataIndex]}`
            const topUp = `Candidate Top-Up:    $${candidatesTopUpData[context.dataIndex]}`
            const extendAccess = `Extend access:          $${extendAccessData[context.dataIndex]}`
            const candidates = `No. of candidates:     $${noOfCandidatesData[context.dataIndex]}`
            const employers = `No. of employers:      $${noOfEmployersData[context.dataIndex]}`
            const users = `No. of users:              $${totalUsersData[context.dataIndex]}`
            return [
              earnings,
              topUp,
              extendAccess,
              candidates,
              employers,
              users
            ];
          },
        },
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
  };

  return (
    <div className={`${styles.chartGraph} ${className}`}>
      <Line data={data} options={options}></Line>
    </div>
  );
};

export { LineChart };

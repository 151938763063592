import React from "react";
import { TableBody, TableBodyRow } from "..";
import styles from "./styles.module.scss";
import { CopyIcon, DotIcon, EyeIcon } from "assets";
import { SelectDropDown } from "components/selectDropDown";

export interface AssessmentCheckType {
  index: number;
  value: boolean;
}

export interface AssessmentsTableItem {
  modalID: string;
  id: string;
  assessmentTitle: string;
  startDate: string;
  endDate: string;
  status: string;
}

interface TableBodyProps {
  tableBodyItems: AssessmentsTableItem[];
  tableBodyItemClassName?: string;
  tableBodyRowClassName?: string;
  tableBodyStatus?: string;
  tableBody?: string;
  statusItem?: string;
  statusColor?: string;
  shownHeaders: { title: string; Icon: React.FC }[];
  copyLink: (id: any) => void;
  viewReview: (id: any) => void;
  assessmentModal: (id: string) => void;
}

const AssessmentsTable: React.FC<TableBodyProps> = ({
  tableBody,
  tableBodyItems,
  tableBodyItemClassName,
  tableBodyRowClassName,
  tableBodyStatus,
  statusItem,
  statusColor,
  shownHeaders,
  viewReview,
  copyLink,
  assessmentModal
}) => {
  const items = (assessmentID: string, id: string) => [
    {
      name: "Copy link",
      icon: CopyIcon,
      action: () => {
        copyLink(assessmentID)
      },
    },
    {
      name: "View reviews",
      icon: EyeIcon,
      action: () => {
        viewReview(id)
      },
    },
  ];
  return (
    <>
      <TableBody customClassName={`${styles.tableBody} ${tableBody}`}>
        {tableBodyItems.map((item, index) => (
          <TableBodyRow
            key={`body ${index}`}
            customClassName={`${styles.tableBodyRow} ${tableBodyRowClassName}`}
          >
            {shownHeaders.map((header, headerIndex) => (
              <div
                key={headerIndex}
                className={`${tableBodyItemClassName} ${styles.box}`}
              >
                {header.title === "Assessments title" && (
                  <span
                    onClick={() => {
                      assessmentModal(item.modalID)
                    }}
                    className={`${styles.title}`}
                  >
                    {item.assessmentTitle}
                  </span>
                )}

                {header.title === "Start date" && (
                  <span className={`${tableBodyItemClassName} ${styles.date}`}>{item.startDate}
                  </span>
                )}
                {header.title === "End date" && (
                  <span className={`${tableBodyItemClassName} ${styles.date}`}>{item.endDate}</span>
                )}
                {header.title === "Status" && (
                  <span
                    className={`${tableBodyItemClassName} ${styles.statusSection}`}
                  >
                    <p
                      className={`
                      ${statusItem} 
                      ${statusColor}
                      ${styles.status}
                      ${styles['text'+item.status]}
                      `}
                    >
                      <DotIcon
                        className={`${styles.dot} ${styles['dot'+item.status]}`}
                      />
                      <span>{item.status}</span>
                    </p>
                  </span>
                )}
                {header.title === "Dots" && (
                  <span className={`${tableBodyItemClassName} ${styles.dots}`}>
                    <SelectDropDown
                      className={styles.SelectDropDown}
                      items={items(item.id, item.modalID)}
                    />
                  </span>
                )}
              </div>
            ))}
          </TableBodyRow>
        ))}
      </TableBody>
    </>
  );
};

export { AssessmentsTable };
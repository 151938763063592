import { resetPasswordService } from "api";
import { Preloader, Toast } from "components";
import { useApiRequest } from "hooks/useApiRequest";
import { ResetPasswordUI, ResetData } from "modules";
import { useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Routes } from "router";

const ResetPassword = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [toast, setToast] = useState({ show: false, head: "", message: "" });
  const token = searchParams.get("token");
  const email = searchParams.get("email");
  const { run: runReset, data: ResetResponse, error, requestStatus } = useApiRequest({});
  const resetPassword = (data: ResetData) => {
    const password = data.password;

    token &&
      email &&
      runReset(resetPasswordService({ email, token, password }));
  };

  useMemo(() => {
    if (ResetResponse?.status === 200) {
      setToast({
        show: true,
        head: "Success",
        message: "You have successfully reset your password."
      });
      setTimeout(() => {
        navigate(Routes.home);
      }, 2000);
    } else if (error) {
      setToast({
        show: true,
        head: "Error",
        message: error.message,
      });
    }
  }, [ResetResponse, error, navigate]);

  const handleClose = () => {
    setToast({ show: false, head: "", message: "" });
  };
  return (
    <>
      <Toast
        onClose={handleClose}
        loading={toast.show}
        head={toast.head}
        message={toast.message}
      />
      <Preloader loading={requestStatus.isPending} />
      <ResetPasswordUI reset={resetPassword} />
    </>
  );
};

export { ResetPassword };

import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import { Button, CustomSelect, GroupTab, Input } from "components";
import { profilePicture, UsersIcon } from "assets";
import { optionType } from "types";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import { Routes } from "router";
import { EmptyComponent } from "../employers";

export interface ContactFormData {
  firstname: string;
  lastname: string;
  phone: string;
  email: string;
  job: string;
}

export interface CompanyFormData {
  companyName: string;
  industry: string;
  hiringFrequency: optionType;
  employeeNo: optionType;
}
interface ProfileProps {
  contactSubmit: (data: ContactFormData) => void;
  companySubmit: (data: CompanyFormData) => void;
  contactValues: ContactFormData;
  companyValues: CompanyFormData;
  companyLogo: string;
  title: string;
}

const contactFormSchema = yup.object({
  firstname: yup.string().required("Required"),
  lastname: yup.string().required("Required"),
  phone: yup.string().required("Required"),
  email: yup.string().email().required("Required"),
  job: yup.string().required("Required"),
});

const companyFormSchema = yup.object({
  companyName: yup.string().required("Required"),
  industry: yup.string().required("Required"),
  hiringFrequency: yup.object({
    label: yup.string().required("Required"),
    value: yup.string().required("Required"),
  }),
  employeeNo: yup.object({
    label: yup.string().required("Required"),
    value: yup.string().required("Required"),
  }),
});

const ProfileUI: React.FC<ProfileProps> = ({
  contactSubmit,
  companySubmit,
  contactValues,
  companyValues,
  companyLogo,
  title
}) => {
  const {
    register: contactRegister,
    handleSubmit: contactHandleSubmit,
    formState: { errors: contactError },
    reset: resetContact,
  } = useForm<ContactFormData>({
    resolver: yupResolver(contactFormSchema),
    defaultValues: contactValues,
  });
  const {
    register: companyRegister,
    watch: companyWatch,
    setValue: companySetValue,
    handleSubmit: companyHandleSubmit,
    formState: { errors: companyError },
    reset: resetCompany,
  } = useForm<CompanyFormData>({
    resolver: yupResolver(companyFormSchema),
    defaultValues: companyValues,
  });
  const { id } = useParams<{ id: string }>();
  const onContactSubmit: SubmitHandler<ContactFormData> = (data) => {
    return contactSubmit(data);
  };
  const onCompanySubmit: SubmitHandler<CompanyFormData> = (data) => {
    return companySubmit(data);
  };
  const noOfEmployees: optionType[] = [
    {
      label: "1-15",
      value: "1-15",
    },
    {
      label: "16-50",
      value: "16-50",
    },
    {
      label: "51-100",
      value: "51-100",
    },
    {
      label: "1000+",
      value: "1000+",
    },
  ];

  const hiringFrequency: optionType[] = [
    {
      label: "1 - 5 hire annually",
      value: "1 - 5 hire annually",
    },
    {
      label: "6 - 5 hire annually",
      value: "6 - 5 hire annually",
    },
    {
      label: "16 - 30 hire annually",
      value: "16 - 30 hire annually",
    },
    {
      label: "50+ hire annually",
      value: "21 - 50 hire annually",
    },
  ];
  
  const navigate = useNavigate();

  useEffect(() => {
    resetContact(contactValues);
    resetCompany(companyValues);
  }, [companyValues, resetCompany]);

  return (
    <div className={styles.profile}>
      <GroupTab
        title={title}
        description="Access and update the detailed information for this employer."
        active={"profile"}
        id={id}
        navigateProfile={() => {
          navigate(Routes.profile(id));
        }}
        navigateAssessments={() => {
          navigate(Routes.assessments("active", id));
        }}
        navigateReports={() => {
          navigate(Routes.reports(id));
        }}
        items={[
          {
            Icon: UsersIcon,
            label: "Users",
            link: "",
          },
          {
            Icon: EmptyComponent,
            label: "Employers",
            link: Routes.employers,
          },
          {
            Icon: EmptyComponent,
            label: title,
            link: Routes.profile(id),
          },
        ]}
      />
      <div className={styles.profileInfo}>
        <div className={styles.contact}>
          <h3 className={styles.head}>Contact Info</h3>
          <img alt="" src={profilePicture} className={styles.contactPfp} />
          <div className={styles.name}>
            <Input
              label="First name"
              placeholder="Ayo"
              type="text"
              className={styles.input}
              parentClassName={styles.inputContainer}
              required
              validatorMessage={contactError.firstname?.message}
              name="firstname"
              register={contactRegister}
            />
            <Input
              label="Last name"
              placeholder="Phillip"
              type="text"
              className={styles.input}
              parentClassName={styles.inputContainer}
              required
              validatorMessage={contactError.lastname?.message}
              name="lastname"
              register={contactRegister}
            />
          </div>
          <Input
            label="Phone number"
            placeholder="+2348120000000"
            type="number"
            className={styles.input}
            parentClassName={styles.inputContainer}
            required
            validatorMessage={contactError.phone?.message}
            name="phone"
            register={contactRegister}
          />
          <Input
            label="Email address"
            placeholder="ayophilips@visiongroup.com"
            type="text"
            className={styles.input}
            parentClassName={styles.inputContainer}
            required
            validatorMessage={contactError.email?.message}
            name="email"
            register={contactRegister}
          />
          <Input
            label="Job role"
            placeholder="HR Manager"
            type="text"
            className={styles.input}
            parentClassName={styles.inputContainer}
            required
            validatorMessage={contactError.job?.message}
            name="job"
            register={contactRegister}
          />
          <Button type={"fill"} onClick={contactHandleSubmit(onContactSubmit)}>
            Submit
          </Button>
        </div>
        <div className={styles.contact}>
          <h3 className={styles.head}>Company Info</h3>
          <img alt="" src={companyLogo} className={styles.contactPfp} />
          <Input
            label="Company name"
            placeholder="Vision Group"
            type="text"
            className={styles.input}
            parentClassName={styles.inputContainer}
            required
            validatorMessage={companyError.companyName?.message}
            name="companyName"
            register={companyRegister}
          />
          <Input
            label="Industry"
            placeholder="Please input"
            type="text"
            className={styles.input}
            parentClassName={styles.inputContainer}
            required
            validatorMessage={companyError.industry?.message}
            name="industry"
            register={companyRegister}
          />
          <CustomSelect
            onChange={(val) => companySetValue(`hiringFrequency`, val)}
            validatorMessage={
              companyError?.hiringFrequency
                ? companyError?.hiringFrequency?.value?.message?.toString() ??
                  ""
                : ""
            }
            name={`hiringFrequency`}
            placeholder={"Please select"}
            options={hiringFrequency}
            value={companyWatch("hiringFrequency")}
            parentClassName={styles.inputContainer}
            inputClass={styles.select}
            label="Annual Hiring Frequency"
          />
          <CustomSelect
            onChange={(val) => companySetValue(`employeeNo`, val)}
            validatorMessage={
              companyError?.employeeNo
                ? companyError?.employeeNo?.value?.message?.toString() ?? ""
                : ""
            }
            name={`employeeNo`}
            placeholder={"51 - 100"}
            options={noOfEmployees}
            value={companyWatch("employeeNo")}
            parentClassName={styles.inputContainer}
            inputClass={styles.select}
            label="No. of employees"
          />
          <Button type={"fill"} onClick={companyHandleSubmit(onCompanySubmit)}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export { ProfileUI };

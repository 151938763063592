import React from "react";
import styles from "./styles.module.scss";
import { BreadCrumbs } from "components/breadCrumbs";
import { ArrowLeftIcon } from "assets";

interface GroupTabProps {
  active: string;
  id?: string;
  navigateProfile: (id?: string) => void;
  navigateAssessments: (id?: string) => void;
  navigateReports: (id?: string) => void;
  items: any[];
  back?: boolean;
  navigateBack?: () => void;
  title?: string;
  description?: string;
}

const GroupTab: React.FC<GroupTabProps> = ({
  active,
  id,
  navigateProfile,
  navigateAssessments,
  navigateReports,
  items,
  back,
  navigateBack,
  title,
  description
}) => {
  return (
    <div className={styles.groupTab}>
      <BreadCrumbs className={styles.navItem} items={items} />
      <div className={styles.bigHead}>
        <div className={styles.header}>
          <h3 className={styles.head}>{title}</h3>
          <p className={styles.subHead}>
            {description}
          </p>
        </div>
        {back &&
          <div
            className={styles.back}
            onClick={navigateBack}
          >
            <ArrowLeftIcon/>
            Back
          </div>}
      </div>

      <div className={styles.filter}>
        <div className={styles.filterTab}>
          <div
            className={`${styles.filterBtn} ${
              active === "profile" && styles.active
            }`}
            onClick={() => {
              navigateProfile(id);
            }}
          >
            Profile
          </div>
          <div
            className={`${styles.filterBtn} ${
              active === "assessments" && styles.active
            }`}
            onClick={() => {
              navigateAssessments(id);
            }}
          >
            Assessments
          </div>
          <div
            className={`${styles.filterBtn} ${
              active === "reports" && styles.active
            }`}
            onClick={() => {
              navigateReports(id);
            }}
          >
            Reports
          </div>
        </div>
      </div>
    </div>
  );
};

export { GroupTab };

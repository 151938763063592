import { CandidatesIcon, FilterIconOutline } from "assets";
import { useState } from "react";
import styles from "./styles.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  ReviewsFilterData,
  ReviewsSchema,
  initReviewsFilterData,
  ReviewsFilterModal,
  ActiveFilters,
} from "components";

interface ReviewsFilterProps {
  submit: (data: ReviewsFilterData) => void;
  className?: string;
  show: string;
}

const ReviewsFilter: React.FC<ReviewsFilterProps> = ({
  className = "",
  submit,
}) => {
  const form = useForm<ReviewsFilterData>({
    resolver: yupResolver<ReviewsFilterData>(ReviewsSchema),
    defaultValues: initReviewsFilterData,
  });

  const [showModal, setModal] = useState(false);
  const handleFilterSubmit = (data: ReviewsFilterData) => {
    submit(data);
  };

  const filter = [
    {
      icon: <CandidatesIcon />,
      value: !form.getValues(`rating.value`) ? "" : `${form.getValues(`rating.value`)}`,
      reset: () => {
        form.setValue("rating.value", "");
        handleFilterSubmit(form.watch());
      },
    },
  ].filter((item) => item.value !== "");

  return (
    <>
      {filter.length === 0 ? (
        <div
          onClick={() => setModal(true)}
          className={`${styles.filter} ${className}`}
        >
          <FilterIconOutline role="button" className={styles.filterIcon} />
          <p className={styles.text}>Filter</p>
        </div>
      ) : (
        <ActiveFilters
          className={className}
          click={() => {
            setModal(true);
          }}
          filterData={filter}
        />
      )}
      <ReviewsFilterModal
        show={showModal}
        close={() => setModal(false)}
        submit={handleFilterSubmit}
        form={form}
      />
    </>
  );
};
export { ReviewsFilter };

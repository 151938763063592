import React, { ReactNode, useState } from "react";
import styles from "./styles.module.scss";
import {
  ChevronIcon,
  ConflictIcon,
  CopyIcon,
  EyeIcon,
  MatchIcon,
  UsersIcon,
} from "assets";
import { Plugin } from "chart.js";
import {
  Button,
  DoughnutChart,
  DropDown,
  GroupTab,
  RatingBar,
  StackDropDown,
} from "components";
import { useNavigate, useParams } from "react-router-dom";
import { Routes } from "router";
import { EmptyComponent } from "../employers";

interface CandidateReportProps {
  score: string;
  name: string;
  email: string;
  phoneNumber: string;
  copyLink: () => void;
  viewCV: () => void;
}

const CandidateReportUI: React.FC<CandidateReportProps> = ({
  score,
  name,
  email,
  phoneNumber,
  copyLink,
  viewCV
}) => {
  const centerTextPlugin: Plugin<"doughnut"> = {
    id: "centerText",
    beforeDraw: (chart) => {
      const { ctx, width, height } = chart;
      const number = chart.data.datasets[0].data[0] as number;
      const text = `${number.toString()}%`;
      const text2 = `avg.`;

      ctx.save();

      ctx.fillStyle = "#F29339";
      const fontSizeNumber = (height / 4).toFixed(2);
      ctx.font = `${fontSizeNumber}px Gelion`;
      ctx.textBaseline = "middle";

      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / 2 - 10;

      ctx.fillText(text, textX, textY);

      ctx.fillStyle = "#98A2B3";
      const fontSizeText2 = (height / 13).toFixed(2);
      ctx.font = `300 ${fontSizeText2}px Gelion`;
      const text2X = Math.round((width - ctx.measureText(text2).width) / 2);
      const text2Y = textY + parseInt(fontSizeText2) + 30;

      ctx.fillText(text2, text2X, text2Y);

      ctx.restore();
    },
  };

  const centerTextPlugin2: Plugin<"doughnut"> = {
    id: "centerText2",
    beforeDraw: (chart) => {
      const { ctx, width, height } = chart;
      const number = chart.data.datasets[0].data[0] as number;
      const text = `${number.toString()}%`;

      ctx.save();

      ctx.fillStyle = "#605BFF";
      const fontSizeNumber = (height / 4).toFixed(2);
      ctx.font = `${fontSizeNumber}px Gelion`;
      ctx.textBaseline = "middle";

      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / 2;

      ctx.fillText(text, textX, textY);

      ctx.restore();
    },
  };

  const [showGeneral, setShowGeneral] = useState(false);

  const questions = [
    {
      question: "What is a corrected syntax to output 'Hello World' in python?",
      options: [
        { isCorrect: false, isChosen: false },
        { isCorrect: true, isChosen: true },
        { isCorrect: false, isChosen: false },
        { isCorrect: false, isChosen: false },
      ],
    },
    {
      question: "What is a corrected syntax to output 'Hello World' in python?",
      options: [
        { isCorrect: true, isChosen: false },
        { isCorrect: false, isChosen: true },
        { isCorrect: true, isChosen: false },
        { isCorrect: false, isChosen: false },
        { isCorrect: true, isChosen: false },
        { isCorrect: false, isChosen: false },
      ],
    },
    {
      question: "What is a corrected syntax to output 'Hello World' in python?",
      options: [
        { isCorrect: true, isChosen: false },
        { isCorrect: false, isChosen: false },
        { isCorrect: true, isChosen: true },
        { isCorrect: false, isChosen: false },
      ],
    },
    {
      question: "What is a corrected syntax to output 'Hello World' in python?",
      options: [
        { isCorrect: false, isChosen: false },
        { isCorrect: true, isChosen: true },
        { isCorrect: false, isChosen: false },
        { isCorrect: false, isChosen: false },
      ],
    },
  ];

  const { id, id2, tab } = useParams<{ id: string, id2: string, tab: string }>();
  const navigate = useNavigate();
  const companyTitle = localStorage.getItem('titleHead') || ''
  return (
    <div className={styles.main}>
      <GroupTab
        title={companyTitle}
        description="View a thorough analysis of a candidate's assessment, including performance metrics."
        back={true}
        navigateBack={() => {
          tab === "assessed" && navigate(Routes.reportAssessed(id, id2));
          tab === "consider" && navigate(Routes.reportConsider(id, id2));
          tab === "toInterview" && navigate(Routes.reportInterview(id, id2));
          tab === "rejected" && navigate(Routes.reportRejected(id, id2));
        }}
        active={"reports"}
        id={id}
        navigateProfile={() => {
          navigate(Routes.profile(id));
        }}
        navigateAssessments={() => {
          navigate(Routes.assessments("active", id));
        }}
        navigateReports={() => {
          navigate(Routes.reports(id));
        }}
        items={[
          {
            Icon: UsersIcon,
            label: "Users",
            link: "",
          },
          {
            Icon: EmptyComponent,
            label: "Employers",
            link: Routes.employers,
          },
          {
            Icon: EmptyComponent,
            label: companyTitle,
            link: Routes.profile(id),
          },
        ]}
      />
      <div className={styles.candidateReport}>
        <div className={styles.general}>
          <div className={styles.head}>
            <div className={styles.details}>
              <h3 className={styles.title}>General</h3>
              <p className={styles.text}>
                This is a general overview of {name}'s details
              </p>
            </div>
            <ChevronIcon
              onClick={() => {
                setShowGeneral(!showGeneral);
              }}
              className={`${styles.chevron}  ${showGeneral ? styles.up : ""}`}
            />
          </div>
          {!showGeneral && (
            <div className={styles.body}>
              <div className={styles.chart}>
                <DoughnutChart
                  score={score}
                  cutout="80%"
                  radius={[20, 0]}
                  total={100}
                  color={["#F29339", "#FFF5E6"]}
                  plugins={[centerTextPlugin]}
                  className={styles.innerChart}
                />
              </div>
              <div className={styles.candidatesDetails}>
                <p className={styles.texts}>Precision report</p>
                <div className={styles.name}>{name}</div>
                <div className={styles.deets}>
                  <p className={styles.email}>{email}</p>
                  <p className={styles.number}>{phoneNumber}</p>
                </div>

                <div className={styles.btns}>
                  <a
                    onClick={viewCV} className={styles.cvBtn} href="###">
                    <EyeIcon /> CV
                  </a>
                  <Button
                    className={styles.cvBtn}
                    type={"transparent"}
                    onClick={copyLink}
                  >
                    {" "}
                    <CopyIcon /> Portfolio Link
                  </Button>
                </div>
              </div>
              <div className={styles.rBtn}>
                <Button
                  className={styles.reportBtn}
                  type={"transparent"}
                  onClick={() => {}}
                >
                  {" "}
                  <CopyIcon /> Share report
                </Button>
              </div>
            </div>
          )}
        </div>
        <div className={styles.specific}>
          <div className={styles.basic}>
            <ShowHide
              head="Summary"
              body={
                <div className={styles.summary}>
                  <div className={styles.summaryText}>
                    The New normal reality has presented managers with a new
                    series of challenges with regards to balancing employee
                    performance, effectiveness and development. In personal
                    qualitative research interviewing 10 business and HR
                    managers on the employee shocks experienced during the March
                    Lockdown in Nigeria, a reoccurring theme was seen in
                    employee quality.
                  </div>
                  <ul className={styles.keyPoints}>
                    <div className={styles.head}>Key Point/ take overs</div>
                    <li>
                      Basic Expectations: Below Expectations (insert % score)
                    </li>
                    <li>Soft Skills: Limited (insert % score)</li>
                    <li>Work Style: Below Average (insert % score)</li>
                    <li>Personality: Concerning (insert % score)</li>
                    <li>
                      Workplace Qualities: Below Satisfactory (insert % score)
                    </li>
                    <li>Work Motivators: Limited (insert % score)</li>
                    <li>Domain Knowledge: Insufficient (insert % score)</li>
                  </ul>
                </div>
              }
            />
            <ShowHide
              head="Basic Expectations"
              body={
                <div className={styles.scroll}>
                  <div className={styles.basicExpectations}>
                    <div className={styles.chartt}>
                      <div className={styles.dough}>
                        <DoughnutChart
                          score="50"
                          radius={[0]}
                          cutout="60%"
                          total={100}
                          color={["#605BFF", "#DFDEFF"]}
                          plugins={[centerTextPlugin2]}
                        />
                      </div>

                      <div className={styles.explanation}>
                        This explains what basic expectation is supposed to
                        reveal. the description should be precise.
                      </div>
                    </div>
                    <div className={styles.experienceLevel}>
                      <p className={styles.text}>Experienve level</p>
                      <p className={styles.text}>Years of experince</p>
                      <p className={styles.text}>Experienve Preference</p>
                      <p className={styles.text}>Salary Expectation</p>
                      <p className={styles.text}>Experience enviroment</p>
                    </div>
                    <div className={styles.expectation}>
                      <p className={styles.title}>Expectation Metric</p>
                      <div className={styles.info}>
                        <p className={styles.grey}>Mid level</p>
                        <p className={styles.grey}>5-8 years</p>
                        <p className={styles.grey}>Contract</p>
                        <p className={styles.grey}>NGN 100K-200K</p>
                        <p className={styles.grey}>Growing Business</p>
                      </div>
                    </div>
                    <div className={styles.expectation}>
                      <p className={styles.title}>Candidate's Response</p>
                      <div className={styles.info}>
                        <p className={styles.grey}>Associate level</p>
                        <p className={styles.grey}>3-5 years</p>
                        <p className={styles.grey}>Remote</p>
                        <p className={styles.grey}>NGN 300K-500K</p>
                        <p className={styles.grey}>Growing Business</p>
                      </div>
                    </div>
                    <div className={styles.expectation}>
                      <p className={styles.title}>Result</p>
                      <div className={styles.info}>
                        <p className={styles.conflict}>
                          Conflict <ConflictIcon />
                        </p>
                        <p className={styles.conflict}>
                          Conflict <ConflictIcon />
                        </p>
                        <p className={styles.conflict}>
                          Conflict <ConflictIcon />
                        </p>
                        <p className={styles.conflict}>
                          Conflict <ConflictIcon />
                        </p>
                        <p className={styles.match}>
                          Match <MatchIcon />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              }
            />
            <ShowHide
              head="Soft skills"
              body={
                <div className={`${styles.softSkills}`}>
                  <div className={styles.chartt}>
                    <div className={styles.dough}>
                      <DoughnutChart
                        score="60"
                        radius={[0]}
                        cutout="60%"
                        total={100}
                        color={["#605BFF", "#DFDEFF"]}
                        plugins={[centerTextPlugin2]}
                      />
                    </div>
                    <div className={styles.ch}>
                      <div className={styles.explanation}>
                        This explains what basic expectation is supposed to
                        reveal. the description should be precise.
                      </div>

                      <div className={styles.rating}>
                        <div className={styles.level}>
                          Excellent:{" "}
                          <span className={styles.amount}>100 - 90%</span>
                        </div>
                        <div className={`${styles.level} ${styles.effective}`}>
                          Effective:{" "}
                          <span className={styles.amount}>89 - 70%</span>
                        </div>
                        <div className={`${styles.level} ${styles.average}`}>
                          Average:{" "}
                          <span className={styles.amount}>9 - 50%</span>
                        </div>
                        <div className={`${styles.level} ${styles.growth}`}>
                          Needs Growth:{" "}
                          <span className={styles.amount}>49% & below</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.half}>
                    <div className={styles.analytical}>
                      <p className={styles.head}>Analytical thinking</p>
                      <RatingBar rating={85} barClassName={styles.bar} />
                      <div className={styles.desc}>
                        <div className={styles.result}>
                          <p className={styles.percentage}>75%</p>
                          <p className={styles.text}>Candidate's Result</p>
                        </div>
                        <div className={styles.expectations}>
                          <p className={styles.percentage}>89 - 70%</p>
                          <p className={styles.text}>Expectation</p>
                        </div>
                      </div>
                      <div className={styles.word}>
                        This explains what analytical thinking is supposed to
                        be. the description should be precise.
                      </div>
                    </div>
                    <div className={`${styles.analytical} ${styles.agile}`}>
                      <p className={styles.head}>Agile work-ethic</p>
                      <RatingBar rating={35} barClassName={styles.bar} />
                      <div className={styles.desc}>
                        <div className={styles.result}>
                          <p className={styles.percentage}>75%</p>
                          <p className={styles.text}>Candidate's Result</p>
                        </div>
                        <div className={styles.expectations}>
                          <p className={styles.percentage}>89 - 70%</p>
                          <p className={styles.text}>Expectation</p>
                        </div>
                      </div>
                      <div className={styles.word}>
                        This explains what agile thinking is supposed to be. the
                        description should be precise.
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.half} `}>
                    <div className={`${styles.analytical} ${styles.creative}`}>
                      <p className={styles.head}>Creative thinking</p>
                      <RatingBar rating={50} barClassName={styles.bar} />
                      <div className={styles.desc}>
                        <div className={styles.result}>
                          <p className={styles.percentage}>75%</p>
                          <p className={styles.text}>Candidate's Result</p>
                        </div>
                        <div className={styles.expectations}>
                          <p className={styles.percentage}>89 - 70%</p>
                          <p className={styles.text}>Expectation</p>
                        </div>
                      </div>
                      <div className={styles.word}>
                        This explains what analytical thinking is supposed to
                        be. the description should be precise.
                      </div>
                    </div>
                    <div className={`${styles.analytical} ${styles.lead}`}>
                      <p className={styles.head}>Leadership/ Management</p>
                      <RatingBar rating={80} barClassName={styles.bar} />
                      <div className={styles.desc}>
                        <div className={styles.result}>
                          <p className={styles.percentage}>75%</p>
                          <p className={styles.text}>Candidate's Result</p>
                        </div>
                        <div className={styles.expectations}>
                          <p className={styles.percentage}>89 - 70%</p>
                          <p className={styles.text}>Expectation</p>
                        </div>
                      </div>
                      <div className={styles.word}>
                        This explains what agile thinking is supposed to be. the
                        description should be precise.
                      </div>
                    </div>
                  </div>
                </div>
              }
            />
            <ShowHide
              head="Work style"
              body={
                <div
                  className={`${styles.basicExpectations} ${styles.softSkills} ${styles.workStyle}`}
                >
                  <div className={styles.chartt}>
                    <div className={styles.dough}>
                      <DoughnutChart
                        score="65"
                        radius={[0]}
                        cutout="60%"
                        total={100}
                        color={["#605BFF", "#DFDEFF"]}
                        plugins={[centerTextPlugin2]}
                      />
                    </div>
                    <div className={styles.ch}>
                      <div className={styles.explanation}>
                        This explains what basic expectation is supposed to
                        reveal. the description should be precise.
                      </div>

                      <div className={styles.rating}>
                        <div className={styles.level}>
                          Excellent:{" "}
                          <span className={styles.amount}>100 - 90%</span>
                        </div>
                        <div className={`${styles.level} ${styles.effective}`}>
                          Effective:{" "}
                          <span className={styles.amount}>89 - 70%</span>
                        </div>
                        <div className={`${styles.level} ${styles.average}`}>
                          Average:{" "}
                          <span className={styles.amount}>9 - 50%</span>
                        </div>
                        <div className={`${styles.level} ${styles.growth}`}>
                          Needs Growth:{" "}
                          <span className={styles.amount}>49% & below</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.half}>
                    <div className={styles.analytical}>
                      <p className={styles.head}>Priotitising</p>
                      <RatingBar rating={85} barClassName={styles.bar} />
                      <div className={styles.desc}>
                        <div className={styles.result}>
                          <p className={styles.percentage}>75%</p>
                          <p className={styles.text}>Candidate's Result</p>
                        </div>
                        <div className={styles.expectations}>
                          <p className={styles.percentage}>89 - 70%</p>
                          <p className={styles.text}>Expectation</p>
                        </div>
                      </div>
                      <div className={styles.word}>
                        <div className={styles.point}>
                          <div className={styles.pointHead}>Key Quality</div>
                          <div className={styles.pointBody}>
                            Laser-focused on execution, prioritising what needs
                            to be done until it is done.
                          </div>
                        </div>
                        <div className={styles.point}>
                          <div className={styles.pointHead}>Good at</div>
                          <ul className={styles.pointBody}>
                            <li>Laser-focused on execution</li>
                            <li>Independent work</li>
                            <li>Agility and flexibility</li>
                          </ul>
                        </div>
                        <div className={styles.point}>
                          <div className={styles.pointHead}>Poor at</div>
                          <ul className={styles.pointBody}>
                            <li>Communicating</li>
                            <li>Collaborating</li>
                            <li>Not following processes</li>
                            <li>Blind spots to shortcuts</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className={`${styles.analytical} ${styles.agile}`}>
                      <p className={styles.head}>Planning</p>
                      <RatingBar rating={35} barClassName={styles.bar} />
                      <div className={styles.desc}>
                        <div className={styles.result}>
                          <p className={styles.percentage}>75%</p>
                          <p className={styles.text}>Candidate's Result</p>
                        </div>
                        <div className={styles.expectations}>
                          <p className={styles.percentage}>89 - 70%</p>
                          <p className={styles.text}>Expectation</p>
                        </div>
                      </div>
                      <div className={styles.word}>
                        <div className={styles.point}>
                          <div className={styles.pointHead}>Key Quality</div>
                          <div className={styles.pointBody}>
                            Laser-focused on execution, prioritising what needs
                            to be done until it is done.
                          </div>
                        </div>
                        <div className={styles.point}>
                          <div className={styles.pointHead}>Good at</div>
                          <ul className={styles.pointBody}>
                            <li>Laser-focused on execution</li>
                            <li>Independent work</li>
                            <li>Agility and flexibility</li>
                          </ul>
                        </div>
                        <div className={styles.point}>
                          <div className={styles.pointHead}>Poor at</div>
                          <ul className={styles.pointBody}>
                            <li>Communicating</li>
                            <li>Collaborating</li>
                            <li>Not following processes</li>
                            <li>Blind spots to shortcuts</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.half}`}>
                    <div className={`${styles.analytical} ${styles.creative}`}>
                      <p className={styles.head}>Visualizing</p>
                      <RatingBar rating={50} barClassName={styles.bar} />
                      <div className={styles.desc}>
                        <div className={styles.result}>
                          <p className={styles.percentage}>75%</p>
                          <p className={styles.text}>Candidate's Result</p>
                        </div>
                        <div className={styles.expectations}>
                          <p className={styles.percentage}>89 - 70%</p>
                          <p className={styles.text}>Expectation</p>
                        </div>
                      </div>
                      <div className={styles.word}>
                        <div className={styles.point}>
                          <div className={styles.pointHead}>Key Quality</div>
                          <div className={styles.pointBody}>
                            Laser-focused on execution, prioritising what needs
                            to be done until it is done.
                          </div>
                        </div>
                        <div className={styles.point}>
                          <div className={styles.pointHead}>Good at</div>
                          <ul className={styles.pointBody}>
                            <li>Laser-focused on execution</li>
                            <li>Independent work</li>
                            <li>Agility and flexibility</li>
                          </ul>
                        </div>
                        <div className={styles.point}>
                          <div className={styles.pointHead}>Poor at</div>
                          <ul className={styles.pointBody}>
                            <li>Communicating</li>
                            <li>Collaborating</li>
                            <li>Not following processes</li>
                            <li>Blind spots to shortcuts</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className={`${styles.analytical} ${styles.lead}`}>
                      <p className={styles.head}>Coordinating</p>
                      <RatingBar rating={80} barClassName={styles.bar} />
                      <div className={styles.desc}>
                        <div className={styles.result}>
                          <p className={styles.percentage}>75%</p>
                          <p className={styles.text}>Candidate's Result</p>
                        </div>
                        <div className={styles.expectations}>
                          <p className={styles.percentage}>89 - 70%</p>
                          <p className={styles.text}>Expectation</p>
                        </div>
                      </div>
                      <div className={styles.word}>
                        <div className={styles.point}>
                          <div className={styles.pointHead}>Key Quality</div>
                          <div className={styles.pointBody}>
                            Laser-focused on execution, prioritising what needs
                            to be done until it is done.
                          </div>
                        </div>
                        <div className={styles.point}>
                          <div className={styles.pointHead}>Good at</div>
                          <ul className={styles.pointBody}>
                            <li>Laser-focused on execution</li>
                            <li>Independent work</li>
                            <li>Agility and flexibility</li>
                          </ul>
                        </div>
                        <div className={styles.point}>
                          <div className={styles.pointHead}>Poor at</div>
                          <ul className={styles.pointBody}>
                            <li>Communicating</li>
                            <li>Collaborating</li>
                            <li>Not following processes</li>
                            <li>Blind spots to shortcuts</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            />
            <ShowHide
              head="Workspace qualities"
              body={
                <div
                  className={`${styles.basicExpectations} ${styles.softSkills} ${styles.workStyle} ${styles.workspaceQualities}`}
                >
                  <div className={styles.chartt}>
                    <div className={styles.dough}>
                      <DoughnutChart
                        score="100"
                        radius={[0]}
                        cutout="60%"
                        total={100}
                        color={["#605BFF", "#DFDEFF"]}
                        plugins={[centerTextPlugin2]}
                      />
                    </div>
                    <div className={styles.ch}>
                      <div className={styles.explanation}>
                        This explains what basic expectation is supposed to
                        reveal. the description should be precise.
                      </div>

                      <div className={styles.rating}>
                        Employers expectation:
                        <div className={styles.level}>
                          Responsible
                          <MatchIcon className={styles.tickIcon} />
                        </div>
                        <div className={`${styles.level} ${styles.effective}`}>
                          Innovative
                          <MatchIcon className={styles.tickIcon} />
                        </div>
                        <div className={`${styles.level} ${styles.average}`}>
                          Supportive
                          <MatchIcon className={styles.tickIcon} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.rightSide}>
                    <div className={styles.half}>
                      <div className={styles.analytical}>
                        <p className={styles.head}>
                          Responsible{" "}
                          <span className={styles.matchTick}>
                            Match <MatchIcon />
                          </span>
                        </p>
                        <div className={styles.desc}>
                          <div className={styles.point}>
                            <div className={styles.pointHead}>
                              Characteritics
                            </div>
                            <ul className={styles.pointBody}>
                              <li>
                                Active imagination; sees implications,
                                inferences, and hidden meanings
                              </li>
                              <li>
                                Reliable, hard-working, responsible, and
                                trustworthy
                              </li>
                              <li>
                                Doubting, contrarian thinking, sometimes
                                procrastinates when fearing an outcome{" "}
                              </li>
                              <li>Questions authority</li>
                            </ul>
                          </div>
                          <div className={styles.point}>
                            <div className={styles.pointHead}>
                              Behaviours at work
                            </div>
                            <ul className={styles.pointBody}>
                              <li>
                                Excellent troubleshooters and Devil’s Advocates,
                                and can foresee problems thanks to their
                                extensive planning and the “what if” scenarios
                                in their heads
                              </li>
                              <li>
                                Comfortable evaluating/analyzing information and
                                ideas
                              </li>
                              <li>Follow the rules</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className={`${styles.analytical} ${styles.agile}`}>
                        <p className={styles.head}>
                          Innovative{" "}
                          <span className={styles.matchTick}>
                            Match <MatchIcon />
                          </span>
                        </p>
                        <div className={styles.desc}>
                          <div className={styles.point}>
                            <div className={styles.pointHead}>
                              Characteritics
                            </div>
                            <ul className={styles.pointBody}>
                              <li>
                                Active imagination; sees implications,
                                inferences, and hidden meanings
                              </li>
                              <li>
                                Reliable, hard-working, responsible, and
                                trustworthy
                              </li>
                              <li>
                                Doubting, contrarian thinking, sometimes
                                procrastinates when fearing an outcome{" "}
                              </li>
                              <li>Questions authority</li>
                            </ul>
                          </div>
                          <div className={styles.point}>
                            <div className={styles.pointHead}>
                              Behaviours at work
                            </div>
                            <ul className={styles.pointBody}>
                              <li>
                                Excellent troubleshooters and Devil’s Advocates,
                                and can foresee problems thanks to their
                                extensive planning and the “what if” scenarios
                                in their heads
                              </li>
                              <li>
                                Comfortable evaluating/analyzing information and
                                ideas
                              </li>
                              <li>Follow the rules</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            />
            <ShowHide
              head="Work motivators"
              body={
                <div
                  className={`${styles.basicExpectations} ${styles.softSkills} ${styles.workStyle} ${styles.workspaceQualities} ${styles.workMotivators}`}
                >
                  <div className={styles.chartt}>
                    <div className={styles.dough}>
                      <DoughnutChart
                        score="35"
                        radius={[0]}
                        cutout="60%"
                        total={100}
                        color={["#605BFF", "#DFDEFF"]}
                        plugins={[centerTextPlugin2]}
                      />
                    </div>
                    <div className={styles.ch}>
                      <div className={styles.explanation}>
                        This explains what basic expectation is supposed to
                        reveal. the description should be precise.
                      </div>

                      <div className={styles.rating}>
                        Employers expectation:
                        <div className={`${styles.level} ${styles.red}`}>
                          Autonomy
                          <ConflictIcon className={styles.tickIcon} />
                        </div>
                        <div className={`${styles.level} ${styles.effective}`}>
                          Power
                          <MatchIcon className={styles.tickIcon} />
                        </div>
                        <div className={`${styles.level} ${styles.red}`}>
                          Teamwork
                          <ConflictIcon className={styles.tickIcon} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.desc}>
                    <div className={styles.analytical}>
                      <p className={styles.head}>
                        Candidate's response:
                        <span className={styles.matchTick}>
                          Conflict <ConflictIcon />
                        </span>
                      </p>
                      <div className={styles.desc}>Dedication to a cause</div>
                      <div className={styles.word}>
                        <div className={styles.point}>
                          <div className={styles.pointHead}>Definition</div>
                          <div className={styles.pointBody}>
                            This motivator represents a deep commitment to work
                            that aligns with personal values and makes a
                            positive impact.
                          </div>
                        </div>
                        <div className={styles.point}>
                          <div className={styles.pointHead}>Pros: </div>
                          <ul className={styles.pointBody}>
                            <li>
                              Individuals dedicated to a cause can demonstrate
                              high levels of commitment and passion.
                            </li>
                            <li>
                              They can inspire others and promote a positive
                              work culture.
                            </li>
                            <li>
                              A shared purpose can foster a sense of belonging
                              and engagement.
                            </li>
                          </ul>
                        </div>
                        <div className={styles.point}>
                          <div className={styles.pointHead}>Cons: </div>
                          <ul className={styles.pointBody}>
                            <li>
                              Misalignment between personal values and
                              organizational goals may lead to dissatisfaction
                              and disengagement.
                            </li>
                            <li>Collaborating</li>
                            <li>
                              The pursuit of a cause can sometimes overshadow
                              other essential aspects of work.
                            </li>
                            <li>
                              {" "}
                              Finding a common cause that resonates with all
                              team members can be challenging.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className={styles.analytical}>
                      <p className={styles.head}>
                        Candidate's response:
                        <span className={styles.matchTick}>
                          Conflict <ConflictIcon />
                        </span>
                      </p>
                      <div className={styles.desc}>Dedication to a cause</div>
                      <div className={styles.word}>
                        <div className={styles.point}>
                          <div className={styles.pointHead}>Definition</div>
                          <div className={styles.pointBody}>
                            This motivator represents a deep commitment to work
                            that aligns with personal values and makes a
                            positive impact.
                          </div>
                        </div>
                        <div className={styles.point}>
                          <div className={styles.pointHead}>Pros: </div>
                          <ul className={styles.pointBody}>
                            <li>
                              Individuals dedicated to a cause can demonstrate
                              high levels of commitment and passion.
                            </li>
                            <li>
                              They can inspire others and promote a positive
                              work culture.
                            </li>
                            <li>
                              A shared purpose can foster a sense of belonging
                              and engagement.
                            </li>
                          </ul>
                        </div>
                        <div className={styles.point}>
                          <div className={styles.pointHead}>Cons: </div>
                          <ul className={styles.pointBody}>
                            <li>
                              Misalignment between personal values and
                              organizational goals may lead to dissatisfaction
                              and disengagement.
                            </li>
                            <li>Collaborating</li>
                            <li>
                              The pursuit of a cause can sometimes overshadow
                              other essential aspects of work.
                            </li>
                            <li>
                              {" "}
                              Finding a common cause that resonates with all
                              team members can be challenging.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className={`${styles.analytical} ${styles.mastery}`}>
                      <p className={styles.head}>
                        Candidate's response:
                        <span className={styles.matchTick}>
                          Conflict <ConflictIcon />
                        </span>
                      </p>
                      <div className={styles.desc}>Dedication to a cause</div>
                      <div className={styles.word}>
                        <div className={styles.point}>
                          <div className={styles.pointHead}>Definition</div>
                          <div className={styles.pointBody}>
                            This motivator represents a deep commitment to work
                            that aligns with personal values and makes a
                            positive impact.
                          </div>
                        </div>
                        <div className={styles.point}>
                          <div className={styles.pointHead}>Pros: </div>
                          <ul className={styles.pointBody}>
                            <li>
                              Individuals dedicated to a cause can demonstrate
                              high levels of commitment and passion.
                            </li>
                            <li>
                              They can inspire others and promote a positive
                              work culture.
                            </li>
                            <li>
                              A shared purpose can foster a sense of belonging
                              and engagement.
                            </li>
                          </ul>
                        </div>
                        <div className={styles.point}>
                          <div className={styles.pointHead}>Cons: </div>
                          <ul className={styles.pointBody}>
                            <li>
                              Misalignment between personal values and
                              organizational goals may lead to dissatisfaction
                              and disengagement.
                            </li>
                            <li>Collaborating</li>
                            <li>
                              The pursuit of a cause can sometimes overshadow
                              other essential aspects of work.
                            </li>
                            <li>
                              {" "}
                              Finding a common cause that resonates with all
                              team members can be challenging.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            />
            <ShowHide
              head="Personality"
              body={
                <div
                  className={`${styles.basicExpectations} ${styles.softSkills} ${styles.personality}`}
                >
                  <div className={styles.chartt}>
                    <div className={styles.dough}>
                      <DoughnutChart
                        score="20"
                        radius={[0]}
                        cutout="60%"
                        total={100}
                        color={["#605BFF", "#DFDEFF"]}
                        plugins={[centerTextPlugin2]}
                      />
                    </div>
                    <div className={styles.ch}>
                      <div className={styles.explanation}>
                        This explains what basic expectation is supposed to
                        reveal. the description should be precise.
                      </div>

                      <div className={styles.rating}>
                        Employers expectation:
                        <div className={`${styles.level} ${styles.effective}`}>
                          Dynamic Networker
                        </div>
                        Candidates personality type:
                        <div className={`${styles.level} ${styles.effective}`}>
                          Thoughtful Organizer
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.half}>
                    <div className={styles.analytical}>
                      <p className={styles.head}>Interacting with People</p>
                      <RatingBar rating={85} barClassName={styles.bar} />
                      <div className={styles.desc}>
                        <div className={styles.result}>
                          <p className={styles.percentage}>75%</p>
                          <p className={styles.text}>Independent</p>
                        </div>
                        <div className={styles.expectations}>
                          <p className={styles.percentage}>25%</p>
                          <p className={styles.text}>Social</p>
                        </div>
                      </div>
                      <div className={styles.word}>
                        This explains what interacting with people is supposed
                        to be. the description should be precise.
                      </div>
                    </div>
                    <div className={`${styles.analytical} ${styles.agile}`}>
                      <p className={styles.head}>Processing Information</p>
                      <RatingBar rating={35} barClassName={styles.bar} />
                      <div className={styles.desc}>
                        <div className={styles.result}>
                          <p className={styles.percentage}>45%</p>
                          <p className={styles.text}>Creative</p>
                        </div>
                        <div className={styles.expectations}>
                          <p className={styles.percentage}>55%</p>
                          <p className={styles.text}>Practical</p>
                        </div>
                      </div>
                      <div className={styles.word}>
                        This explains what interacting with people is supposed
                        to be. the description should be precise.
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.half}`}>
                    <div className={`${styles.analytical} ${styles.creative}`}>
                      <p className={styles.head}>Problem Solving</p>
                      <RatingBar rating={50} barClassName={styles.bar} />
                      <div className={styles.desc}>
                        <div className={styles.result}>
                          <p className={styles.percentage}>90%</p>
                          <p className={styles.text}>Emotions</p>
                        </div>
                        <div className={styles.expectations}>
                          <p className={styles.percentage}>10%</p>
                          <p className={styles.text}>Logic</p>
                        </div>
                      </div>
                      <div className={styles.word}>
                        This explains what interacting with people is supposed
                        to be. the description should be precise.
                      </div>
                    </div>
                    <div className={`${styles.analytical} ${styles.lead}`}>
                      <p className={styles.head}>Descision Making</p>
                      <RatingBar rating={80} barClassName={styles.bar} />
                      <div className={styles.desc}>
                        <div className={styles.result}>
                          <p className={styles.percentage}>60%</p>
                          <p className={styles.text}>Candidate's Result</p>
                        </div>
                        <div className={styles.expectations}>
                          <p className={styles.percentage}>40%</p>
                          <p className={styles.text}>Expectation</p>
                        </div>
                      </div>
                      <div className={styles.word}>
                        This explains what interacting with people is supposed
                        to be. the description should be precise.
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.organise}`}>
                    <div className={styles.analytical}>
                      <p className={styles.head}>Thoughtful Organizer</p>
                      <div className={styles.desc}>
                        <div className={styles.point}>
                          <div className={styles.pointHead}>Strengths</div>
                          <ul className={styles.pointBody}>
                            <li>
                              <span className={styles.liHead}>
                                Holistic Perspective:{" "}
                              </span>
                              Possesses the ability to both emotional and
                              practical aspects of a situation leading to a
                              comprehensive and well-rounded solution.
                            </li>
                            <li>
                              <span className={styles.liHead}>
                                Attention to Detail:{" "}
                              </span>
                              Possesses the ability to both emotional and
                              practical aspects of a situation leading to a
                              comprehensive and well-rounded solution.
                            </li>
                            <li>
                              <span className={styles.liHead}>
                                Effective Time Management:{" "}
                              </span>
                              Possesses the ability to both emotional and
                              practical aspects of a situation leading to a
                              comprehensive and well-rounded solution.
                            </li>
                            <li>
                              <span className={styles.liHead}>
                                Strong Interpersonal Skills:{" "}
                              </span>
                              Possesses the ability to both emotional and
                              practical aspects of a situation leading to a
                              comprehensive and well-rounded solution.
                            </li>
                            <li>
                              <span className={styles.liHead}>
                                Conflict Resolution:{" "}
                              </span>
                              Possesses the ability to both emotional and
                              practical aspects of a situation leading to a
                              comprehensive and well-rounded solution.
                            </li>
                          </ul>
                        </div>
                        <div className={styles.point}>
                          <div className={styles.pointHead}>Growth areas</div>
                          <ul className={styles.pointBody}>
                            <li>
                              <span className={styles.liHead}>
                                Overthinking and Analysis Paralysis:{" "}
                              </span>
                              Possesses the ability to both emotional and
                              practical aspects of a situation leading to a
                              comprehensive and well-rounded solution.
                            </li>
                            <li>
                              <span className={styles.liHead}>
                                Perfectionism:{" "}
                              </span>
                              Possesses the ability to both emotional and
                              practical aspects of a situation leading to a
                              comprehensive and well-rounded solution.
                            </li>
                            <li>
                              <span className={styles.liHead}>
                                Difficulty with collaborative tasks and
                                delegating:{" "}
                              </span>
                              Possesses the ability to both emotional and
                              practical aspects of a situation leading to a
                              comprehensive and well-rounded solution.
                            </li>
                            <li>
                              <span className={styles.liHead}>
                                Resistance to change:{" "}
                              </span>
                              Possesses the ability to both emotional and
                              practical aspects of a situation leading to a
                              comprehensive and well-rounded solution.
                            </li>
                            <li>
                              <span className={styles.liHead}>
                                Avoidance of Confrontation:{" "}
                              </span>
                              Possesses the ability to both emotional and
                              practical aspects of a situation leading to a
                              comprehensive and well-rounded solution.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            />
            <ShowHide
              head="Domain Knowledge"
              body={
                <div className={styles.domainKnowledge}>
                  <div className={styles.question}>
                    <div className={styles.questionHead}>Key question: </div>
                    <div className={styles.questionText}>
                      Tell us about a challenge you used this skill to solve,
                      how you approached it, the impact it made
                    </div>
                  </div>
                  <section className={styles.section}>
                    <div className={styles.score}>
                      <span className={styles.scoreHead}>
                        Conflict Management{" "}
                      </span>
                      <span className={styles.scoreText}>Score:</span>
                      <span className={styles.scoreActual}>7/10</span>
                    </div>
                    <DropDown
                      title="Score reason: "
                      body="Lörem ipsum is simply dummy text of the printing and typesetting industry."
                    />
                    <DropDown
                      title="Candidates reason: "
                      body="While building the Sabimovement Social Construct platform, a
                    project aimed at assisting men and boys in confronting
                    age-long and culturally inherited biases to create an equal
                    and safe society, I encountered a challenge related to scope
                    and schedule management. As the project progressed, it
                    became apparent that the scope was expanding beyond the
                    initial plans, potentially leading to delays and budget
                    overruns. To address this challenge, I did a thorough
                    assessment of the project scope. I re-analyzed the
                    requirements, objectives, and deliverables and also engaged
                    with key stakeholders, including community leaders, gender
                    experts, and potential platform users, to gather their input
                    and align their expectations with the project's scope. Based
                    on the assessment and stakeholder input, I developed a
                    comprehensive project plan. This plan outlined specific
                    activities, milestones, and timelines to guide the eventual
                    project's execution."
                    />
                  </section>
                  <section className={styles.section}>
                    <div className={styles.score}>
                      <span className={styles.scoreHead}>
                        Performance Management{" "}
                      </span>
                      <span className={styles.scoreText}>Score: </span>
                      <span className={styles.scoreActual}>7/10</span>
                    </div>
                    <DropDown
                      title="Score reason: "
                      body="Lörem ipsum is simply dummy text of the printing and typesetting industry."
                    />
                    <DropDown
                      title="Candidates reason: "
                      body="While building the Sabimovement Social Construct platform, a
                    project aimed at assisting men and boys in confronting
                    age-long and culturally inherited biases to create an equal
                    and safe society, I encountered a challenge related to scope
                    and schedule management. As the project progressed, it
                    became apparent that the scope was expanding beyond the
                    initial plans, potentially leading to delays and budget
                    overruns. To address this challenge, I did a thorough
                    assessment of the project scope. I re-analyzed the
                    requirements, objectives, and deliverables and also engaged
                    with key stakeholders, including community leaders, gender
                    experts, and potential platform users, to gather their input
                    and align their expectations with the project's scope. Based
                    on the assessment and stakeholder input, I developed a
                    comprehensive project plan. This plan outlined specific
                    activities, milestones, and timelines to guide the eventual
                    project's execution."
                    />
                  </section>
                  <section className={styles.section}>
                    <div className={styles.score}>
                      <span className={styles.scoreHead}>
                        Quality Management{" "}
                      </span>
                      <span className={styles.scoreText}>Score: </span>
                      <span className={styles.scoreActual}>7/10</span>
                    </div>
                    <DropDown
                      title="Score reason: "
                      body="Lörem ipsum is simply dummy text of the printing and typesetting industry."
                    />
                    <DropDown
                      title="Candidates reason: "
                      body="While building the Sabimovement Social Construct platform, a
                    project aimed at assisting men and boys in confronting
                    age-long and culturally inherited biases to create an equal
                    and safe society, I encountered a challenge related to scope
                    and schedule management. As the project progressed, it
                    became apparent that the scope was expanding beyond the
                    initial plans, potentially leading to delays and budget
                    overruns. To address this challenge, I did a thorough
                    assessment of the project scope. I re-analyzed the
                    requirements, objectives, and deliverables and also engaged
                    with key stakeholders, including community leaders, gender
                    experts, and potential platform users, to gather their input
                    and align their expectations with the project's scope. Based
                    on the assessment and stakeholder input, I developed a
                    comprehensive project plan. This plan outlined specific
                    activities, milestones, and timelines to guide the eventual
                    project's execution."
                    />
                  </section>
                  <section className={styles.section}>
                    <div className={styles.score}>
                      <span className={styles.scoreHead}>Team management </span>
                    </div>
                    <DropDown
                      title="Score reason: "
                      body="Lörem ipsum is simply dummy text of the printing and typesetting industry."
                    />
                    <DropDown
                      title="Candidates reason: "
                      body="While building the Sabimovement Social Construct platform, a
                    project aimed at assisting men and boys in confronting
                    age-long and culturally inherited biases to create an equal
                    and safe society, I encountered a challenge related to scope
                    and schedule management. As the project progressed, it
                    became apparent that the scope was expanding beyond the
                    initial plans, potentially leading to delays and budget
                    overruns. To address this challenge, I did a thorough
                    assessment of the project scope. I re-analyzed the
                    requirements, objectives, and deliverables and also engaged
                    with key stakeholders, including community leaders, gender
                    experts, and potential platform users, to gather their input
                    and align their expectations with the project's scope. Based
                    on the assessment and stakeholder input, I developed a
                    comprehensive project plan. This plan outlined specific
                    activities, milestones, and timelines to guide the eventual
                    project's execution."
                    />
                    <div className={styles.line}></div>
                    <DropDown
                      title="Score reason: "
                      body="Lörem ipsum is simply dummy text of the printing and typesetting industry."
                    />
                    <DropDown
                      title="Candidates reason: "
                      body="While building the Sabimovement Social Construct platform, a
                    project aimed at assisting men and boys in confronting
                    age-long and culturally inherited biases to create an equal
                    and safe society, I encountered a challenge related to scope
                    and schedule management. As the project progressed, it
                    became apparent that the scope was expanding beyond the
                    initial plans, potentially leading to delays and budget
                    overruns. To address this challenge, I did a thorough
                    assessment of the project scope. I re-analyzed the
                    requirements, objectives, and deliverables and also engaged
                    with key stakeholders, including community leaders, gender
                    experts, and potential platform users, to gather their input
                    and align their expectations with the project's scope. Based
                    on the assessment and stakeholder input, I developed a
                    comprehensive project plan. This plan outlined specific
                    activities, milestones, and timelines to guide the eventual
                    project's execution."
                    />
                  </section>
                  <section className={styles.section}>
                    <div className={styles.score}>
                      <span className={styles.scoreHead}>
                        Project management{" "}
                      </span>
                    </div>
                    <DropDown
                      title="Score reason: "
                      body="Lörem ipsum is simply dummy text of the printing and typesetting industry."
                    />
                    <DropDown
                      title="Candidates reason: "
                      body="While building the Sabimovement Social Construct platform, a
                    project aimed at assisting men and boys in confronting
                    age-long and culturally inherited biases to create an equal
                    and safe society, I encountered a challenge related to scope
                    and schedule management. As the project progressed, it
                    became apparent that the scope was expanding beyond the
                    initial plans, potentially leading to delays and budget
                    overruns. To address this challenge, I did a thorough
                    assessment of the project scope. I re-analyzed the
                    requirements, objectives, and deliverables and also engaged
                    with key stakeholders, including community leaders, gender
                    experts, and potential platform users, to gather their input
                    and align their expectations with the project's scope. Based
                    on the assessment and stakeholder input, I developed a
                    comprehensive project plan. This plan outlined specific
                    activities, milestones, and timelines to guide the eventual
                    project's execution."
                    />
                  </section>
                  <StackDropDown
                    doughnutScore="20"
                    plugin={[centerTextPlugin2]}
                    stack="Python"
                    score="02/20"
                    questions={questions}
                  />
                  <StackDropDown
                    doughnutScore="90"
                    plugin={[centerTextPlugin2]}
                    stack="Javascript"
                    score="18/20"
                    questions={questions}
                  />
                  <StackDropDown
                    doughnutScore="90"
                    plugin={[centerTextPlugin2]}
                    stack="Machine Learning"
                    score="18/20"
                    questions={questions}
                  />
                </div>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { CandidateReportUI };

interface ShowHideProps {
  head: string;
  body: ReactNode;
}

const ShowHide: React.FC<ShowHideProps> = ({ head, body }) => {
  const [show, setShow] = useState(true);
  return (
    <div className={styles.container}>
      <div className={styles.head}>
        {head}
        <div className={styles.lineContainer}>
          <div className={styles.linee}></div>
        </div>
        <ChevronIcon
          onClick={() => {
            setShow((prev) => !prev);
          }}
          className={`${styles.chevron} ${!show && styles.up}`}
        />
      </div>
      {show && <div className={styles.body}>{body}</div>}
    </div>
  );
};

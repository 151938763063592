import React from "react";
import { TableBody, TableBodyRow } from "..";
import styles from "./styles.module.scss";
import { DoughnutChart, Popup } from "components";
import { Plugin } from "chart.js";
import { EyeIcon } from "assets";
import { useParams } from "react-router-dom";

export interface ReviewsTableItem {
  id: string;
  candidateName: string;
  rating: string;
  comment: string;
}

interface TableBodyProps {
  tableBodyItems: ReviewsTableItem[];
  tableBodyItemClassName?: string;
  tableBodyRowClassName?: string;
  tableBodyStatus?: string;
  tableBody?: string;
  statusItem?: string;
  statusColor?: string;
  shownHeaders: { title: string; Icon: React.FC }[];
  view: (id: any, id2: any, id3:any) => void;
}

const plugin: Plugin<"doughnut"> = {
  id: "centerText",
  beforeDraw: (chart) => {
    const { ctx, width, height } = chart;
    const score = chart.data.datasets[0].data[0] as number;
    const color = (chart.data.datasets[0].backgroundColor as string[])[0];
    const text = `${score.toString()}`;

    ctx.save();
    ctx.fillStyle = color;
    const fontSize = (height / 3).toFixed(2);
    ctx.font = `${fontSize}px Gelion`;
    ctx.textBaseline = "middle";

    const textX = Math.round((width - ctx.measureText(text).width) / 2);
    const textY = height / 2;

    ctx.fillText(text, textX, textY);
    ctx.restore();
  },
};

const ReviewsTable: React.FC<TableBodyProps> = ({
  tableBody,
  tableBodyItems,
  tableBodyItemClassName,
  tableBodyRowClassName,
  shownHeaders,
  view,
}) => {
  const getColor = (score: number) => {
    if (score <= 6) return "#E74444";
    if (score > 6 && score <= 8) return "#EDA12F";
    if (score > 8 && score <= 10) return "#3DCC87";
    return "#000000";
  };
  const { id, id2 } = useParams<{ id: string; id2: string; }>();
  return (
    <>
      <TableBody customClassName={`${styles.tableBody} ${tableBody}`}>
        {tableBodyItems.map((item, index) => {
          const score = parseFloat(item.rating);
          const color = getColor(score);
          return (
            <TableBodyRow
              key={`body ${index}`}
              customClassName={`${styles.tableBodyRow} ${tableBodyRowClassName}`}
            >
              {shownHeaders.map((header, headerIndex) => (
                <div
                  onClick={() => {
                    view(id, id2, item.id);
                  }}
                  key={headerIndex}
                  className={`${tableBodyItemClassName} ${styles.box}`}
                >
                  {header.title === "Candidate name" && (
                    <span className={`${tableBodyItemClassName}`}>
                      {item.candidateName}
                    </span>
                  )}
                  {header.title === "Rating" && (
                    <span
                      className={`${tableBodyItemClassName} ${styles.statusSection} ${styles.doughnut}`}
                    >
                      <Popup
                        Icon={() => (
                          <DoughnutChart
                            score={item.rating}
                            total={10}
                            color={[color, "#F2F4F7"]}
                            radius={[0]}
                            cutout={"90%"}
                            plugins={[plugin]}
                          />
                        )}
                        text={`${
                          parseInt(item.rating) <= 6
                            ? "Detractors"
                            : parseInt(item.rating) <= 8 &&
                              parseInt(item.rating) > 6
                            ? "Passive"
                            : parseInt(item.rating) <= 10 &&
                              parseInt(item.rating) > 6
                            ? "Promoters"
                            : ""
                        }`}
                        action={() => {}}
                        className={styles.doughnut}
                      />
                    </span>
                  )}
                  {header.title === "Comment" && (
                    <span className={tableBodyItemClassName}>
                      {item.comment}
                    </span>
                  )}
                </div>
              ))}
            </TableBodyRow>
          );
        })}
      </TableBody>
    </>
  );
};

export { ReviewsTable };

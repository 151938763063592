import { Modal } from "components/modal";
import styles from "./styles.module.scss";
import { CloseIcon, profilePicture, SendIcon } from "assets";
import { Search } from "components/search";
import { CheckBox } from "components/checkBox";
import { CheckType } from "components/table";
import { Button } from "components/button";
interface LearnMoreProps {
  show: boolean;
  close: () => void;
  title: string;
  number: string;
  searchTerm: string;
  handleSearch: (data: string) => void;
  checks: boolean[];
  handleCheck: ({ index, value }: CheckType) => void;
  tableItems: any[];
}

const OverviewModal: React.FC<LearnMoreProps> = ({
  close,
  show,
  title,
  number,
  searchTerm,
  handleSearch,
  checks,
  handleCheck,
  tableItems
}) => {
  const checkTrue = checks.filter((value) => value === true);
  return (
    <>
      <Modal
        position="right"
        close={close}
        show={show}
        contentClassName={styles.modal}
      >
        <div className={styles.close}>
          <CloseIcon
            className={styles.closeIcon}
            role="button"
            onClick={close}
          />
        </div>
        <div className={styles.container}>
          <div className={styles.head}>
            <h2 className={styles.name}>{title}</h2>
            <h2 className={styles.name}>{number}</h2>
          </div>
          <Search
            className={styles.search}
            value={searchTerm}
            placeholder="Search in list..."
            handleChange={(e) => {
              handleSearch(e);
            }}
          />
          <div className={styles.table}>
            {tableItems.map((item, index) => (
              <div key={index} className={styles.row}>
                <span className={`${styles.nameSection}`}>
                  <CheckBox
                    inputClassName={styles.check}
                    id={""}
                    checked={checks[index]}
                    onChange={() => handleCheck({ index, value: !checks[index] })}
                  />
                  <img src={profilePicture} alt="" />
                  <span
                    className={styles.nameFlex}
                  >
                    <span
                      className={` ${styles.businessName}`}
                    >
                      {item.businessName}
                    </span>
                    <span
                      className={` ${styles.businessEmail}`}
                    >
                      {item.email}
                    </span>
                  </span>
                </span>
              </div>))}
          </div>

          {checkTrue.length !== 0 && (
            <div className={styles.employerSelected}>
              <div className={styles.amount}>
                {checkTrue.length} employer{checkTrue.length > 1 && "s"}{" "}
                selected
              </div>
              <div className={styles.buttons}>
                <Button
                  type="fill"
                  onClick={() => {
                  }}
                  className={styles.requestBtn}
                >
                  <SendIcon />
                  Download list
                </Button>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export { OverviewModal };

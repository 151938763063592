/*
=================================
AUTH URLS
=================================
*/

import { forgotPasswordData } from "api/services";
import { appendParams } from "helpers";

/**
 * Login URL
 *
 * @returns url string
 *
 */

export const loginURL = () => `/admin/login/`;

/**
 * Fogrot URL
 *
 * @returns url string
 *
 */

export const forgotPasswordURL = (email: forgotPasswordData) => `/admin/reset-password/?${appendParams(email)}`;

/**
 * Reset URL
 *
 * @returns url string
 *
 */
  
export const resetPasswordURL = () => `/admin/reset-password/`;

/**
 * logout URL
 *
 * @returns url string
 *
 */

export const logoutURL = () => `/admin/logout/`;
import { reviewService, singleReviewService } from "api";
import { Preloader, Toast } from "components";
import { SingleReviewUI } from "features";
import { useApiRequest } from "hooks";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

const SingleReview = () => {
  const { id, id2, id3 } = useParams();
  const {
    run: runReviewData,
    data: reviewDataResponse,
    requestStatus: reviewRequestStatus,
    error: reviewError,
  } = useApiRequest({});
  const [toast, setToast] = useState({ show: false, head: "", message: "" });
  const [comment, setComment] = useState("")
  const [assessmentTitle, setAssessmentTitle] = useState("")
  const [rating, setRating] = useState(0)
  const [name, setName] = useState("")
  useMemo(() => {
    if (reviewDataResponse?.status === 200) {
      const reviewData = reviewDataResponse.data.data;
      setComment(reviewData.feedback)
      setAssessmentTitle(reviewData.assessment_title)
      setRating(reviewData.rating)
      setName(reviewData.full_name)
    } else if (reviewError) {
      setToast({
        show: true,
        head: "Error",
        message: reviewError.message,
      });
    }
  }, [reviewDataResponse, reviewError]);
  useEffect(() => {
    runReviewData(singleReviewService(id, id2, id3));
  }, [id, runReviewData]);
  const handleClose = () => {
    setToast({ show: false, head: "", message: "" });
  };
  return (
    <div>
      <Toast
        onClose={handleClose}
        loading={toast.show}
        head={toast.head}
        message={toast.message}
      />
      <Preloader loading={reviewRequestStatus.isPending} />
      <SingleReviewUI
        comment={comment}
        assessmentTitle={assessmentTitle}
        rating={rating}
        name={name}
      />
    </div>
  );
};

export { SingleReview };

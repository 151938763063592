import React from "react";
import styles from "./styles.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { Routes } from "router";
import { GroupTab } from "components";
import { UsersIcon } from "assets";
import { EmptyComponent } from "../employers";

interface SingleReviewProps {
  comment: string;
  assessmentTitle?: string;
  rating: number;
  name: string;
}

const SingleReviewUI: React.FC<SingleReviewProps> = ({
  comment,
  assessmentTitle,
  rating,
  name,
}) => {
  const numbers = [
    {
      id: 1,
      number: "1",
    },
    {
      id: 2,
      number: "2",
    },
    {
      id: 3,
      number: "3",
    },
    {
      id: 4,
      number: "4",
    },
    {
      id: 5,
      number: "5",
    },
    {
      id: 6,
      number: "6",
    },
    {
      id: 7,
      number: "7",
    },
    {
      id: 8,
      number: "8",
    },
    {
      id: 9,
      number: "9",
    },
    {
      id: 10,
      number: "10",
    },
  ];
  const { id, id2, id3 } = useParams<{ id: string; id2: string, id3: string; }>();
  const navigate = useNavigate();
  const title = localStorage.getItem("titleHead");
  return (
    <div className={styles.main}>
      <GroupTab
        title={title ?? ""}
        description="View detailed feedback and ratings provided by the candidate for this assessment."
        back={true}
        navigateBack={()=>{navigate(Routes.reviews(id, id2))}}
        active={"assessments"}
        id={id}
        navigateProfile={() => {
          navigate(Routes.profile(id));
        }}
        navigateAssessments={() => {
          navigate(Routes.assessments("active", id));
        }}
        navigateReports={() => {
          navigate(Routes.reports(id));
        }}
        items={[
          {
            Icon: UsersIcon,
            label: "Users",
            link: "",
          },
          {
            Icon: EmptyComponent,
            label: "Employers",
            link: Routes.employers,
          },
          {
            Icon: EmptyComponent,
            label: title,
            link: Routes.profile(id),
          },
          {
            Icon: EmptyComponent,
            label: assessmentTitle,
            link: Routes.assessments("active", id),
          },
          {
            Icon: EmptyComponent,
            label: name,
            link: Routes.candidateReview(id, id2, id3),
          },
        ]}
      />
      <div className={styles.header}>{name}</div>
      <div className={styles.singleReview}>
        
        <div className={styles.head}>
          We'd love your feedback on the ease of the application process to
          enable us improve.{" "}
        </div>
        <div className={styles.subHead}>
          How easy was the assessment process?{" "}
        </div>
        <div className={styles.rates}>
          <div className={styles.rateNumbers}>
            {numbers.map((item, index) => (
              <div key={index} className={`${styles.circle} ${item.number === rating.toString() && styles.pick}`}>
                <span className={styles.number}>{item.number}</span>
              </div>
            ))}
          </div>
          <div className={styles.rateText}>
            <span className={styles.level}>Not easy</span>
            <span className={styles.level}>Extremely easy</span>
          </div>
        </div>
        <div className={styles.commentHead}>
          Comments to share with us What do you think about this application
          process? give us your candid opinion. We thrive on candor we'd like to
          see this in potential candidates we're considering. Good or bad share
          with us
        </div>
        <div className={styles.comment}>
          {comment}
        </div>
      </div>
    </div>
  );
};

export { SingleReviewUI };

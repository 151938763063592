import styles from "./styles.module.scss";
import { CandidatesIcon, CloseIcon } from "assets";
import * as yup from "yup";
import { SubmitHandler } from "react-hook-form";
import { Button, CustomSelect, Input, InputDropDown, Modal } from "components";
import { optionType } from "types";

export interface SingleReportsFilterData {
  scores?: {
    label?: any;
    value?: any;
  };
  startDate?: string | null;
}

export const initSingleReportsFilterData: SingleReportsFilterData = {
  scores: { label: "", value: "" },
  startDate: null,
};

export const singleReportSchema = yup.object({
  startDate: yup.string().nullable(),
  scores: yup.object({
    label: yup.string().nullable(),
    value: yup.string().nullable(),
  }),
});

interface SingleReportsFilterModalProps {
  show: boolean;
  close: () => void;
  submit: (data: SingleReportsFilterData) => void;
  form: any;
}

const SingleReportsFilterModal: React.FC<SingleReportsFilterModalProps> = ({
  show,
  close,
  submit,
  form,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    reset,
  } = form;
  const onSubmit: SubmitHandler<SingleReportsFilterData> = (data) => {
    submit(data);
    close();
  };
  const onReset = () => {
    reset(initSingleReportsFilterData);
    close();
  };

  const scores: optionType[] = [
    {
      label: "0 - 40",
      value: "0 - 40",
    },
    {
      label: "41 - 70",
      value: "41 - 70",
    },
    {
      label: "71 - 100",
      value: "71 - 100",
    },
  ];

  return (
    <>
      <Modal
        contentClassName={styles.modal}
        position={"centered"}
        close={close}
        show={show}
      >
        <div className={styles.close}>
          <CloseIcon
            className={styles.closeIcon}
            role="button"
            onClick={close}
          />
        </div>
        <div className={styles.container}>
          <div className={styles.header}>
            <p>Filters</p>
          </div>
          <form className={styles.form}>
            <InputDropDown
              Icon={CandidatesIcon}
              className={styles.wrap}
              title="Scores"
            >
              <CustomSelect
                onChange={(val) => setValue(`scores`, val)}
                validatorMessage={
                  errors?.scores
                    ? errors?.scores?.value?.message?.toString() ?? ""
                    : ""
                }
                name={`scores`}
                placeholder={"Please select"}
                options={scores}
                value={watch("scores")}
                parentClassName={styles.inputContainer}
                inputClass={styles.select}
                label=""
              />
            </InputDropDown>
            <InputDropDown
              Icon={CandidatesIcon}
              className={styles.wrap}
              title="Date"
            >
              <Input
                placeholder="Start Date"
                name="startDate"
                type="date"
                validatorMessage={errors.startDate?.message}
                parentClassName={styles.input}
                className={styles.mainInput}
                register={register}
                value={watch("startDate")}
                min={new Date().toISOString().split("T")[0]}
              />
            </InputDropDown>
          </form>
          <div className={styles.footer}>
            <Button
              className={styles.cancelbtn}
              type="outline"
              onClick={onReset}
            >
              Cancel
            </Button>
            <Button type="fill" onClick={handleSubmit(onSubmit)}>
              Apply filters
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export { SingleReportsFilterModal };

/*
=================================
OVERVIEW SERVICES
=================================
*/

import { billingGraphURL, billingPlansCountURL, billingPlansUsersURL, frequentlyCreatedRolesURL, getRequest, overviewCarouselURL, reportInsightURL, scoreURL } from "api";

/**
 * Overview Carousel service
 * @returns axios promise
 */

interface OverviewCarouselData {
  time_frame?: "one_day" | "one_month" | "one_year";
  start_date?: string;
  end_date?: string;
}

export const overviewCarouselService = (data: OverviewCarouselData) => {
  const request = {
    url: overviewCarouselURL(),
    config: {
      params: {
        time_frame: data.time_frame,
        start_date: data.start_date,
        end_date: data.end_date,
      },
    },
  };
  return getRequest(request);
};

/**
 * Billing Plans Count service
 * @returns axios promise
 */

export interface BillingPlansCountData {
  start_date?: string | null;
  end_date?: string | null;
}

export const billingPlansCountService = (data: BillingPlansCountData) => {
  const request = {
    url: billingPlansCountURL(),
    config: {
      params: {
        start_date: data.start_date || '',
        end_date: data.end_date || '',
      }
    }
  };
  return getRequest(request);
};

/**
 * Billing Plans Users service
 * @returns axios promise
 */

export interface BillingPlansData {
  plan_type?: string;
  search?: string;
  start_date?: string | null;
  end_date?: string | null;
}

export const billingPlansUsersService = (data: BillingPlansData) => {
  const request = {
    url: billingPlansUsersURL(data)
  };
  return getRequest(request);
};


/**
 * Report Insights Carousel service
 * @returns axios promise
 */

interface ReportInsightsData {
  time_frame: "one_day" | "one_month" | "one_year";
}

export const reportInsightsService = (data: ReportInsightsData) => {
  const request = {
    url: reportInsightURL(),
    config: {
      params: {
        time_frame: data.time_frame,
      },
    },
  };
  return getRequest(request);
};

/**
 * Frequently Created Roles service
 * @returns axios promise
 */

interface FrequentlyCreatedRolesData {
  id?: string;
  frequency?: string
}

export const FrequentlyCreatedRolesService = (data: FrequentlyCreatedRolesData) => {
  const request = {
    url: frequentlyCreatedRolesURL(data.id),
    config: {
      params: {
        frequency: data.frequency || ''
      }
    }
  };
  return getRequest(request);
};

/**
 * Billing Graph Service
 * @returns axios promise
 */

interface BillingGraphData {
  time_frame?: string
}

export const billingGraphService = (data: BillingGraphData) => {
  const request = {
    url: billingGraphURL(),
    config: {
      params: {
        time_frame: data.time_frame || ''
      }
    }
  };
  return getRequest(request);
};

/**
 * Precision Score Service
 * @returns axios promise
 */

interface scoreData {
  time_frame?: string
}

export const scoreService = (data: scoreData) => {
  const request = {
    url: scoreURL(),
    config: {
      params: {
        time_frame: data.time_frame || ''
      }
    }
  };
  return getRequest(request);
};
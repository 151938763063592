import React from "react";
import { TableBody, TableBodyRow } from "..";
import styles from "./styles.module.scss";

export interface DraftCheckType {
  index: number;
  value: boolean;
}

export interface DraftsTableItem {
  id: string;
  assessmentTitle: string;
  dateCreated: string;
  metricsSet: string;
  button: string;
}

interface TableBodyProps {
  tableBodyItems: DraftsTableItem[];
  tableBodyItemClassName?: string;
  tableBodyRowClassName?: string;
  tableBodyStatus?: string;
  tableBody?: string;
  statusItem?: string;
  statusColor?: string;
  shownHeaders: { title: string; Icon: React.FC }[];
  copyLink: (id: any) => void;
  viewReview: (id: any) => void;
  draftsModal: (title: string, date: string) => void;
}

const DraftsTable: React.FC<TableBodyProps> = ({
  tableBody,
  tableBodyItems,
  tableBodyItemClassName,
  tableBodyRowClassName,
  shownHeaders,
  draftsModal
}) => {
  return (
    <>
      <TableBody customClassName={`${styles.tableBody} ${tableBody}`}>
        {tableBodyItems.map((item, index) => (
          <TableBodyRow
            key={`body ${index}`}
            customClassName={`${styles.tableBodyRow} ${tableBodyRowClassName}`}
          >
            {shownHeaders.map((header, headerIndex) => (
              <div
                key={headerIndex}
                className={`${tableBodyItemClassName} ${styles.box}`}
              >
                {header.title === "Assessments title" && (
                  <span
                    onClick={() => {
                      draftsModal(item.assessmentTitle, item.dateCreated);
                    }}
                    className={`${tableBodyItemClassName} ${styles.title}`}
                  >
                    {item.assessmentTitle}
                  </span>
                )}

                {header.title === "Date Created" && (
                  <span className={`${tableBodyItemClassName} ${styles.date}`}>{item.dateCreated}
                  </span>
                )}
                {header.title === "Metrics set" && (
                  <span className={`${tableBodyItemClassName} ${styles.metric}`}>
                    <span className={styles.scrollable}>{item.metricsSet}</span>
                  </span>
                )}
                {header.title === "Button" && (
                  <span className={`${tableBodyItemClassName} ${styles.continue}`}>
                    <span className={styles.btn}>{item.button}</span>
                  </span>
                )}

              </div>
            ))}
          </TableBodyRow>
        ))}
      </TableBody>
    </>
  );
};

export { DraftsTable };
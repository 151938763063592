import React, { useState } from 'react'
import styles from "./styles.module.scss"
import { ChevronIcon } from 'assets';

interface DropDownProps {
  title: string;
  body: string;
}

const DropDown: React.FC<DropDownProps> = ({title, body}) => {
  const [show, setShow] = useState(true);
  return (
    <div className={styles.dropDown}>
    <div className={styles.dropDownHead}>
        <span className={styles.headText}>{title}</span>
      <ChevronIcon
        onClick={() =>
          setShow((prev) => !prev)
        }
        className={`${styles.chevron} ${
          show && styles.up
        }`}
      />
    </div>
    {show && (
      <div className={styles.dropDownBody}>
        {body}
      </div>
    )}
  </div>
  )
}

export {DropDown}

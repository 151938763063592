/**
 * ROUTES
 *
 * ===============================================
 *
 * This object depicts the component url structure.
 * It contains a key-value pair of components and their respective URLs
 *
 */

export const Routes = {
  // Authentication
  home: "/",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",

  // Dashboard
  overview: "/overview",
  employers: "/users/employers",
  profile: (id: any) => `/profile/${id}`,
  assessments: (tab: any, id: any) => `/assessments/${tab}/${id}`,
  reportAssessed: (id: any, id2: any) => `/reports/${id}/assessed/${id2}`,
  reportAccepted: (id: any, id2: any) => `/reports/${id}/accepted/${id2}`,
  reportConsider: (id: any, id2: any) => `/reports/${id}/consider/${id2}`,
  reportInterview: (id: any, id2: any) => `/reports/${id}/interview/${id2}`,
  reportRejected: (id: any, id2: any) => `/reports/${id}/rejected/${id2}`,
  candidateReport: (id: any, tab: any, id2: any, id3: any) => `/reports/${id}/${tab}/${id2}/candidate-report/${id3}`,
  reports: (id: any) => `/reports/${id}`,
  reviews: (id: any, id2: any) => `/reviews/${id}/${id2}`,
  candidateReview: (id: any, id2: any, id3: any) => `/reviews/${id}/candidate-review/${id2}/${id3}`,
  candidates: "/users/candidates",
};

import React, { ReactNode, useState } from 'react'
import styles from "./styles.module.scss"
import { ChevronIcon } from 'assets';

interface InputDropDownProps {
  title: string;
  children: ReactNode;
  className?: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const InputDropDown: React.FC<InputDropDownProps> = ({title, children, className, Icon}) => {
  const [showStartDate, setShowStartDate] = useState(false);
  const showStartDateDropDown = () => {
    setShowStartDate(prev => !prev);
  };
  return (
    <div className={styles.inputDropDown}>
    <div className={styles.planDetails} onClick={showStartDateDropDown}>
      <div className={styles.planName}>
        <Icon />
        <p className={styles.name}> {title} </p>
      </div>
      <ChevronIcon
        className={`${styles.chevron}  ${
          showStartDate ? styles.up : ""
        }`}
      />
    </div>
    <div
      className={`${
        showStartDate ? styles.showStartDate : styles.hidePlans
      } ${styles.candidatesWrap} ${className}`}
    >
      {children}
    </div>
  </div>
  )
}

export {InputDropDown}

import * as React from "react";
import styles from "./styles.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { Button, Input } from "components";
import { LogoWithText } from "assets";
import { Link } from "react-router-dom";
import { Routes } from "router";

export interface ResetData {
  password: string;
  confirmPassword: string;
}

const initCreateData: ResetData = {
  password: "",
  confirmPassword: "",
};

interface ResetPasswordProps {
  reset: (data: ResetData) => void;
}

const resetPasswordSchema = yup
  .object({
    password: yup
      .string()
      .required("Required")
      .min(8, "Password should be at least 8 characters long")
      .matches(/[A-Z]/, "Password should contain an uppercase character")
      .matches(/[a-z]/, "Password should contain an lowercase character")
      .matches(/[0-9]/, "Password should contain at least one number"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Passwords must match")
      .min(8, "Password should be at least 8 characters long")
      .matches(/[A-Z]/, "Password should contain an uppercase character")
      .matches(/[a-z]/, "Password should contain an lowercase character")
      .matches(/[0-9]/, "Password should contain at least one number")
      .required("Required"),
  })
  .required();

const ResetPasswordUI: React.FC<ResetPasswordProps> = ({
  reset,
}: ResetPasswordProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm<ResetData>({
    resolver: yupResolver(resetPasswordSchema),
    defaultValues: initCreateData,
  });

  const onSubmit: SubmitHandler<ResetData> = (data) => {
    return reset(data);
  };

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <div className={styles.head}>
          <LogoWithText className={styles.logo}/>
        </div>
        <h1 className={styles.ttl}>Set new password</h1>
        <p className={styles.subttl}>Your new password must be different from previous used passwords</p>
        <div className={styles.body}>
          <form className={styles.form}>
            <Input
              label="Password"
              placeholder=""
              type="password"
              parentClassName={styles.passwordInput}
              className={styles.mainInput}
              required
              validatorMessage={errors.password?.message}
              name="password"
              register={register}
            />
            <Input
              label="Confirm password"
              placeholder=""
              type="password"
              parentClassName={styles.passwordInput}
              className={styles.mainInput}
              required
              validatorMessage={errors.confirmPassword?.message}
              name="confirmPassword"
              register={register}
              value={watch("confirmPassword")}
            />
            <Button
              className={styles.reset}
              onClick={handleSubmit(onSubmit)}
              type="fill"
            >
              Reset Password
            </Button>
            <Link
              className={`${styles.login}`}
              to={Routes.home}
            >
              Log in
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
};

export { ResetPasswordUI };

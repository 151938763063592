import { CandidatesIcon, FilterIconOutline } from "assets";
import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  AssessmentsFilterData,
  AssessmentsFilterModal,
  assessmentSchema,
  initAssessmentFilterData,
  ActiveFilters
} from "components";

interface AssessmentsFilterProps {
  submit: (data: AssessmentsFilterData) => void;
  className?: string;
  show: string;
}

const AssessmentsFilter: React.FC<AssessmentsFilterProps> = ({
  className = "",
  submit,
}) => {
  const form = useForm<AssessmentsFilterData>({
    resolver: yupResolver<AssessmentsFilterData>(assessmentSchema),
    defaultValues: initAssessmentFilterData,
  });

  const [showModal, setModal] = useState(false);

  const handleFilterSubmit = (data: AssessmentsFilterData) => {
    submit(data);
  };
  const resetAndSubmit = (key: keyof AssessmentsFilterData) => {
    form.setValue(key, undefined);
    handleFilterSubmit(form.watch());
  };
  const filter = [
    {
      icon: <CandidatesIcon />,
      value: !form.getValues(`startDate`) || !form.getValues(`endDate`) ? "" : `${form.getValues(`startDate`)} - ${form.getValues(`endDate`)}`,
      reset: () => {
        resetAndSubmit("startDate");
        resetAndSubmit("endDate");
      },
    },
    {
      icon: "",
      value: !form.getValues(`active`) ? "" : `Active`,
      reset: () => {
        resetAndSubmit("active");
      },
    },
    {
      icon: "",
      value: !form.getValues(`closed`) ? "" : `Closed`,
      reset: () => {
        resetAndSubmit("closed");
      },
    },
    {
      icon: "",
      value: !form.getValues(`scheduled`) ? "" : `Scheduled`,
      reset: () => {
        resetAndSubmit("scheduled");
      },
    },
    {
      icon: "",
      value: !form.getValues(`paused`) ? "" : `Paused`,
      reset: () => {
        resetAndSubmit("paused");
      },
    },
  ].filter(
    (item) =>
      item.value !== ""
  );
  useEffect(() => {
    form.watch();
  }, [form]);
  return (
    <>
      {filter.length === 0 ? (
        <div
          onClick={() => setModal(true)}
          className={`${styles.filter} ${className}`}
        >
          <FilterIconOutline role="button" className={styles.filterIcon} />
          <p className={styles.text}>Filter</p>
        </div>
      ) : (
        <ActiveFilters
          className={className}
          click={() => {
            setModal(true);
          }}
          filterData={filter}
        />
      )}

      <AssessmentsFilterModal
        show={showModal}
        close={() => setModal(false)}
        submit={handleFilterSubmit}
        form={form}
      />
    </>
  );
};

export { AssessmentsFilter };

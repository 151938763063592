import { LayoutRouteProps } from "react-router-dom";
import { Routes } from "./routes";
import { Layout } from "layout";
import { ForgotPassword, Login, Overview, ResetPassword, Employers, Candidates, Profile, Reports, ReportAssessed, CandidateReport, ReportConsider, ReportInterview, ReportRejected, Reviews, SingleReview, Assessments, ReportAccepted } from "pages";
// import { Routes } from "./routes";

// Route Builder Item Props
export interface RouteBuilderItem extends LayoutRouteProps {
  Layout?: React.FC<any>; // If you wish to add a layout to the page
  Element: React.FC;
  props?: any;
  isProtected?: boolean;
}

/**
 * ROUTE BUILDER
 *
 * ===============================================
 *
 * This is a list of all our application routes.
 *
 * A single item on this list contains the necessary Route props needed by React Router to do it's job.
 *
 * If you wish to add a new route to the application,
 * just fulfill all the necessary props needed by the RouteBuilder item. Ciao!
 *
 */

export const RouteBuilder: RouteBuilderItem[] = [
  {
    path: Routes.home,
    Element: Login,
  },
  {
    path: Routes.forgotPassword,
    Element: ForgotPassword,
  },
  {
    path: Routes.resetPassword,
    Element: ResetPassword,
  },
  {
    path: Routes.overview,
    Element: Overview,
    Layout: Layout,
    props: {
      active: "Overview",
    },
  },
  {
    path: Routes.employers,
    Element: Employers,
    Layout: Layout,
    props: {
      active: "Employers",
    },
  },
  {
    path: Routes.profile(":id"),
    Element: Profile,
    Layout: Layout,
    props: (params: any) => ({
      id: params.id,
      active: "Employers",
    }),
  },
  {
    path: Routes.assessments(":tab" ,":id"),
    Element: Assessments,
    Layout: Layout,
    props: (params: any) => ({
      id: params.id,
      tab: params.tab,
      active: "Employers",
    }),
  },
  {
    path: Routes.reportAssessed(":id", ":id2"),
    Element: ReportAssessed,
    Layout: Layout,
    props: (params: any) => ({
      id: params.id,
      id2: params.id2,
      active: "Employers",
    }),
  },
  {
    path: Routes.reportAccepted(":id", ":id2"),
    Element: ReportAccepted,
    Layout: Layout,
    props: (params: any) => ({
      id: params.id,
      id2: params.id2,
      active: "Employers",
    }),
  },
  {
    path: Routes.reportConsider(":id", ":id2"),
    Element: ReportConsider,
    Layout: Layout,
    props: (params: any) => ({
      id: params.id,
      id2: params.id2,
      active: "Employers",
    }),
  },
  {
    path: Routes.reportInterview(":id", ":id2"),
    Element: ReportInterview,
    Layout: Layout,
    props: (params: any) => ({
      id: params.id,
      id2: params.id2,
      active: "Employers",
    }),
  },
  {
    path: Routes.reportRejected(":id", ":id2"),
    Element: ReportRejected,
    Layout: Layout,
    props: (params: any) => ({
      id: params.id,
      id2: params.id2,
      active: "Employers",
    }),
  },
  {
    path: Routes.candidateReport(":id", ":tab", ":id2", ":id3"),
    Element: CandidateReport,
    Layout: Layout,
    props: (params: any) => ({
      id: params.id,
      tab: params.tab,
      id2: params.id2,
      id3: params.id3,
      active: "Employers",
    }),
  },
  {
    path: Routes.reports(":id"),
    Element: Reports,
    Layout: Layout,
    props: (params: any) => ({
      id: params.id,
      active: "Employers",
    }),
  },
  {
    path: Routes.reviews(":id", ":id2"),
    Element: Reviews,
    Layout: Layout,
    props: (params: any) => ({
      id: params.id,
      id2: params.id2,
      active: "Employers",
    }),
  },
  {
    path: Routes.candidateReview(":id", ":id2", ":id3"),
    Element: SingleReview,
    Layout: Layout,
    props: (params: any) => ({
      id: params.id,
      id2: params.id2,
      id3: params.id3,
      active: "Employers",
    }),
  },
  
  {
    path: Routes.candidates,
    Element: Candidates,
    Layout: Layout,
    props: {
      active: "Candidates",
    },
  }
];

import React, { useState } from "react";
import styles from "./styles.module.scss";
import { useParams, useNavigate } from "react-router-dom";
import {
  GroupTab,
  Pagination,
  Search,
  SingleReportsFilter,
  SingleReportsTable,
  SingleReportsTableItem,
  Table,
} from "components";
import { ArrowIcon, EmptyTable, UsersIcon } from "assets";
import { Routes } from "router";
import { EmptyComponent } from "../employers";
interface SingleReportProps {
  singleReportsTable: SingleReportsTableItem[];
  singleReportSearchTerm: string;
  handleSingleReportSearch: (data: any) => void;
  handleSingleReportFilter: (data: any) => void;
  handleSingleReportView: (id: any, tab: any, id2: any, id3: any) => void;
  singleReportPagination: {
    handleChange: (page: any) => void;
    total: number;
    current: number;
    limit: number;
  };
  copyLink: (id: any) => void;
  viewCV: (id: any) => void;
  sort: () => void;
  title: string;
}

const ReportAssessedUI: React.FC<SingleReportProps> = ({
  singleReportsTable,
  singleReportSearchTerm,
  handleSingleReportSearch,
  handleSingleReportFilter,
  handleSingleReportView,
  singleReportPagination,
  copyLink,
  viewCV,
  sort,
  title,
}) => {
  const { id, id2 } = useParams<{
    id: string;
    id2: string;
    tab: string;
  }>();
  const navigate = useNavigate();
  const [active, setActive] = useState("assessed");
  const [candidateReport, setCandidateReport] = useState(false);

  const reportsTableHeaderTitles = [
    {
      index: 1,
      title: "Candidate name",
      Icon: EmptyComponent,
    },
    {
      index: 2,
      title: "Date & Time assessed",
      Icon: EmptyComponent,
    },
    {
      index: 3,
      title: "Email",
      Icon: EmptyComponent,
    },
    {
      index: 4,
      title: "Phone number",
      Icon: EmptyComponent,
    },
    {
      index: 5,
      title: "Score",
      Icon: ArrowIcon,
    },
    {
      index: 6,
      title: "View",
      Icon: EmptyComponent,
    },
    {
      index: 7,
      title: "Copy",
      Icon: EmptyComponent,
    },
  ];
  const [headerVisibility, setHeaderVisibility] = useState(
    reportsTableHeaderTitles.map(() => true)
  );
  const shouldHideTable = headerVisibility
    .slice(0, -1)
    .every((visible) => !visible);
  const companyTitle = localStorage.getItem('titleHead') || '';
  return (
    <div className={styles.main}>
      <GroupTab
        title={companyTitle}
        description="View comprehensive data on reports for each assessment filtered with sifferent status values."        
        back={true}
        navigateBack={()=>{navigate(Routes.reports(id))}}
        active={"reports"}
        id={id}
        navigateProfile={() => {
          navigate(Routes.profile(id));
        }}
        navigateAssessments={() => {
          navigate(Routes.assessments("active", id));
        }}
        navigateReports={() => {
          navigate(Routes.reports(id));
        }}
        items={[
          {
            Icon: UsersIcon,
            label: "Users",
            link: "",
          },
          {
            Icon: EmptyComponent,
            label: "Employers",
            link: Routes.employers,
          },
          {
            Icon: EmptyComponent,
            label: companyTitle,
            link: Routes.profile(id),
          },
          {
            Icon: EmptyComponent,
            label: title,
            link: Routes.reportAssessed(id, id2)
          },
        ]}
      />
      <div className={`${styles.reports}`}>
        <div className={styles.header}>{title}</div>
        <div className={styles.table}>
          <div className={styles.tableBar}>
            <div className={styles.filter}>
              <div className={styles.filterTab}>
                <div
                  className={`${styles.filterBtn} ${
                    active === "assessed" && styles.active
                  }`}
                  onClick={() => {
                    setActive("assessed");
                    navigate(Routes.reportAssessed(id, id2));
                  }}
                >
                  Assessed
                </div>
                <div
                  className={`${styles.filterBtn} ${
                    active === "consider" && styles.active
                  }`}
                  onClick={() => {
                    setActive("consider");
                    navigate(Routes.reportConsider(id, id2));
                  }}
                >
                  Consider
                </div>
                <div
                  className={`${styles.filterBtn} ${
                    active === "interview" && styles.active
                  }`}
                  onClick={() => {
                    setActive("interview");
                    navigate(Routes.reportInterview(id, id2));
                  }}
                >
                  To Interview
                </div>
                <div
                  className={`${styles.filterBtn} ${
                    active === "accepted" && styles.active
                  }`}
                  onClick={() => {
                    setActive("accepted");
                    navigate(Routes.reportAccepted(id, id2));
                  }}
                >
                  Accepted
                </div>                
                <div
                  className={`${styles.filterBtn} ${
                    active === "rejected" && styles.active
                  }`}
                  onClick={() => {
                    setActive("rejected");
                    navigate(Routes.reportRejected(id, id2));
                  }}
                >
                  Rejected
                </div>
              </div>
            </div>
            <div className={styles.tableActions}>
              <div className={styles.filterScroll}>
                <SingleReportsFilter
                  submit={handleSingleReportFilter}
                  className={styles.filter}
                  show="reports"
                />
              </div>
              <Search
                className={styles.search}
                value={singleReportSearchTerm}
                placeholder="Search in list..."
                handleChange={(e) => {
                  handleSingleReportSearch(e);
                }}
              />
            </div>
          </div>
          <div className={styles.singleReport}>
            {shouldHideTable ? (
              <div className={styles.emptyTable}>
                <EmptyTable />
              </div>
            ) : (
              <Table
                tableHeaderTitles={reportsTableHeaderTitles}
                tableBody={
                  <SingleReportsTable
                    tableBody={styles.tableBody}
                    tableBodyItems={singleReportsTable}
                    tableBodyItemClassName={styles.tableItem}
                    tableBodyRowClassName={styles.reportTableBodyItem}
                    tableBodyStatus={styles.tableBodyStatus}
                    shownHeaders={reportsTableHeaderTitles}
                    statusItem={styles.statusItem}
                    copyLink={copyLink}
                    viewCV={viewCV}
                    view={handleSingleReportView}
                  />
                }
                customTableClasses={{
                  tableContainerClassName: styles.reportTableWrap,
                  tableHeaderClassName: styles.reportTableHeader,
                  tableHeaderItemClassName: styles.tableHeaderItem,
                  statusClass: styles.statusClass,
                  headerStyle: styles.headerStyle,
                }}
                headerVisibility={headerVisibility}
                sort={sort}
              />
            )}
            <Pagination
              current={singleReportPagination.current}
              total={singleReportPagination.total}
              handleChange={singleReportPagination.handleChange}
              limit={singleReportPagination.limit}
              parentClassName={styles.pagin}
              classP={styles.paginationBtn}
              paginText={styles.paginText}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { ReportAssessedUI };

import * as React from "react";
import { TableBody, TableBodyRow } from "..";
import styles from "./styles.module.scss";
import { DotIcon, profilePicture } from "assets";
import { CheckBox } from "components/checkBox";
import { SelectDropDown } from "components/selectDropDown";

export interface CheckType {
  index: number;
  value: boolean;
}

export interface EmployerTableItem {
  id: string;
  businessName: string;
  dateCreated: string;
  ownersID: string;
  email: string;
  name: string;
  phoneNumber: string;
  planType: string;
  candidateNo: string;
  status: string;
}

interface TableBodyProps {
  tableBodyItems: EmployerTableItem[];
  tableBodyItemClassName?: string;
  tableBodyRowClassName?: string;
  tableBodyStatus?: string;
  statusItem?: string;
  statusColor?: string;
  checks: boolean[];
  handleCheck: ({ index, value }: CheckType) => void;
  shownHeaders: { title: string; Icon: React.FC }[];
  view: (id: any) => void;
  handleDeactivate: (id?: string) => void;
  handleRequestReview: (id?: string) => void;
}

const EmployersTable: React.FC<TableBodyProps> = ({
  tableBodyItems,
  tableBodyItemClassName,
  tableBodyRowClassName,
  statusItem,
  statusColor,
  checks,
  handleCheck,
  shownHeaders,
  view,
  handleRequestReview,
  handleDeactivate,
}) => {
  const items = (id: string) => [
    {
      name: "Request review",
      action: () => {
        handleRequestReview(id);
      },
    },
    {
      name: "Deactivate employer",
      action: () => {
        handleDeactivate(id);
      },
    },
  ];
  return (
    <>
      <TableBody customClassName={`${styles.tableBody}`}>
        {tableBodyItems.map((item, index) => (
          <TableBodyRow
            key={`body ${index}`}
            customClassName={`${styles.tableBodyRow} ${tableBodyRowClassName}`}
          >
            {shownHeaders.map((header, headerIndex) => (
              <div
                key={headerIndex}
                className={`${tableBodyItemClassName} ${styles.box}`}
              >
                {header.title === "Business name" && (
                  <span className={`${styles.nameSection}`}>
                    <CheckBox
                      inputClassName={styles.check}
                      id={""}
                      checked={checks[index]}
                      onChange={() =>
                        handleCheck({ index, value: !checks[index] })
                      }
                    />
                    <img src={profilePicture} alt="" />
                    <span
                      onClick={() => {
                        view(item.id);
                      }}
                      className={styles.nameFlex}
                    >
                      <span
                        className={`${styles.businessName}`}
                      >
                        {item.businessName}
                      </span>
                      <span
                        className={`${styles.businessEmail}`}
                      >
                        {item.email}
                      </span>
                    </span>
                  </span>
                )}

                {header.title === "Date Created" && (
                  <span className={`${tableBodyItemClassName} ${styles.namee}`}>
                    <span className={styles.scrollable}>{item.dateCreated}</span>
                  </span>
                )}
                {header.title === "Contact person" && (
                  <span className={`${tableBodyItemClassName} ${styles.namee}`}>
                    <span className={styles.scrollable}>{item.name}</span>
                  </span>
                )}
                {header.title === "Phone no." && (
                  <span className={tableBodyItemClassName}>
                    {item.phoneNumber}
                  </span>
                )}
                {header.title === "Plan type" && (
                  <span
                    className={`${styles.planType}`}
                  >{item.planType}
                  </span>
                )}
                {header.title === "No. of candidates" && (
                  <span className={`${tableBodyItemClassName} ${styles.no}`}>
                    {item.candidateNo}
                  </span>
                )}
                {header.title === "Status" && (
                  <span
                    className={`${tableBodyItemClassName} ${styles.statusSection}`}
                  >
                    <p
                      className={`
                      ${statusItem} 
                      ${statusColor}
                      ${styles.status}
                      ${styles["text" + item.status]}
                      `}
                    >
                      <DotIcon
                        className={`${styles.dot} ${
                          styles["dot" + item.status]
                        }`}
                      />
                  <span className={`${tableBodyItemClassName} ${styles.statusWrapper}`}>
                    <span className={styles.scrollable}>{item.status}</span>
                  </span>
                    </p>
                  </span>
                )}
                {header.title === "Dots" && (
                  <span className={`${tableBodyItemClassName} ${styles.dots}`}>
                    <SelectDropDown
                      className={styles.SelectDropDown}
                      items={items(item.ownersID)}
                    />
                  </span>
                )}
              </div>
            ))}
          </TableBodyRow>
        ))}
      </TableBody>
    </>
  );
};

export { EmployersTable };

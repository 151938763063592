import { forgotPasswordService } from "api";
import { Preloader, Toast } from "components";
import { useApiRequest } from "hooks/useApiRequest";
import { ForgotPasswordData, ForgotPasswordUI } from "modules";
import { useMemo, useState } from "react";

const ForgotPassword = () => {
  const [toast, setToast] = useState({ show: false, head: "", message: "" });
  const [sent, setSent] = useState(false);
  const {
    run: runForgotPassword,
    data: forgotPasswordResponse,
    error,
    requestStatus
  } = useApiRequest({});

  const forgotPassword = (data: ForgotPasswordData) => {
    runForgotPassword(forgotPasswordService(data));
  };

  useMemo(() => {
    if (forgotPasswordResponse?.status === 200) {
      setToast({
        show: true,
        head: "Success",
        message: "Check your email for instructions!"
      });
      setSent(true)
    } else if (error) {
      setToast({
        show: true,
        head: "Error",
        message: error.message
      });
    }
  }, [error, forgotPasswordResponse]);

  const handleClose = () => {
    setToast({ show: false, head: "", message: "" });
  };

  return (
    <>
      <Toast
        onClose={handleClose}
        loading={toast.show}
        head={toast.head}
        message={toast.message}
      />
      <Preloader loading={requestStatus.isPending} />
      <ForgotPasswordUI login={forgotPassword} sent={sent} />
    </>
  );
};

export { ForgotPassword };

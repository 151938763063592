/*
=================================
OVERVIEW URLS
=================================
*/

/**
 * OverviewCarousel URL
 *
 * @returns url string
 *
 */

export const overviewCarouselURL = () => `/admin/overview/dashboard/`;

/**
 * BillingPlansCount URL
 *
 * @returns url string
 *
 */

export const billingPlansCountURL = () => `/admin/overview/plan_counts/`;

/**
 * BillingPlansUsers URL
 *
 * @returns url string
 *
 */

export const billingPlansUsersURL = ({plan_type, search, start_date, end_date }: any) => {
  const queryParams = {
    plan_type: plan_type || '',
    search: search || '',
    start_date: start_date? start_date : undefined,
    end_date: end_date ? end_date : undefined,
  };

  const queryString = Object.entries(queryParams)
    .filter(([_, value]) => value !== undefined && value !== '')
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

  const query = queryString ? `?${queryString}` : '';

  return `/admin/overview/plan_users/${query}`;
}

/**
 * Report Insights Carousel URL
 *
 * @returns url string
 *
 */

export const reportInsightURL = () => `/admin/overview/candidate_report_insight/`;

/**
 * Frequently Created Roles URL
 *
 * @returns url string
 *
 */

export const frequentlyCreatedRolesURL = (id?: string) => `/admin/frequently-created-roles/${id}/`

/**
 * Frequently Created Roles URL
 *
 * @returns url string
 *
 */

export const billingGraphURL = () => `/admin/billing/graph/`

/**
 * Precision Score URL
 *
 * @returns url string
 *
 */

export const scoreURL = () => `/admin/overview/overall_precision_score/`


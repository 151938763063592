import React, { useState } from "react";
import styles from "./styles.module.scss";
import { useParams, useNavigate } from "react-router-dom";
import {
  AssessmentsFilter,
  AssessmentsTable,
  AssessmentsTableItem,
  BarChart,
  DraftsFilterData,
  DraftsTable,
  DraftsTableItem,
  FilterTabs,
  GroupTab,
  MiniCard,
  Pagination,
  Search,
  Table,
} from "components";
import { UsersIcon } from "assets";
import { Routes } from "router";
import { EmptyComponent } from "../employers";
import { optionType } from "types";

interface AssessmentsProps {
  assessments: AssessmentsTableItem[];
  searchTerm: string;
  handleSearch: (data: any) => void;
  handleFilter: (data: any) => void;
  filter: any;
  handleAssessmentsView: (id: any) => void;
  assessmentsPagination: {
    handleChange: (page: any) => void;
    total: number;
    current: number;
    limit: number;
  };

  assessmentChartData: number[];
  assessmentChartLabels: string[];
  assessment: (data: any) => void;
  drafts: DraftsTableItem[];
  draftsSearchTerm: string;
  handleDraftsSearch: (data: any) => void;
  handleDraftsFilter: (data: any) => void;
  draftsFilter: DraftsFilterData;
  handleDraftsView: (data: any) => void;
  draftsPagination: {
    handleChange: (page: any) => void;
    total: number;
    current: number;
    limit: number;
  };
  assessmentModal: (id: string) => void;
  carousel: {
    activeRoles: {
      count: string;
      growth: string;
    }
    scheduledRoles: {
      count: string;
      growth: string;
    }
    draftRoles: {
      count: string;
      growth: string;
    }
    closedRoles: {
      count: string;
      growth: string;
    }
  };
  copyLink: (id: string) => void;
  title: string;
  filterOptions: any[];
  handleFilterClick: (data: optionType) => void;
  period: optionType;
  activeTab: string;
  setActiveTab: (data: string) => void;
}

const AssessmentsUI: React.FC<AssessmentsProps> = ({
  assessments,
  searchTerm,
  handleSearch,
  handleFilter,
  filter,
  handleAssessmentsView,
  assessmentsPagination,
  assessmentChartData,
  assessmentChartLabels,
  assessment,
  drafts,
  draftsSearchTerm,
  handleDraftsSearch,
  handleDraftsFilter,
  draftsFilter,
  handleDraftsView,
  draftsPagination,
  assessmentModal,
  carousel,
  copyLink,
  title,
  filterOptions,
  handleFilterClick,
  activeTab,
  setActiveTab,
  period,
}) => {
  const { id, tab } = useParams<{ id: string; tab: string }>();
  const navigate = useNavigate();
  const tableHeaderTitles = [
    {
      index: 1,
      title: "Assessments title",
      Icon: EmptyComponent,
    },
    {
      index: 2,
      title: "Start date",
      Icon: EmptyComponent,
    },
    {
      index: 3,
      title: "End date",
      Icon: EmptyComponent,
    },
    {
      index: 4,
      title: "Status",
      Icon: EmptyComponent,
    },
    {
      index: 7,
      title: "Dots",
      Icon: EmptyComponent,
    },
  ];
  const draftsTableHeaderTitles = [
    {
      index: 1,
      title: "Assessments title",
      Icon: EmptyComponent,
    },
    {
      index: 2,
      title: "Date Created",
      Icon: EmptyComponent,
    },
    {
      index: 3,
      title: "Metrics set",
      Icon: EmptyComponent,
    },
    {
      index: 4,
      title: "Button",
      Icon: EmptyComponent,
    },
  ];
  const cardData = [
    {
      id: 1,
      header: "Active Roles",
      users: carousel.activeRoles.count,
      timeFrame: period.label,
      usersRate: 10,
      ratingType: carousel.activeRoles.growth,
    },
    {
      id: 2,
      header: "Scheduled Roles",
      users: carousel.scheduledRoles.count,
      timeFrame: period.label,
      usersRate: 10,
      ratingType: carousel.scheduledRoles.growth,
    },
    {
      id: 3,
      header: "Closed Roles",
      users: carousel.closedRoles.count,
      timeFrame: period.label,
      usersRate: 10,
      ratingType: carousel.closedRoles.growth,
    },
    {
      id: 3,
      header: "Draft Roles",
      users: carousel.draftRoles.count,
      timeFrame: period.label,
      usersRate: 10,
      ratingType: carousel.draftRoles.growth,
    },
  ];

  const [activePeriod, setActivePeriod] = useState("oneYear");

  const handleDraftClick = () => {
    navigate(Routes.assessments("drafts", id));
  };

  const handleAllClick = () => {
    navigate(Routes.assessments("active", id));
    setActiveTab("");
  };

  const handleActiveClick = () => {
    navigate(Routes.assessments("active", id));
    setActiveTab("active");
  };

  const handlePausedClick = () => {
    navigate(Routes.assessments("active", id));
    setActiveTab("paused");
  };

  const handleScheduledClick = () => {
    navigate(Routes.assessments("active", id));
    setActiveTab("scheduled");
  };

  const handleClosedClick = () => {
    navigate(Routes.assessments("active", id));
    setActiveTab("closed");
  };
  return (
    <div className={styles.main}>
      <GroupTab
        title={title}
        description="Explore the list of assessments created, view trends in frequently created roles, and monitor key metrics with role-specific insights."
        active={"assessments"}
        id={id}
        navigateProfile={() => {
          navigate(Routes.profile(id));
        }}
        navigateAssessments={() => {
          navigate(Routes.assessments(tab, id));
        }}
        navigateReports={() => {
          navigate(Routes.reports(id));
        }}
        items={[
          {
            Icon: UsersIcon,
            label: "Users",
            link: "",
          },
          {
            Icon: EmptyComponent,
            label: "Employers",
            link: Routes.employers,
          },
          {
            Icon: EmptyComponent,
            label: title,
            link: Routes.profile(id),
          },
        ]}
      />
      <FilterTabs
        filters={filterOptions}
        onChange={handleFilterClick}
        value={period}
        className={styles.filterTabs}
      />
      <div className={styles.assessments}>
        <div className={styles.cards}>
          <div className={styles.cardScroll}>
            {cardData.map((item, index) => (
              <MiniCard
                openChart={() => {}}
                filter={()=>{}}
                key={index}
                header={item.header}
                users={item.users}
                ratingType={item.ratingType}
                timeFrame={item.timeFrame}
              />
            ))}
          </div>
        </div>
        <div className={styles.chart}>
          <div className={styles.chartHead}>Frequently created roles</div>
          <div className={styles.chartGraph}>
            <BarChart
              dataProp={assessmentChartData}
              labels={assessmentChartLabels}
              stepSize={2}
            />
          </div>
          <div className={styles.chartLine}>
            <span
              className={`${styles.date} ${
                activePeriod === "oneDay" ? styles.active : ""
              }`}
              onClick={() => {
                assessment("oneDay");
                setActivePeriod("oneDay");
              }}
            >
              1D
            </span>
            <span
              className={`${styles.date} ${
                activePeriod === "oneWeek" ? styles.active : ""
              }`}
              onClick={() => {
                assessment("oneWeek");
                setActivePeriod("oneWeek");
              }}
            >
              1W
            </span>
            <span
              className={`${styles.date} ${
                activePeriod === "oneMonth" ? styles.active : ""
              }`}
              onClick={() => {
                assessment("oneMonth");
                setActivePeriod("oneMonth");
              }}
            >
              1M
            </span>
            <span
              className={`${styles.date} ${
                activePeriod === "threeMonth" ? styles.active : ""
              }`}
              onClick={() => {
                assessment("threeMonth");
                setActivePeriod("threeMonth");
              }}
            >
              3M
            </span>
            <span
              className={`${styles.date} ${
                activePeriod === "oneYear" ? styles.active : ""
              }`}
              onClick={() => {
                assessment("oneYear");
                setActivePeriod("oneYear");
              }}
            >
              1Y
            </span>
            <span
              className={`${styles.date} ${
                activePeriod === "all" ? styles.active : ""
              }`}
              onClick={() => {
                assessment("all");
                setActivePeriod("all");
              }}
            >
              All
            </span>
          </div>
        </div>
        <div className={styles.filterScroll}>
        <div className={styles.filterTab}>
          <div
            className={`${styles.filterBtn} ${
              activeTab === "" && styles.active
            }`}
            onClick={() => {
              handleAllClick();
            }}
          >
            All
          </div>
          <div
            className={`${styles.filterBtn} ${
              activeTab === "active" && styles.active
            }`}
            onClick={() => {
              handleActiveClick();
            }}
          >
            Active
          </div>
          <div
            className={`${styles.filterBtn} ${
              activeTab === "scheduled" && styles.active
            }`}
            onClick={() => {
              handleScheduledClick();
            }}
          >
            Scheduled
          </div>
          <div
            className={`${styles.filterBtn} ${
              activeTab === "paused" && styles.active
            }`}
            onClick={() => {
              handlePausedClick();
            }}
          >
            Paused
          </div>
          <div
            className={`${styles.filterBtn} ${
              activeTab === "closed" && styles.active
            }`}
            onClick={() => {
              handleClosedClick();
            }}
          >
            Closed
          </div>
          <div
            className={`${styles.filterBtn} ${
              tab === "drafts" && styles.active
            }`}
            onClick={() => {
              handleDraftClick();
            }}
          >
            Drafts
          </div>
        </div>
        </div>

        {tab === "active" ? (
          <div className={styles.table}>
            <div className={styles.tableBar}>
              <h3 className={styles.head}>All Published</h3>
              <div className={styles.tableActions}>
                <div className={styles.filterScroll}>
                  <AssessmentsFilter
                    submit={handleFilter}
                    className={styles.filter}
                    show="assessments"
                  />
                </div>
                <Search
                  className={styles.search}
                  value={searchTerm}
                  placeholder="Search in list..."
                  handleChange={(e) => {
                    handleSearch(e);
                  }}
                />
              </div>
            </div>
            <Table
              tableHeaderTitles={tableHeaderTitles}
              tableBody={
                <AssessmentsTable
                  tableBody={styles.tableBody}
                  tableBodyItems={assessments}
                  tableBodyItemClassName={styles.tableItem}
                  tableBodyRowClassName={styles.tableBodyItem}
                  tableBodyStatus={styles.tableBodyStatus}
                  shownHeaders={tableHeaderTitles}
                  statusItem={styles.statusItem}
                  viewReview={handleAssessmentsView}
                  copyLink={copyLink}
                  assessmentModal={assessmentModal}
                />
              }
              customTableClasses={{
                tableContainerClassName: styles.tableWrap,
                tableHeaderClassName: styles.tableHeader,
                tableHeaderItemClassName: styles.tableHeaderItem,
                statusClass: styles.statusClass,
              }}
            />
            <Pagination
              current={assessmentsPagination.current}
              total={assessmentsPagination.total}
              handleChange={assessmentsPagination.handleChange}
              limit={assessmentsPagination.limit}
              parentClassName={styles.pagin}
              classP={styles.paginationBtn}
              paginText={styles.paginText}
            />
          </div>
        ) : (
          <div className={styles.table}>
            <div className={styles.tableBar}>
              <h3 className={styles.head}>All Drafts</h3>
              <div className={styles.tableActions}>
                <Search
                  className={styles.search}
                  value={draftsSearchTerm}
                  placeholder="Search in list..."
                  handleChange={(e) => {
                    handleDraftsSearch(e);
                  }}
                />
              </div>
            </div>
            <Table
              tableHeaderTitles={draftsTableHeaderTitles}
              tableBody={
                <DraftsTable
                  tableBody={styles.tableBody}
                  tableBodyItems={drafts}
                  tableBodyItemClassName={styles.tableItem}
                  tableBodyRowClassName={styles.draftBodyItem}
                  tableBodyStatus={styles.tableBodyStatus}
                  shownHeaders={draftsTableHeaderTitles}
                  statusItem={styles.statusItem}
                  viewReview={handleDraftsView}
                  copyLink={copyLink}
                  draftsModal={assessmentModal}
                />
              }
              customTableClasses={{
                tableContainerClassName: styles.tableWrap,
                tableHeaderClassName: styles.draftHeader,
                tableHeaderItemClassName: styles.tableHeaderItem,
                statusClass: styles.statusClass,
              }}
            />

            <Pagination
              current={draftsPagination.current}
              total={draftsPagination.total}
              handleChange={draftsPagination.handleChange}
              limit={draftsPagination.limit}
              parentClassName={styles.pagin}
              classP={styles.paginationBtn}
              paginText={styles.paginText}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { AssessmentsUI };

import { AddIcon, CloseIcon } from "assets";
import React, { ReactNode } from "react";
import styles from "./styles.module.scss";

interface ActiveFiltersProps {
  className?: string;
  click: () => void;
  filterData:  {
    icon: ReactNode;
    value: string;
    reset: () => void;
    }[];
}

const ActiveFilters: React.FC<ActiveFiltersProps> = ({
  className,
  click,
  filterData,
}) => {
  return (
    <div className={`${styles.afterFilter} ${className}`}>
      <span className={styles.fixedIconContainer}>
        <AddIcon className={styles.openFilter} onClick={click} />
      </span>
      {filterData
        .map((item, index) => (
          <div className={styles.filterRange} key={index}>
            {item.icon === "" ?? <span className={styles.rangeIcon}>{item.icon}</span>}
            <p className={styles.number}>{item.value}</p>
            <CloseIcon className={styles.close} onClick={item.reset} />
          </div>
        ))}
    </div>
  );
};

export { ActiveFilters };

import React from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  LineElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  PointElement,
  Legend,
  BarElement,
  Filler
} from "chart.js";
import { MainRouter } from 'router';
ChartJS.register(ArcElement, CategoryScale, LinearScale, PointElement, Legend, Tooltip, BarElement, LineElement, Filler);

function App() {
  return (
    <>
      <MainRouter />
    </>
  );
}

export default App;

import * as React from "react";
import { useState } from "react";
import styles from "./styles.module.scss";
import { Input } from "components/input";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { format } from "date-fns";
import { CalendarIcon } from "assets";

export interface CalendarData {
  startDate: string;
  endDate: string;
}

const today = new Date();
const lastMonthDate = new Date(today);

// Set the date to one month in the past
lastMonthDate.setMonth(today.getMonth() - 1);

// Format the date as MM-DD-YYYY
const pastmonth = String(lastMonthDate.getMonth() + 1).padStart(2, "0");
const pastday = String(lastMonthDate.getDate()).padStart(2, "0");
const pastyear = lastMonthDate.getFullYear();

export const formattedLastMonthDate = `${pastyear}-${pastmonth}-${pastday}`;

const month = String(today.getMonth() + 1).padStart(2, "0");
const day = String(today.getDate()).padStart(2, "0");
const year = today.getFullYear();

export const formattedCurrentDate = `${year}-${month}-${day}`;

export const initCalendarData: CalendarData = {
  startDate: formattedLastMonthDate,
  endDate: formattedCurrentDate,
};

export const calendarSchema = yup.object({
  startDate: yup.string().required(),
  endDate: yup.string().required(),
});

interface DateFilterProps {
  onStartDateChange: (start: string, end: string) => void;
  onEndDateChange: (start: string, end: string) => void;
  className: string;
}

const DateFilter: React.FC<DateFilterProps> = ({ onStartDateChange, onEndDateChange,  className }) => {
  const {
    register,
    setValue,
    formState: { errors },
    watch,
  } = useForm<CalendarData>({
    resolver: yupResolver<CalendarData>(calendarSchema),
    defaultValues: initCalendarData,
  });
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(formattedCurrentDate);
  // Function to format the date
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return format(date, "dd MMMM, yyyy"); // Change format as needed
  };

  // Function to handle date change
  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setStartDate(newValue);
    setValue("startDate", newValue); // Update the form state
    onStartDateChange(newValue, endDate);
  };
  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue("endDate", newValue); // Update the form state
    setEndDate(newValue);
    onEndDateChange(startDate, newValue);
  };

  const formattedDate = watch("startDate")
    ? formatDate(watch("startDate"))
    : "";
  const formattedEndDate = watch("endDate") ? formatDate(watch("endDate")) : "";
  return (
    <>
      <div className={`${styles.date} ${className}`}>
        <CalendarIcon />
        <div
          className={styles.dateInput}
          style={{ display: "flex", alignItems: "center" }}
        >
          <>
            <Input
              onClick={(e) => e.currentTarget.showPicker()}
              placeholder="Select Previous Date"
              name="startDate"
              type="date"
              validatorMessage={errors.startDate?.message}
              parentClassName={styles.input}
              className={styles.mainInput}
              register={register}
              value={formattedDate}
              onChange={handleDateChange}
            />
            <Input
              onClick={(e) => e.currentTarget.showPicker()}
              placeholder=""
              name="endDate"
              type="date"
              validatorMessage={errors.endDate?.message}
              parentClassName={styles.input}
              className={styles.mainInput}
              register={register}
              value={formattedEndDate}
              onChange={handleEndDateChange}
            />
          </>
        </div>
        <div className={styles.dates}>
          {watch("startDate") && (
            <span
              onClick={() => {
                const startDateInput = document.querySelector(
                  `input[name="startDate"]`
                ) as HTMLInputElement;
                if (startDateInput) {
                  startDateInput.showPicker();
                }
              }}
            >
              {formatDate(watch("startDate"))}
            </span>
          )}
          -
          {watch("endDate") && (
            <span
              onClick={() => {
                const endDateInput = document.querySelector(
                  `input[name="endDate"]`
                ) as HTMLInputElement;
                if (endDateInput) {
                  endDateInput.showPicker();
                }
              }}
            >
              {formatDate(watch("endDate"))}
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export { DateFilter };
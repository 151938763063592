import React from "react";
import { TableBody, TableBodyRow } from "..";
import styles from "./styles.module.scss";
import { CopyIcon, EyeIcon } from "assets";
import { DoughnutChart, Popup } from "components";
import {
  Plugin,
} from "chart.js";
import { useParams } from "react-router-dom";


export interface SingleReportCheckType {
  index: number;
  value: boolean;
}

export interface SingleReportsTableItem {
  id: string;
  singleReportName: string;
  date: string;
  email: string;
  phoneNumber: string;
  score: string;
  link: string;
  resume: string
}

interface TableBodyProps {
  tableBodyItems: SingleReportsTableItem[];
  tableBodyItemClassName?: string;
  tableBodyRowClassName?: string;
  tableBodyStatus?: string;
  tableBody?: string;
  statusItem?: string;
  statusColor?: string;
  shownHeaders: { title: string; Icon: React.FC }[];
  copyLink: (link: any) => void;
  viewCV: (id: any) => void;
  view: (id: any, tab: any, id2: any, id3: any) => void;
}

const SingleReportsTable: React.FC<TableBodyProps> = ({
  tableBody,
  tableBodyItems,
  tableBodyItemClassName,
  tableBodyRowClassName,
  tableBodyStatus,
  statusItem,
  statusColor,
  shownHeaders,
  viewCV,
  copyLink,
  view,
}) => {
  const { id, id2} = useParams<{
    id: string;
    id2: string;
  }>();
  return (
    <>
      <TableBody customClassName={`${styles.tableBody} ${tableBody}`}>
        {tableBodyItems.map((item, index) => {
            const getColor = (score: number) => {
              if (score <= 25) return "#E74444";
              if (score > 25 && score <= 70) return "#EDA12F";
              if (score > 70 && score <= 85) return "#605BFF";
              if (score > 85 && score <= 100) return "#3DCC87";
              return "#000000";
          };
          const scoreInt = parseInt(item.score)
            const color = getColor(scoreInt)
            const plugin1: Plugin<"doughnut"> = {
              id: "centerText",
              beforeDraw: (chart) => {
                const { ctx, width, height } = chart;
                const text = `${item.score}%`;
                // const text = `95%`;
            
                ctx.save();
                ctx.fillStyle = color;
                const fontSize = (height / 3).toFixed(2);
                ctx.font = `${fontSize}px Gelion`;
                ctx.textBaseline = "middle";
            
                const textX = Math.round((width - ctx.measureText(text).width) / 2);
                const textY = height / 2;
            
                ctx.fillText(text, textX, textY);
                ctx.restore();
              },
            };
          return (
            <TableBodyRow
              key={`body ${index}`}
              customClassName={`${styles.tableBodyRow} ${tableBodyRowClassName}`}
            >
              {shownHeaders.map((header, headerIndex) => (
                <div
                  key={headerIndex}
                  className={`${tableBodyItemClassName} ${styles.box}`}
                >
                  {header.title === "Candidate name" && (
                    <span
                      className={`${styles.name}`}
                      onClick={() => {
                        view(id, "assessed", id2, item.id);
                      }}
                    >
                      {item.singleReportName}
                    </span>
                  )}

                  {header.title === "Date & Time assessed" && (
                    <span
                      className={`${tableBodyItemClassName} ${styles.date}`}>{item.date}
                    </span>
                  )}
                  {header.title === "Email" && (
                    <span className={`${styles.email}`}>{item.email}</span>
                  )}
                  {header.title === "Phone number" && (
                    <span className={`${tableBodyItemClassName} ${styles.phoneNumber}`}>
                      {item.phoneNumber}
                    </span>
                  )}
                  {header.title === "Score" && (
                    <span
                      className={`${tableBodyItemClassName} ${styles.statusSection} ${styles.doughnut}`}
                    >
                      <DoughnutChart score={item.score} total={100} color={[color, "#F2F4F7"]} radius={[0]} cutout={"90%"} plugins={[plugin1]}/>
                    </span>
                  )}
                  {header.title === "View" && (
                    <Popup
                      Icon={EyeIcon}
                      text="View CV"
                      action={() => {
                        viewCV(item.resume);
                      }}
                    />
                  )}
                  {header.title === "Copy" && (
                    <span
                      className={`${tableBodyItemClassName} ${styles.icons}`}
                    >
                      <Popup
                        Icon={CopyIcon}
                        text="Copy link"
                        action={() => copyLink(item.link)}
                      />
                    </span>
                  )}
                </div>
              ))}
            </TableBodyRow>
          );
        })}
      </TableBody>
    </>
  );
};

export { SingleReportsTable };

import { employerProfileService, reportChartService, reportsCarouselService, reportService } from "api";
import {
  initReportsFilterData,
  Preloader,
  ReportsFilterData,
  ReportsTableItem,
  Toast,
} from "components";
import { ReportsUI } from "features";
import { useApiRequest } from "hooks";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Routes } from "router";
import { format, parseISO } from "date-fns";
import { optionType } from "types";

const Reports = () => {

  // API REQUEST VARIABLES

  const {
    run: runReportChartData,
    data: reportChartDataResponse,
    requestStatus: reportChartRequestStatus,
    error: reportChartError,
  } = useApiRequest({});
  const {
    run: runCarouselData,
    data: carouselDataResponse,
    requestStatus: carouselRequestStatus,
    error: carouselError,
  } = useApiRequest({});
  const {
    run: runReportsData,
    data: reportsDataResponse,
    requestStatus: reportRequestStatus,
    error: reportsError,
  } = useApiRequest({});
  const {
    run: runProfileData,
    data: profileDataResponse,
    requestStatus: profileRequestStatus,
    error: profileError,
  } = useApiRequest({});

  // HOOK VARIABLES

  const { id } = useParams();
  const [toast, setToast] = useState({ show: false, head: "", message: "" });
  const [titleHead, setTitleHead] = useState("");
  const [reportsSearchTerm, setReportsSearchTerm] = useState("");
  const [currentReportsPage, setCurrentReportsPage] = useState(1);
  const [filter, setFilter] = useState<ReportsFilterData>(
    initReportsFilterData
  );
  const [carousel, setCarousel] = useState({
    average_precision_score: {
      count: "",
      growth: ""
    },
    total_assessed_roles: {
      count: "",
      growth: ""
    },
    completed_responses: {
      count: "",
      growth: ""
    },
    abandoned_responses: {
      count: "",
      growth: ""
    },
    total_candidates: {
      count: "",
      growth: ""
    },
    total_candidates_consider: {
      count: "",
      growth: ""
    },
    total_candidates_rejected: {
      count: "",
      growth: ""
    },
    total_candidates_to_interview: {
      count: "",
      growth: ""
    },
    total_candidates_accepted: {
      count: "",
      growth: ""
    },
  });
  const [totalReports, setTotalReports] = useState("");
  const [reportTable, setReportTable] = useState<ReportsTableItem[]>([]);
  const [frequency, setFrequency] = useState("oneYear");
  const [reportChartValue, setReportChartValue] = useState<number[]>([]);
  const [reportChartLabels, setReportChartLabels] = useState<string[]>([]);
  const filterOptions: optionType[] = [
    {
      label: "Daily",
      value: "one_day",
    },
    {
      label: "Monthly",
      value: "one_month",
    },
    {
      label: "Yearly",
      value: "one_year",
    },
  ];
  const [period, setPeriod] = useState<optionType>(filterOptions[1]);
  const [timeFrame, setTimeFrame] = useState<"one_day" | "one_month" | "one_year">("one_month");
  const [candidatePeriod, setCandidatePeriod] = useState<optionType>(filterOptions[1]);
  const [candidateTimeFrame, setCandidateTimeFrame] = useState("monthly");

  // USE MEMO

  useMemo(() => {
    if (profileDataResponse?.status === 200) {
      const profileData = profileDataResponse?.data;
      setTitleHead(profileData?.name);
      localStorage.setItem(
        "titleHead",
        profileData?.name
      );
    } else if (profileError) {
      setToast({
        show: true,
        head: "Error",
        message: profileError.message,
      });
    }
  }, [profileDataResponse, profileError]);

  useMemo(() => {
    if (reportsDataResponse?.status === 200) {
      const reportsData = reportsDataResponse.data.results;
      setTotalReports(reportsDataResponse?.data.count);
      const reportsList = reportsData.map((item: any) => ({
        id: item.id,
        reportTitle: item.title,
        startDate: format(parseISO(item.created_at), "MM/dd/yyyy - h:mm a"),
        status:
          item.status?.charAt(0)?.toUpperCase() +
          item.status?.slice(1)?.toLowerCase(),
        total: item.total_candidated_allowed,
        completed: item.completed_count,
        abandoned: item.abandoned_count,
        deadline:
          item.Deadline === "Not live" || item.Deadline === "Ended"
            ? item.Deadline
            : item.Deadline + " days",
      }));
      setReportTable(reportsList);
    } else if (reportsError) {
      setToast({
        show: true,
        head: "Error",
        message: reportsError.message,
      });
    }
  }, [reportsDataResponse, reportsError]);
  useMemo(() => {
    if (reportChartDataResponse?.status === 200) {
      const reportChartData =
        reportChartDataResponse.data.data.frequently_created_roles;
      setReportChartValue(reportChartData.map((role: any) => role.count));
      setReportChartLabels(
        reportChartData.map((role: any) => role.assessment_title)
      );
    } else if (reportChartError) {
      setToast({
        show: true,
        head: "Error",
        message: reportChartError.message,
      });
    }
  }, [reportChartDataResponse, reportChartError]);
  useMemo(() => {
    if (carouselDataResponse?.status === 200) {
      const carouselData = carouselDataResponse.data.data;
      const data: any = {
        average_precision_score: {
          count: Math.floor(
            carouselData.average_precision_score.score),
          growth: carouselData.average_precision_score.growth
        },
        total_assessed_roles: {
          count: carouselData.total_assessed_roles.count,
          growth: carouselData.total_assessed_roles.growth,
        },
        completed_responses: {
          count: carouselData.completed_responses.count,
          growth: carouselData.completed_responses.growth,
        },
        abandoned_responses: {
          count: carouselData.abandoned_responses.count,
          growth: carouselData.abandoned_responses.growth,
        },
        total_candidates: {
          count: carouselData.total_candidates.count,
          growth: carouselData.total_candidates.growth,
        },
        total_candidates_consider: {
          count: carouselData.total_candidates_consider.count,
          growth: carouselData.total_candidates_consider.growth,
        },
        total_candidates_rejected: {
          count: carouselData.total_candidates_rejected.count,
          growth: carouselData.total_candidates_rejected.growth,
        },
        total_candidates_to_interview: {
          count:
            carouselData.total_candidates_to_interview.count,
          growth: carouselData.total_candidates_to_interview.growth,
        },
        total_candidates_accepted: {
          count: carouselData.total_candidates_accepted.count,
          growth: carouselData.total_candidates_accepted.growth
        }
      };
      setCarousel(data);
    } else if (carouselError) {
      setToast({
        show: true,
        head: "Error",
        message: carouselError.message,
      });
    }
  }, [carouselDataResponse]);


  // USE EFFECT


  useEffect(() => {
    runProfileData(employerProfileService(id));
  }, [id, runProfileData]);

  useEffect(() => {
    runReportsData(
      reportService({
        id: id,
        search: reportsSearchTerm,
        page: currentReportsPage,
        start_date: filter.startDate,
        report_status:
          (filter.active && "active") ||
          (filter.closed && "closed") ||
          (filter.scheduled && "scheduled") ||
          "",
      })
    );
  }, [filter, reportsSearchTerm, currentReportsPage, runReportsData, id]);
  useEffect(() => {
    runReportChartData(
      reportChartService({
        id: id,
        frequency: frequency,
      })
    );
  }, [runReportChartData, frequency]);
  useEffect(() => {
    runCarouselData(reportsCarouselService(
      {
        id: id,
        time_frame: timeFrame
      }
    ));
  }, [id, runCarouselData, timeFrame, candidateTimeFrame]);


  // FUNCTIONS

  const handleReportChartPeriodChange = (frequency: any) => {
    setFrequency(frequency);
  };
  const handleReportsPageChange = (page: number) => {
    setCurrentReportsPage(page);
  };
  const handleReportsSearch = (searchTerm: string) => {
    setReportsSearchTerm(searchTerm);
    setCurrentReportsPage(1);
  };
  const navigateReports = useNavigate();
  const handleReportsView = (id: any, id2: any) => {
    navigateReports(Routes.reportAssessed(id, id2));
  };
  const handleClose = () => {
    setToast({ show: false, head: "", message: "" });
  };
  const handleFilterClick = (selectedOption: optionType) => {
    setPeriod(selectedOption);
    setTimeFrame(selectedOption.value);
  };
  const handleCandidateFilterClick = (selectedOption: optionType) => {
    setCandidatePeriod(selectedOption);
    setCandidateTimeFrame(selectedOption.value);
  };
  const handleReportsFilter = (data: any) => {
    setFilter({
      startDate: data.startDate,
      active: data.active,
      closed: data.closed,
      scheduled: data.scheduled,
      totalMin: data.totalMin,
      totalMax: data.totalMax,
      completedMin: data.completedMin,
      completedMax: data.completedMax,
      abandonedMin: data.abandonedMin,
      abandonedMax: data.abandonedMax,
    });
    setCurrentReportsPage(1);
  };
  return (
    <div>
      <Toast
        onClose={handleClose}
        loading={toast.show}
        head={toast.head}
        message={toast.message}
      />
      <Preloader
        loading={
          carouselRequestStatus.isPending || reportRequestStatus.isPending ||
          reportChartRequestStatus.isPending
        }
      />
      <ReportsUI
        reportsTable={reportTable}
        handleReportSearch={handleReportsSearch}
        reportSearchTerm={reportsSearchTerm}
        reportPagination={{
          handleChange: handleReportsPageChange,
          total: Math.ceil(parseInt(totalReports) / 9),
          current: currentReportsPage,
          limit: 9
        }}
        handleReportFilter={handleReportsFilter}
        filter={{
          startDate: filter.startDate,
          active: filter.active,
          closed: filter.closed,
          scheduled: filter.scheduled,
          totalMin: filter.totalMin,
          totalMax: filter.totalMax,
          completedMin: filter.completedMin,
          completedMax: filter.completedMax,
          abandonedMin: filter.abandonedMin,
          abandonedMax: filter.abandonedMax,
        }}
        handleReportView={handleReportsView}
        reportChartData={reportChartValue}
        reportChartLabels={reportChartLabels}
        report={handleReportChartPeriodChange}
        carousel={carousel}
        title={titleHead}
        filterOptions={filterOptions}
        handleFilterClick={handleFilterClick}
        period={period}
        handleCandidateFilterClick={handleCandidateFilterClick}
        candidatePeriod={candidatePeriod}
      />
    </div>
  );
};

export { Reports };

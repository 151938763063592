import { Modal } from "components/modal";
import styles from "./styles.module.scss";
import { useState } from "react";
import { ChevronIcon, CloseIcon } from "assets";
interface LearnMoreProps {
  show: boolean;
  close: () => void;
  title: string;
  start: string;
  end: string;
  duration: string;
  questions: string;
  assessmentScope: string[];
  descriptionText: string;
}

const AssessmentEmployersModal: React.FC<LearnMoreProps> = ({
  close,
  show,
  title,
  start,
  end,
  duration,
  questions,
  assessmentScope,
  descriptionText,
}) => {
  const [description, setDescription] = useState(true);
  const showDescription = () => {
    setDescription(!description);
  };
  const [instructions, setInstructions] = useState(true);
  const showInstructions = () => {
    setInstructions(!instructions);
  };
  const convertTime = (duration: string) => {
    const hours = Math.floor(parseInt(duration) / 60);
    const minutes = parseInt(duration) % 60;
    return hours > 0
      ? `${hours} hours and ${minutes} minutes`
      : `${minutes} minutes`;
  };
  return (
    <>
      <Modal
        position="right"
        close={close}
        show={show}
        contentClassName={styles.modal}
      >
        <div className={styles.close}>
          <CloseIcon
            className={styles.closeIcon}
            role="button"
            onClick={close}
          />
        </div>
        <div className={styles.container}>
          <div className={styles.head}>
            <h2 className={styles.name}>{title}</h2>
          </div>
          <div className={styles.date}>
            <div className={styles.start}>
              <div className={styles.title}>Start Date:</div>
              <div className={styles.time}>{start}</div>
              <div className={styles.total}>
                Total time:{" "}
                <span className={styles.minutes}>{convertTime(duration)}</span>
              </div>
            </div>
            <div className={`${styles.end} ${styles.start}`}>
              <div className={styles.title}>End Date:</div>
              <div className={styles.time}>{end}</div>
              <div className={styles.total}>
                Total questions{" "}
                <span className={styles.minutes}>{questions}</span>
              </div>
            </div>
          </div>
          <div className={styles.questions}>
            Questions in this assessment covers the following areas
          </div>
          <div className={styles.scroll}>
            <div className={styles.btns}>
              {assessmentScope.map((item) => {
                const formattedName = item.replace(/_/g, " ");

                return (
                  <span key={item} className={styles.btn}>
                    {formattedName}
                  </span>
                );
              })}
            </div>
          </div>

          <div className={styles.description}>
            <div className={styles.title}>
              <span className={styles.desc}>Description</span>
              <ChevronIcon
                className={`${styles.chevron}  ${description ? styles.up : ""}`}
                onClick={showDescription}
              />
            </div>
            {description && (
              <div className={styles.body}>{descriptionText}</div>
            )}
          </div>
          <div className={styles.description}>
            <div className={styles.title}>
              <span className={styles.desc}>Instructions</span>
              <ChevronIcon
                className={`${styles.chevron}  ${
                  instructions ? styles.up : ""
                }`}
                onClick={showInstructions}
              />
            </div>
            {instructions && (
              <ul className={styles.body}>
                <li>
                  <span>
                    1. Format: The test consists of multiple-choice and
                    open-ended questions.{" "}
                  </span>
                  <span>
                    2. Honesty: Please refrain from using any external resources
                    or assistance during the assessment.{" "}
                  </span>
                  <span>
                    3. Accuracy: Provide the response that best reflects your
                    skills, knowledge, and experience. There are no right or
                    wrong answers.{" "}
                  </span>
                  <span>
                    4. Completeness: Answer all questions to the best of your
                    abilities. Partially completed tests may affect your overall
                    assessment.{" "}
                  </span>
                  <span>
                    5. Submitting: Once you have completed the assessment,
                    review your answers carefully before submitting.
                    Double-check for any unanswered questions or mistakes.
                  </span>
                </li>
                <li className={styles.last}>
                  Remember, there are no right or wrong answers. Your
                  performance will be evaluated based on your individual
                  strengths and suitability for the role.
                </li>
              </ul>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export { AssessmentEmployersModal };

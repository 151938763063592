import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "./protectedRoute";
import { ErrorBoundary } from "helpers/errorBoundary";
import { RouteBuilder } from "./routeBuilder";

/**
 * MAIN ROUTER COMPONENT
 *
 * ===============================================
 *
 * This component houses all routes and their respective layouts.
 * To add a route navigate to the route builder and add to the existing list.
 *
 *
 */

const MainRouter: React.FC = () => {
  return (
    <>
      <Routes>
        {RouteBuilder.length > 0 &&
          RouteBuilder.map((item, idx) => {
            const { Element, path, caseSensitive, Layout, props, isProtected } =
              item;
            // Checks if a layout exists or not
            const PageComponent = Layout ? (
              <Layout {...props}>
                <Element />
              </Layout>
            ) : (
              <Element />
            );

            return (
              <Route
                key={idx}
                path={path}
                element={
                  isProtected ? (
                    <ProtectedRoute>
                      <ErrorBoundary key={path}>{PageComponent}</ErrorBoundary>
                    </ProtectedRoute>
                  ) : (
                    PageComponent
                  )
                }
                caseSensitive={caseSensitive}
              />
            );
          })}
      </Routes>
    </>
  );
};

export { MainRouter };

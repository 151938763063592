import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import { ChevronIcon } from "assets";

export interface Items {
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  label: string;
  link: string;
}

interface ItemsProps {
  items: Items[];
  className?: string;
}

const BreadCrumbs: React.FC<ItemsProps> = ({items}, className) => {
  return (
    <section className={`${styles.nav} ${className}`}>
      {items.map((item, index) => (
        <div key={index} className={styles.items}>
          {index === items.length - 1 && item.link === "" ? (
            <p>{item.label}</p>
          ) : (
              <div className={styles.navItem}>
                {item.Icon && <item.Icon className={`${styles.navIcon}`}/>}
                <Link to={item.link}>{item.label}</Link>
              </div>
            
          )}
          {index === items.length - 1 ? "" : <ChevronIcon className={styles.chevron} />}
        </div>
      ))}
    </section>
  );
};

export { BreadCrumbs };

import { admin, menu } from "layout/sidebarItem";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";

export interface dropDownType {
  url: string;
  active: menu | admin;
  state: menu | admin;
  action: () => void;
}

interface SideBarDropDownProps {
  dropDownItem: dropDownType;
  parentHeight: (x: boolean) => void;
}

const SideBarDropDown: React.FC<SideBarDropDownProps> = ({ dropDownItem }) => {
  const { active, url, state, action } = dropDownItem;
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const isActive = () => {
    if (active === state) return true;
    else if (
      state === "Employers" &&
      (path.includes("/profile/") ||
        path.includes("/reports/") ||
        path.includes("/assessments/") ||
        path.includes("/reviews/"))
    )
      return true;
    else return false;
  };
  return (
    <div
      onClick={() => {
        navigate(url);
        action();
      }}
      className={`${styles.userDropdown} ${
        isActive() ? styles.activeItem : ""
      }`}
    >
      <div className={`${isActive() ? styles.userBtnActive : ""}`}>
        <span role="button" className={styles.ttl}>
          {state}
        </span>
      </div>
    </div>
  );
};

export { SideBarDropDown };

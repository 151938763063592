import { CandidatesIcon, FilterIconOutline } from "assets";
import { useState } from "react";
import styles from "./styles.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  SingleReportsFilterData,
  singleReportSchema,
  initSingleReportsFilterData,
  SingleReportsFilterModal,
  ActiveFilters,
} from "components";

interface SingleReportsFilterProps {
  submit: (data: SingleReportsFilterData) => void;
  className?: string;
  show: string;
}

const SingleReportsFilter: React.FC<SingleReportsFilterProps> = ({
  className = "",
  submit,
}) => {
  const form = useForm<SingleReportsFilterData>({
    resolver: yupResolver<SingleReportsFilterData>(singleReportSchema),
    defaultValues: initSingleReportsFilterData,
  });
  const [showModal, setModal] = useState(false);
  const handleFilterSubmit = (data: SingleReportsFilterData) => {
    submit(data);
  };
  const resetAndSubmit = (key: keyof SingleReportsFilterData) => {
    form.setValue(key, undefined);
    handleFilterSubmit(form.watch());
  };
  const filter = [
    {
      icon: <CandidatesIcon />,
      value: !form.getValues(`startDate`) ? "" : `${form.getValues(`startDate`)}`,
      reset: () => {
        resetAndSubmit("startDate");
      },
    },
    {
      icon: "scores: ",
      value: !form.getValues(`scores.value`) ? "" : `${form.getValues(`scores.value`)}`,
      reset: () => {
        form.setValue("scores.value", null);
        handleFilterSubmit(form.watch());
      },
    },
  ].filter((item) => item.value !==  "");

  return (
    <>
      {filter.length === 0 ? (
        <div
          onClick={() => setModal(true)}
          className={`${styles.filter} ${className}`}
        >
          <FilterIconOutline role="button" className={styles.filterIcon} />
          <p className={styles.text}>Filter</p>
        </div>
      ) : (
        <ActiveFilters
          className={className}
          click={() => {
            setModal(true);
          }}
          filterData={filter}
        />
      )}
      <SingleReportsFilterModal
        show={showModal}
        close={() => setModal(false)}
        submit={handleFilterSubmit}
        form={form}
      />
    </>
  );
};
export { SingleReportsFilter };

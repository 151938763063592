import { Modal } from "components/modal";
import styles from "./styles.module.scss";
import { Button } from "components/button";

interface LearnMoreProps {
  show: boolean;
  close: () => void;
  confirm: (id: any) => void;
  purpose: string;
  header: string;
}

const ConfirmationModal: React.FC<LearnMoreProps> = ({
  close,
  show,
  confirm,
  purpose,
  header,
}) => {
  return (
    <>
      <Modal
        position="centered"
        close={close}
        show={show}
        contentClassName={styles.modal}
      >
        <div className={styles.head}>
          <h2 className={styles.name}>{header}</h2>
        </div>
        <div className={styles.body}>
          <p className={styles.learnText}>
            {purpose}
          </p>
        </div>
        <div className={styles.buttons}>
          <div className={styles.btns}>
            <Button type="outline" onClick={close} className={styles.cancelBtn}>
              Cancel
            </Button>
            <Button type="fill" onClick={confirm} className={styles.deleteBtn}>
              <span onClick={close}>Confirm</span>
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export { ConfirmationModal };

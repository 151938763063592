import React from "react";
import { Button } from "components/button";
import styles from "./styles.module.scss";
import { ArrowLeftIcon } from "assets";

export interface PaginationProps {
  parentClassName?: string;
  classP?: string;
  paginText?: string;
  current: number;
  total: number;
  handleChange: (page: number) => void;
  limit: number;
}

const Pagination: React.FC<PaginationProps> = ({
  current,
  parentClassName,
  classP,
  paginText,
  handleChange,
  total,
}) => {
  const generatePageNumbers = () => {
    const pages = [];
    if (total <= 5) {
      for (let i = 1; i <= total; i++) {
        pages.push(i);
      }
    } else {
      if (current <= 3) {
        pages.push(1, 2, 3, "...", total);
      } else if (current >= total - 2) {
        pages.push(1, "...", total - 2, total - 1, total);
      } else {
        pages.push(1, "...", current - 1, current, current + 1, "...", total);
      }
    }
    return pages;
  };

  const pageNumbers = generatePageNumbers();

  return (
    <section className={`${styles.pagination} ${parentClassName}`}>
      <div className={`${styles.flex} ${parentClassName}`}>
        <Button
          disabled={current === 1}
          type={"outline"}
          onClick={() => handleChange(current - 1)}
          className={`${classP}`}
        >
          <ArrowLeftIcon />
          Prev
        </Button>
        <div className={`${styles.numberNav} ${paginText}`}>
          {pageNumbers.map((page, index) =>
            page === "..." ? (
              <span key={index}>...</span>
            ) : (
              <span
                key={index}
                onClick={() => handleChange(Number(page))}
                className={page === current ? styles.activePage : ""}
              >
                {page}
              </span>
            )
          )}
        </div>
        <Button
          disabled={current >= total}
          type={"outline"}
          onClick={() => handleChange(current + 1)}
          className={`${classP}`}
        >
          Next
          <ArrowLeftIcon className={styles.arrowRight} />
        </Button>
      </div>
    </section>
  );
};

export { Pagination };

import { optionType } from "types";
import styles from "./styles.module.scss";

interface FilterTabsProps {
  filters: optionType[];
  onChange: (val: optionType) => void;
  value: optionType;
  className?: string;
}

const FilterTabs: React.FC<FilterTabsProps> = ({ filters, onChange, value, className }) => {
  return (
    <section className={`${styles.filterList} ${className}`}>
      {filters.map((item, index) => (
        <button
          key={`${item.value}_${index}`}
          onClick={() => onChange(item)}
          className={item.value === value.value ? styles.active : ""}
        >
          {item.label}
        </button>
      ))}
    </section>
  );
};

export { FilterTabs };

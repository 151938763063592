import { CandidatesIcon, FilterIconOutline } from "assets";
import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  ReportsFilterData,
  reportSchema,
  initReportsFilterData,
  ReportsFilterModal,
  ActiveFilters
} from "components";

interface ReportsFilterProps {
  submit: (data: ReportsFilterData) => void;
  className?: string;
  show: string;
}

const ReportsFilter: React.FC<ReportsFilterProps> = ({
  className = "",
  submit,
}) => {
  const form = useForm<ReportsFilterData>({
    resolver: yupResolver<ReportsFilterData>(reportSchema),
    defaultValues: initReportsFilterData,
  });

  const [showModal, setModal] = useState(false);
  const handleFilterSubmit = (data: ReportsFilterData) => {
    submit(data);
  };
  const resetAndSubmit = (key: keyof ReportsFilterData) => {
    form.setValue(key, undefined);
    handleFilterSubmit(form.watch());
  };
  const filter = [
    {
      icon: <CandidatesIcon />,
      value: !form.getValues(`startDate`) ? "" : `${form.getValues(`startDate`)}`,
      reset: () => {
        resetAndSubmit("startDate");
      },
    },
    {
      icon: <CandidatesIcon />,
      value: !form.getValues(`totalMin`) || !form.getValues(`totalMax`) ? "" : `${form.getValues(`totalMin`)} - ${form.getValues(`totalMax`)}`,
      reset: () => {
        resetAndSubmit("totalMin");
        resetAndSubmit("totalMax");
      },
    },
    {
      icon: <CandidatesIcon />,
      value: !form.getValues(`completedMin`) || !form.getValues(`completedMax`) ? "" : `${form.getValues(`completedMin`)} - ${form.getValues(`completedMax`)}`,
      reset: () => {
        resetAndSubmit("completedMin");
        resetAndSubmit("completedMax");
      },
    },
    {
      icon: <CandidatesIcon />,
      value: !form.getValues(`abandonedMin`) || !form.getValues(`abandonedMax`) ? "" : `${form.getValues(`abandonedMin`)} - ${form.getValues(`abandonedMax`)}`,
      reset: () => {
        resetAndSubmit("abandonedMin");
        resetAndSubmit("abandonedMax");
      },
    },
    {
      icon: "",
      value: !form.getValues(`active`) ? "" : `Active`,
      reset: () => {
        resetAndSubmit("active");
      },
    },
    {
      icon: "",
      value: !form.getValues(`closed`) ? "" : `Closed`,
      reset: () => {
        resetAndSubmit("closed");
      },
    },
    {
      icon: "",
      value: !form.getValues(`scheduled`) ? "" : `Scheduled`,
      reset: () => {
        resetAndSubmit("scheduled");
      },
    },
  ].filter(
    (item) =>
      item.value !== ""
  );
  useEffect(() => {
    form.watch();
  }, [form]);
  return (
    <>
      {filter.length === 0 ? (
        <div
          onClick={() => setModal(true)}
          className={`${styles.filter} ${className}`}
        >
          <FilterIconOutline role="button" className={styles.filterIcon} />
          <p className={styles.text}>Filter</p>
        </div>
      ) : (
        <ActiveFilters
          className={className}
          click={() => {
            setModal(true);
          }}
          filterData={filter}
        />
      )}
      <ReportsFilterModal
        show={showModal}
        close={() => setModal(false)}
        submit={handleFilterSubmit}
        form={form}
      />
    </>
  );
};

export { ReportsFilter };

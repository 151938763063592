import React from "react";
import styles from "../styles.module.scss";

interface TableHeaderItemProps {
  title: string;
  Icon: React.FunctionComponent;
}
interface TableHeaderProps {
  tableHeaderTitles: TableHeaderItemProps[];
  tableHeadItemClassName?: string;
  tableHeaderClassName?: string;
  tableRowClassName?: string;
  statusClass?: string;
  headerStyle?: string;
  sort?: () => void;
}

const TableHeader: React.FC<TableHeaderProps> = ({
  tableHeaderTitles,
  tableHeadItemClassName = "",
  tableHeaderClassName = "",
  headerStyle,
  sort,
}) => {
  return (
    <section className={`${styles.tableHeader} ${tableHeaderClassName}`}>
      {tableHeaderTitles.map((header, idx) => {
        const Icon = header.Icon;
        return (
          <div className={`${styles.tableHeaderStyle} ${headerStyle}`} key={idx}>
            <span
              className={` ${tableHeadItemClassName}`}
              key={`${header}${idx + 1}`}
            >
              {header.title}
            </span>
            <span className={styles.pointer} onClick={sort}><Icon/></span>
          </div>
        );
      })}
    </section>
  );
};

export { TableHeader };
export type { TableHeaderItemProps, TableHeaderProps };
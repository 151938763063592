import {
  BarChart,
  DoughnutChart,
  FilterTabs,
  Input,
  LineChart,
  MiniCard,
} from "components";
import styles from "./styles.module.scss";
import {
  ArrowIcon,
  ArrowLeftIcon,
  CalendarIcon,
  EvenArrowIcon,
  RateIcon,
  TrendingDownIcon,
} from "assets";
import { useRef, useState } from "react";
import { ChartData, Plugin } from "chart.js";
import { optionType } from "types";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { format } from "date-fns";
import { DateFilter } from "components/dateFilter";

export interface CalendarData {
  startDate: string;
  endDate: string;
}

const today = new Date();
const lastMonthDate = new Date(today);

// Set the date to one month in the past
lastMonthDate.setMonth(today.getMonth() - 1);

// Format the date as MM-DD-YYYY
const pastmonth = String(lastMonthDate.getMonth() + 1).padStart(2, "0");
const pastday = String(lastMonthDate.getDate()).padStart(2, "0");
const pastyear = lastMonthDate.getFullYear();

export const formattedLastMonthDate = `${pastyear}-${pastmonth}-${pastday}`;

const month = String(today.getMonth() + 1).padStart(2, "0");
const day = String(today.getDate()).padStart(2, "0");
const year = today.getFullYear();

export const formattedDate = `${year}-${month}-${day}`;

export const initCalendarData: CalendarData = {
  startDate: formattedLastMonthDate,
  endDate: formattedDate,
};

export const calendarSchema = yup.object({
  startDate: yup.string().required(),
  endDate: yup.string().required(),
});

interface OverviewProps {
  handleModalView: (prop: string, header: string, number: string) => void;
  assessment: (data: any) => void;
  barChartData: number[];
  barChartLabels: string[];
  data: ChartData<"line">;
  cardData: any[];
  reportInsightData: any[];
  candidatesCardData: any[];
  filterOptions: optionType[];
  period: optionType;
  handleFilterClick: (val: optionType) => void;
  totalEarningsData: number[];
  totalUsersData: number[];
  noOfCandidatesData: number[];
  noOfEmployersData: number[];
  candidatesTopUpData: number[];
  extendAccessData: number[];
  frequentlyCreatedRoles: (frequency: string) => void;
  score: string;
  onStartDateChange: (date: string) => void;
  onEndDateChange: (date: string) => void;
  accountsFilterOptions: optionType[];
  accountsPeriod: optionType;
  handleAccountsFilterClick: (val: optionType) => void;
  reportInsightsFilterOptions: optionType[];
  reportInsightsPeriod: optionType;
  handleReportInsightsFilterClick: (val: optionType) => void;
}

const OverviewUI: React.FC<OverviewProps> = ({
  handleModalView,
  assessment,
  barChartData,
  barChartLabels,
  cardData,
  reportInsightData,
  candidatesCardData,
  filterOptions,
  period,
  handleFilterClick,
  data,
  totalEarningsData,
  totalUsersData,
  noOfCandidatesData,
  noOfEmployersData,
  candidatesTopUpData,
  extendAccessData,
  frequentlyCreatedRoles,
  score,
  onStartDateChange,
  onEndDateChange,
  accountsFilterOptions,
  handleAccountsFilterClick,
  accountsPeriod,
  reportInsightsFilterOptions,
  handleReportInsightsFilterClick,
  reportInsightsPeriod,
}) => {
  const {
    register,
    setValue,
    formState: { errors },
    watch,
  } = useForm<CalendarData>({
    resolver: yupResolver<CalendarData>(calendarSchema),
    defaultValues: initCalendarData,
  });
  const [activePeriod, setActivePeriod] = useState("one_year");
  const [activeScorePeriod, setActiveScorePeriod] = useState("one_year");
  const [clicked, setClicked] = useState("usd");
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const cardScrollRef = useRef<HTMLDivElement | null>(null);
  const scrollLeft = () => {
    scrollRef.current?.scrollBy({
      top: 0,
      left: -scrollRef.current.clientWidth,
      behavior: "smooth",
    });
  };
  const scrollRight = () => {
    scrollRef.current?.scrollBy({
      top: 0,
      left: scrollRef.current.clientWidth,
      behavior: "smooth",
    });
  };
  const cardScrollLeft = () => {
    cardScrollRef.current?.scrollBy({
      top: 0,
      left: -cardScrollRef.current.clientWidth,
      behavior: "smooth",
    });
  };
  const cardScrollRight = () => {
    cardScrollRef.current?.scrollBy({
      top: 0,
      left: cardScrollRef.current.clientWidth,
      behavior: "smooth",
    });
  };
  const [clickedCardId, setClickedCardId] = useState<number | null>(null);
  const showView = (id: number) => {
    setClickedCardId((prevId) => (prevId === id ? null : id));
  };

  const centerTextPlugin: Plugin<"doughnut"> = {
    id: "centerText",
    beforeDraw: (chart) => {
      const { ctx, width, height } = chart;
      const number = chart.data.datasets[0].data[0] as number;
      const text = `${number.toString()}%`;
      const text2 = `Accuracy`;

      ctx.save();

      ctx.fillStyle = "#101323";
      const fontSizeNumber = (height / 6).toFixed(2);
      ctx.font = `800 ${fontSizeNumber}px Darker Grotesque`;
      ctx.textBaseline = "middle";

      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / 2 - 10;

      ctx.fillText(text, textX, textY);

      ctx.fillStyle = "#667085";
      const fontSizeText2 = (height / 18).toFixed(2);
      ctx.font = `300 ${fontSizeText2}px Gelion`;
      const text2X = Math.round((width - ctx.measureText(text2).width) / 2);
      const text2Y = textY + parseInt(fontSizeText2) + 18;

      ctx.fillText(text2, text2X, text2Y);

      ctx.restore();
    },
  };
  const name = localStorage.getItem("userName");
  // Function to format the date
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return format(date, "dd MMMM, yyyy"); // Change format as needed
  };

  // Function to handle date change
  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue("startDate", newValue); // Update the form state
    onStartDateChange(newValue);
  };
  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue("endDate", newValue); // Update the form state
    onEndDateChange(newValue);
  };

  const formattedDate = watch("startDate")
    ? formatDate(watch("startDate"))
    : "";
  const formattedEndDate = watch("endDate") ? formatDate(watch("endDate")) : "";
  return (
    <>
      <div className={styles.overview}>
        <div className={styles.header}>
          <div className={styles.left}>
            <h3 className={styles.head}>
              Hey there, {name !== "null" ? name : "Guest"}!
            </h3>
            <p className={styles.subHead}>
              Welcome back, we are happy to have you here!
            </p>
          </div>
          <div className={styles.right}>
            <div className={styles.leftArrow}>
              <ArrowLeftIcon onClick={cardScrollLeft} />
            </div>
            <div className={styles.rightArrow} onClick={cardScrollRight}>
              <ArrowLeftIcon />
            </div>
          </div>
        </div>
        <FilterTabs
          filters={accountsFilterOptions}
          onChange={handleAccountsFilterClick}
          value={accountsPeriod}
          className={styles.filterTabs}
        />
        <div className={styles.cards}>
          <div className={styles.cardScroll} ref={cardScrollRef}>
            {cardData.map((item, index) => (
              <MiniCard
                openChart={() => {}}
                filter={()=>{}}
                key={index}
                header={item.header}
                users={item.users}
                ratingType={item.ratingType}
                className={styles.card}
                percentage={item.percentage}
              />
            ))}
          </div>
        </div>
        <div className={styles.header}>
          <div className={styles.left}>
            <h3 className={styles.smallHead}>Plannings and Billings</h3>
            <p className={styles.subHead}>
              Highlights financial progression or regression per time and per
              plan.
            </p>
          </div>
          <div className={styles.rightCurrency}>
            <div
              className={`${styles.naira} ${
                clicked === "naira" && styles.clicked
              }`}
              onClick={() => setClicked("naira")}
            >
              Naira
            </div>
            <div
              className={`${styles.naira} ${
                clicked === "usd" && styles.clicked
              }`}
              onClick={() => setClicked("usd")}
            >
              USD
            </div>
          </div>
        </div>
        <div className={styles.tabs}>
          <FilterTabs
            filters={filterOptions}
            onChange={handleFilterClick}
            value={period}
            className={styles.filterTabs}
          />
          <DateFilter onStartDateChange={onStartDateChange} onEndDateChange={onEndDateChange} className={styles.dateFilter} />
        </div>
        <div className={styles.bigCards}>
          <div className={`${styles.chartt} ${styles.charttt}`}>
            <div className={styles.chartGraph}>
              <LineChart
                data={data}
                className={styles.lineChart}
                totalEarningsData={totalEarningsData}
                totalUsersData={totalUsersData}
                noOfCandidatesData={noOfCandidatesData}
                noOfEmployersData={noOfEmployersData}
                candidatesTopUpData={candidatesTopUpData}
                extendAccessData={extendAccessData}
              />
            </div>
          </div>
          <div className={styles.candidateCards}>
            {candidatesCardData.map((item, index) => (
              <div
                key={index}
                className={`${styles.card} ${
                  clickedCardId === item.id && styles.clickedd
                }`}
                onMouseEnter={() => showView(item.id)}
                onMouseLeave={() => showView(item.id)}
              >
                <div className={styles.leftt}>
                  <div className={styles.texts}>
                    <p className={styles.textTitle}> {item.header} </p>
                    <h2 className={styles.textNo}>
                      <p className={styles.headTextt}>{item.users}</p>
                      {item.trendStatus === "positive" ? (
                        <RateIcon className={styles.iconn} />
                      ) :  item.trendStatus === "even" ? (
                        <EvenArrowIcon className={`${styles.iconn} ${styles.evenIcon}`} />
                      ) :  (
                        <TrendingDownIcon className={styles.iconn} />
                      )}
                      <span className={styles[item.trendStatus]}>
                        {item.trendValue}
                      </span>
                    </h2>
                  </div>
                </div>
                {clickedCardId === item.id && (
                  <div
                    className={`${styles.rate}`}
                    onClick={() => {
                      handleModalView(item.prop, item.header, item.users);
                    }}
                  >
                    <div className={`${styles.view}`}>
                      <ArrowIcon className={`${styles.viewArrow}`} />
                      <p className={`${styles.viewDesc}`}>{item.type}</p>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className={styles.header}>
          <div className={styles.left}>
            <h3 className={styles.smallHead}>Candidates Report Insights</h3>
            <p className={styles.subHead}>
              A report on evaluated candidates and their categories for informed
              hiring decisions and growth.
            </p>
          </div>
          <div className={styles.right}>
            <div className={styles.leftArrow}>
              <ArrowLeftIcon onClick={scrollLeft} />
            </div>
            <div className={styles.rightArrow} onClick={scrollRight}>
              <ArrowLeftIcon />
            </div>
          </div>
        </div>
        <FilterTabs
          filters={reportInsightsFilterOptions}
          onChange={handleReportInsightsFilterClick}
          value={reportInsightsPeriod}
          className={styles.filterTabss}
        />
        <div className={styles.cards}>
          <div className={styles.cardScroll} ref={scrollRef}>
            {reportInsightData.map((item, index) => (
              <MiniCard
                openChart={() => {}}
                filter={()=>{}}
                key={index}
                header={item.header}
                users={item.users}
                ratingType={item.ratingType}
                className={styles.card}
                percentage={item.percentage}
              />
            ))}
          </div>
        </div>
        <div className={styles.bigCards}>
          <div className={styles.chartt}>
            <div className={styles.chartHead}>Frequently created roles</div>
            <div className={styles.chartGraph}>
              <BarChart dataProp={barChartData} labels={barChartLabels} stepSize={2}/>
            </div>
            <div className={styles.chartLine}>
              <span
                className={`${styles.date} ${
                  activePeriod === "oneDay" ? styles.active : ""
                }`}
                onClick={() => {
                  frequentlyCreatedRoles("oneDay");
                  setActivePeriod("oneDay");
                }}
              >
                1D
              </span>
              <span
                className={`${styles.date} ${
                  activePeriod === "oneWeek" ? styles.active : ""
                }`}
                onClick={() => {
                  frequentlyCreatedRoles("oneWeek");
                  setActivePeriod("oneWeek");
                }}
              >
                1W
              </span>
              <span
                className={`${styles.date} ${
                  activePeriod === "oneMonth" ? styles.active : ""
                }`}
                onClick={() => {
                  frequentlyCreatedRoles("oneMonth");
                  setActivePeriod("oneMonth");
                }}
              >
                1M
              </span>
              <span
                className={`${styles.date} ${
                  activePeriod === "threeMonth" ? styles.active : ""
                }`}
                onClick={() => {
                  frequentlyCreatedRoles("threeMonth");
                  setActivePeriod("threeMonth");
                }}
              >
                3M
              </span>
              <span
                className={`${styles.date} ${
                  activePeriod === "oneYear" ? styles.active : ""
                }`}
                onClick={() => {
                  frequentlyCreatedRoles("oneYear");
                  setActivePeriod("oneYear");
                }}
              >
                1Y
              </span>
              <span
                className={`${styles.date} ${
                  activePeriod === "all" ? styles.active : ""
                }`}
                onClick={() => {
                  frequentlyCreatedRoles("all");
                  setActivePeriod("all");
                }}
              >
                All
              </span>
            </div>
          </div>
          <div className={styles.left}>
            <div className={styles.headBar}>
              <h3 className={styles.headd}>Overall Precision Match Score</h3>
            </div>
            <div className={styles.chart}>
              <DoughnutChart
                score={score}
                radius={[0]}
                cutout="75%"
                total={100}
                color={["#605BFF", "#FFF5E6"]}
                plugins={[centerTextPlugin]}
              />
            </div>
            <div className={styles.chartLine}>
              <span
                className={`${styles.date} ${
                  activeScorePeriod === "one_day" ? styles.active : ""
                }`}
                onClick={() => {
                  assessment("one_day");
                  setActiveScorePeriod("one_day");
                }}
              >
                1D
              </span>
              <span
                className={`${styles.date} ${
                  activeScorePeriod === "one_week" ? styles.active : ""
                }`}
                onClick={() => {
                  assessment("one_week");
                  setActiveScorePeriod("one_week");
                }}
              >
                1W
              </span>
              <span
                className={`${styles.date} ${
                  activeScorePeriod === "one_month" ? styles.active : ""
                }`}
                onClick={() => {
                  assessment("one_month");
                  setActiveScorePeriod("one_month");
                }}
              >
                1M
              </span>
              <span
                className={`${styles.date} ${
                  activeScorePeriod === "three_months" ? styles.active : ""
                }`}
                onClick={() => {
                  assessment("three_months");
                  setActiveScorePeriod("three_months");
                }}
              >
                3M
              </span>
              <span
                className={`${styles.date} ${
                  activeScorePeriod === "one_year" ? styles.active : ""
                }`}
                onClick={() => {
                  assessment("one_year");
                  setActiveScorePeriod("one_year");
                }}
              >
                1Y
              </span>
              <span
                className={`${styles.date} ${
                  activeScorePeriod === "all" ? styles.active : ""
                }`}
                onClick={() => {
                  assessment("all");
                  setActiveScorePeriod("all");
                }}
              >
                All
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export { OverviewUI };
